import axios, { tokenAuthorization } from 'src/utils/axios'
import { CUSTUMERS_URL } from 'src/services/api'

export async function getCustumers() {
  const response = await axios.get(CUSTUMERS_URL, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function getCustumersById({ customerId, token }) {
  const response = await axios.get(CUSTUMERS_URL + `/${customerId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  const { data } = response?.data
  return data
}

export async function postCustomers(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(CUSTUMERS_URL, body, parameters)
  return response
}

export async function putCustomers(id, body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'PUT',
    headers: token
  }
  const response = await axios.put(CUSTUMERS_URL + `/${id}`, body, parameters)
  return response
}

export async function getCustumerById(id) {
  const response = await axios.get(
    CUSTUMERS_URL + `/${id}`,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}
