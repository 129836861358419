function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/'
const ROOTS_DASHBOARD = '/'

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '')
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, 'home'),
  profile: path(ROOTS_DASHBOARD, 'profile'),
  metrics: path(ROOTS_DASHBOARD, 'metrics'),
  customers: path(ROOTS_DASHBOARD, 'customers'),
  users: path(ROOTS_DASHBOARD, 'users'),
  groups: path(ROOTS_DASHBOARD, 'groups'),
  competenceGrouper: path(ROOTS_DASHBOARD, 'competence-grouper'),
  evaluationCycle: path(ROOTS_DASHBOARD, 'evaluation-cycle'),
  impressions: path(ROOTS_DASHBOARD, 'impressions')
}
