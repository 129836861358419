import React from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton, Box, Typography } from '@material-ui/core'
import {
  Close as CloseIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import {
  NewBoxWidget,
  AccordionMeasurement,
  ProgressBar,
  Tooltip,
  SimpleLineChart
} from 'src/components'
import makeStyles from './styles'

function GeneralData({ data, onClose }) {
  const theme = useTheme()
  const classes = makeStyles()

  return (
    <NewBoxWidget
      title="Dados gerais"
      options={
        <Box className={classes.options}>
          <Tooltip placement="top" title="Fechar">
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      width={400}
    >
      {data?.overallMetric ? (
        <>
          <Box mb={2}>
            <Typography className={classes.titleLineChart}>
              Médias gerais
            </Typography>
            <SimpleLineChart data={data?.timeline} />
          </Box>
          <Box mb={4}>
            <ProgressBar
              data={data?.averageMetricsByCategoryType}
              overallMetric={data?.overallMetric}
              overallMetricGap={data?.overallMetricGap}
            />
          </Box>
          <Box mb={2}>
            {data?.radarsCompetenceGrouper?.map(
              ({ category, competenceGroups }, index) => (
                <>
                  {competenceGroups && competenceGroups.length > 0 && (
                    <Box key={index} mb={2}>
                      <AccordionMeasurement
                        categoryName={category}
                        scoreAverage={competenceGroups}
                        users={data?.groupMembers}
                        type="userGroup"
                      />
                    </Box>
                  )}
                </>
              )
            )}
          </Box>
        </>
      ) : (
        <Box
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="100%"
        >
          <Box>
            <AssessmentOutlinedIcon className={classes.notFoundMetricsIcon} />
            <Typography className={classes.notFoundMetricsTitle}>
              Nenhuma informação
            </Typography>
            <Typography className={classes.notFoundMetricsDescription}>
              Não há avaliações para este grupo.
            </Typography>
          </Box>
        </Box>
      )}
    </NewBoxWidget>
  )
}

export default GeneralData
