import { Alert } from 'src/components'
import React, { useState, useMemo, useContext } from 'react'
import notification from 'src/json/notifications.js'

const AlertStateContext = React.createContext()

export function AlertProvider({ children }) {
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState()
  const [typeAlert, setTypeAlert] = useState()
  const notifications = notification()

  function onClose() {
    setVisible(false)
    setMessage('')
    setTypeAlert('default')
  }

  function onCallAlert({ type, message }) {
    setTypeAlert(type)
    setMessage(message)
    setVisible(true)
    setTimeout(() => {
      onClose()
    }, 3000)
  }

  const dataAlert = useMemo(
    () => ({
      visible,
      type: typeAlert,
      message,
      notifications,
      onClose,
      onCallAlert
    }),
    // eslint-disable-next-line
    [visible]
  )

  return (
    <AlertStateContext.Provider value={dataAlert}>
      <Alert />
      {children}
    </AlertStateContext.Provider>
  )
}

export function useAlertContext() {
  const context = useContext(AlertStateContext)
  return context
}
