import { Button, Modal } from 'src/components'
import { profileChangePassword } from 'src/utils/form/validations'
import { Form } from 'react-final-form'
import React from 'react'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import PropTypes from 'prop-types'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

function ModalConfirmChange({ isOpenModalConfirmChange, onClose }) {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  const fakeData = [
    {
      id: 0,
      dateTitle: 'Nome',
      dateOldContent: 'Nome antigo',
      dateNewContent: 'Novo nome'
    },
    {
      id: 1,
      dateTitle: 'CPF',
      dateOldContent: 'CPF antigo',
      dateNewContent: 'CPF nome'
    },
    {
      id: 2,
      dateTitle: 'Data',
      dateOldContent: 'Data antigo',
      dateNewContent: 'Data nome'
    }
  ]

  function onHandleSubmit(values) {
    console.log(values)
  }

  return (
    <Modal
      isOpen={isOpenModalConfirmChange}
      onHandleClose={onClose}
      title="Confirmar alteração"
      subTitle="Confira se as informações que você deseja substituir estão corretas:"
    >
      <Form
        onSubmit={onHandleSubmit}
        validate={profileChangePassword}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={globalClasses.fieldsForm}>
              <div className={classes.boxModalConfirmChange}>
                <div className={classes.boxTitleListModalUpdateInfor}>
                  <p className={classes.titleListModalUpdateInfor}>Antigo</p>
                  <p className={classes.titleListModalUpdateInfor}>Novo</p>
                </div>
                <ul className={classes.boxListModalUpdateInfor}>
                  {fakeData.map(
                    ({ dateTitle, dateOldContent, dateNewContent }) => (
                      <>
                        <li className={classes.listModalUpdateInfor}>
                          <div className={classes.textlistModalUpdateInfor}>
                            <span>
                              <p className={classes.nameListModalUpdateInfor}>
                                {dateTitle}
                              </p>
                              <p className={classes.inforListModalUpdateInfor}>
                                {dateOldContent}
                              </p>
                            </span>
                          </div>
                          <ArrowForwardIosIcon
                            className={classes.iconeArrowlistModalUpdateInfor}
                          />
                          <div className={classes.textlistModalUpdateInfor}>
                            <span>
                              <p className={classes.nameListModalUpdateInfor}>
                                {dateTitle}
                              </p>
                              <p className={classes.inforListModalUpdateInfor}>
                                {dateNewContent}
                              </p>
                            </span>
                          </div>
                        </li>
                      </>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className={globalClasses.modalButtonsPassword}>
              <Button label="Cancelar" variant="underline" onClick={onClose} />
              <Button label="Confirmar" type="submit" />
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default ModalConfirmChange

ModalConfirmChange.propTypes = {
  isOpenModalConfirmChange: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
