import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#D8D8D8',
    height: 4
  },
  content: {
    margin: '8px 0'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    marginRight: 2,
    color: '#787878'
  },
  value: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    color: '#787878'
  }
}))
