import React from 'react'
import { useTheme } from '@material-ui/styles'
import {
  Tooltip,
  MultiProgressBar,
  ProgressBar,
  StarFavorite,
  AccordionMeasurement,
  SimpleLineChart
} from 'src/components'
import { Grid } from '@material-ui/core'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ChromeReaderModeOutlined as ChromeReaderModeOutlinedIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { Typography, Box, IconButton } from '@material-ui/core'
import { useState } from 'react'
import { AvatarGroups } from 'src/screens/Metrics/Partials/MetricsGroups'

export function CardGroup({
  onActionRemove,
  classes,
  primaryColor,
  data: dataMetrics,
  currentUserId: userId
}) {
  const theme = useTheme()

  const [isExpandCard, setIsExpandCard] = useState(false)
  const isExistRadarsCompetenceGrouper =
    dataMetrics?.radarsCompetenceGrouper &&
    dataMetrics?.radarsCompetenceGrouper.length > 0

  function onHandleExpand() {
    setIsExpandCard(!isExpandCard)
  }
  return (
    <>
      <Box
        className={
          isExpandCard ? classes.userContainerShadow : classes.userContainer
        }
      >
        <Box className={classes.displayContent}>
          <Box>
            <Typography className={classes.name}>
              {dataMetrics?.name}
            </Typography>
          </Box>
          <Box className={classes.boxArrow}>
            <Tooltip
              title={
                isExpandCard
                  ? 'Visualizar simplificado'
                  : 'Visualizar com detalhes'
              }
            >
              <IconButton
                onClick={onHandleExpand}
                style={{
                  background: isExpandCard === true && primaryColor,
                  color: isExpandCard === true && '#fff'
                }}
                className={classes.boxContentBtn}
              >
                <ChromeReaderModeOutlinedIcon />
              </IconButton>
            </Tooltip>
            <StarFavorite
              onActionRemove={() =>
                onActionRemove({
                  type: 'group',
                  id: dataMetrics?.id
                })
              }
              checked
              componentId={dataMetrics?.id}
              userId={userId}
              favoriteId={dataMetrics?.favoriteId}
              className={classes.boxBtnOptions}
              type="userGroup"
              colorIcon={theme.palette.primary.main}
            />
            <Tooltip title="Ver grupo">
              <IconButton
                className={classes.boxBtnOptions}
                href={`/metrics/group/${dataMetrics?.id}`}
              >
                <ArrowForwardIosIcon style={{ color: primaryColor }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <AvatarGroups data={dataMetrics?.groupMembers} />
        <div className={classes.containerColorMetrics}>
          {dataMetrics?.averageMetricsByCategoryType && (
            <MultiProgressBar
              data={dataMetrics?.averageMetricsByCategoryType}
              showLegend
            />
          )}
        </div>
      </Box>
      {isExpandCard && (
        <Box className={classes.containerScroll}>
          <Box className={classes.containerTable}>
            <Box className={classes.cardContainer}>
              <p className={classes.titleLineChart}>Médias gerais</p>
              <SimpleLineChart data={dataMetrics?.timeline} />
            </Box>
            <Box className={classes.cardContainer}>
              <ProgressBar
                data={dataMetrics?.averageMetricsByCategoryType}
                overallMetric={dataMetrics?.overallMetric}
                overallMetricGap={dataMetrics?.overallMetricGap}
              />
            </Box>
            <Grid className={classes.cardContainer}>
              {isExistRadarsCompetenceGrouper ? (
                <Box mt={5} className={classes.boxDatasRadar}>
                  {(dataMetrics?.radarsCompetenceGrouper ?? [])?.map(
                    ({ category, competenceGroups }, index) => (
                      <Box key={index} mb={2}>
                        <AccordionMeasurement
                          categoryName={category}
                          scoreAverage={competenceGroups}
                          users={dataMetrics?.groupMembers}
                          type="userGroup"
                        />
                      </Box>
                    )
                  )}
                </Box>
              ) : (
                <Box
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Box>
                    <AssessmentOutlinedIcon
                      className={classes.notFoundMetricsIcon}
                    />
                    <Typography className={classes.notFoundMetricsTitle}>
                      Nenhuma informação
                    </Typography>
                    <Typography className={classes.notFoundMetricsDescription}>
                      Não há avaliações para este grupo.
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}
