import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { roundToUp } from 'round-to'
import { Box } from '@material-ui/core'
import { enumCategory, enumCategoryColor } from 'src/@enum/metrics'
import useStyles from './style'

export function ProgressBar({ data, overallMetric, overallMetricGap }) {
  const classes = useStyles()

  const [dataCategoryMetrics, setDataCategoryMetrics] = useState()

  const getData = useCallback(() => {
    const response = Object.entries(data ?? []).map((currentData) => {
      const [key, value] = currentData
      return {
        label: key,
        value: value
      }
    })
    setDataCategoryMetrics(response)
  }, [data])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Box className={classes.container}>
      <Box className={classes.boxMetricsBarProgress}>
        {(dataCategoryMetrics ?? [])?.map(({ label, value }, index) => (
          <Fragment key={index}>
            {value > 0 && (
              <>
                <section>
                  <span style={{ color: enumCategoryColor[label] }}>
                    {value}
                  </span>
                  <p>{enumCategory[label]}</p>
                  <p className={classes.numberAnd}>{roundToUp(5 - value, 2)}</p>
                </section>
                <Box className={classes.metricsBarProgress}>
                  <Box
                    style={{
                      background: enumCategoryColor[label],
                      width: `${value * 20}%`,
                      height: '100%'
                    }}
                  />
                  <dot
                    style={{
                      background: enumCategoryColor[label]
                    }}
                  />
                </Box>
              </>
            )}
          </Fragment>
        ))}
      </Box>
      <Box className={classes.boxMetricsMedia}>
        <Box>
          <strong>Média</strong>
          <strong>{overallMetric}</strong>
        </Box>
        <Box>
          <p>Gap</p>
          <p>{overallMetricGap}</p>
        </Box>
      </Box>
    </Box>
  )
}
