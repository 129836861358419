import EditUser from 'src/screens/Users/EditUser'
import ListUsers from 'src/screens/Users/ListUsers'
import NewUser from 'src/screens/Users/NewUser'

export default [
  {
    path: '/users',
    element: <ListUsers />
  },
  {
    path: '/users/edit/:id',
    element: <EditUser />
  },
  {
    path: '/users/add',
    element: <NewUser />
  }
]
