import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Radar } from 'react-chartjs-2'
import { getHexToRGBA, getGenerateColor } from 'src/utils'
import { enumCategoryNameColor } from 'src/@enum/metrics'
import { Grid, Box } from '@material-ui/core'
import { Chip } from 'src/components'

export function RadarChartUser({
  title = false,
  legend = false,
  aspectRatio = 2,
  data,
  labels,
  categoryName
}) {
  const theme = useTheme()
  const [dataState, setData] = useState([])

  const options = {
    responsive: true,
    aspectRatio: aspectRatio,
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
          beginAtZero: true,
          fontColor: 'white',
          showLabelBackdrop: false
        },
        grid: {
          color: '#CFD2D3',
          circular: true
        },
        angleLines: {
          color: '#CFD2D3'
        },
        pointLabels: {
          color: '#787878'
        }
      }
    },
    plugins: {
      title: {
        display: title
      },
      legend: {
        display: legend
      }
    }
  }

  const colorGenerate = getGenerateColor()

  const getData = useCallback(() => {
    const radar = (dataState ?? []).map((currentData) => {
      return {
        label: currentData?.competenceGrouperName,
        value: currentData?.average
      }
    })

    const labelsMap = radar?.map((item) => item.label)
    const score = radar?.map((item) => item.value)
    const color = enumCategoryNameColor[categoryName.toLocaleLowerCase()]

    return {
      labels: labels.length >= 1 ? labels : labelsMap,
      datasets: [
        {
          data: score,
          backgroundColor: getHexToRGBA(color ? color : colorGenerate, 0.1),
          borderColor: color ? color : colorGenerate,
          borderWidth: 1
        }
      ]
    }
  }, [dataState, categoryName, labels, colorGenerate])

  useEffect(() => {
    setData(data)
  }, [data, labels])

  return (
    <Box mb={4}>
      <Radar data={getData()} options={options} />
      <Grid container spacing={2} direction="row" justifyContent="center">
        {labels?.map((item) => {
          return (
            <Grid item xs="auto" key={item}>
              <Chip
                name={item}
                dotColor={theme.palette.primary.main}
                id={item}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
