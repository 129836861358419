import React, { useState } from 'react'
import useNotification from 'src/hooks/useNotification'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { styled, Tooltip } from '@material-ui/core'
import { tooltipClasses } from '@mui/material'
import makeStyles from './style'

export function NotificationActivitiesModal({
  infor,
  time,
  wasViewed,
  notificationId,
  userId,
  title,
  isActive,
  redirect
}) {
  const classes = makeStyles()

  const { updateNotification } = useNotification()

  const [isNotificationNotRead, setNotificationNotRead] = useState(wasViewed)
  const [isNotificationRead, setNotificationRead] = useState(wasViewed)

  const controlNotificationNotRead = isNotificationNotRead
    ? 'notificationActiveNotRead'
    : 'notificationDisabledNotRead'
  const controlNotificationRead = isNotificationRead
    ? 'notificationActiveRead'
    : 'notificationDisabledRead'

  const CustomTooltipHoverNotification = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 215,
      fontSize: 14,
      backgroundColor: '#434343',
      marginLeft: 10,
      marginTop: -50,
      padding: 10
    }
  })
  const CustomTooltipHoverRadio = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 14,
      backgroundColor: '#434343',
      padding: 10
    }
  })

  function onHandleCheckOption() {
    const payload = {
      id: notificationId,
      wasViewed: !isNotificationRead,
      userId: userId,
      title: title,
      description: infor,
      redirect: redirect
    }
    updateNotification(notificationId, payload)
    setNotificationNotRead(!isNotificationNotRead)
    setNotificationRead(!isNotificationRead)
  }

  function onHandleRedirect() {
    const payload = {
      id: notificationId,
      wasViewed: true,
      userId: userId,
      title: title,
      description: infor,
      redirect: redirect
    }
    updateNotification(notificationId, payload)
    setNotificationNotRead(isNotificationNotRead)
    setNotificationRead(isNotificationRead)

    setTimeout(() => {
      console.log(JSON.stringify(redirect, null, 2));
      window.location.href = redirect
    }, 100)
  }

  return (
    <div>
      <div className={classes.notificationActivitiesContainer}>
        <CustomTooltipHoverNotification title={infor}>
          <div onClick={() => onHandleRedirect()} className={classes.boxInfor}>
            <h1 className={classes.infor}>{infor}</h1>
            <p className={classes.time}>{moment(time).format('L')}</p>
          </div>
        </CustomTooltipHoverNotification>
        <CustomTooltipHoverRadio title="Marcar como lido" placement="left">
          <button
            onClick={onHandleCheckOption}
            className={clsx(classes.boxIcon, classes[controlNotificationRead])}
          >
            <span className={classes.notificationActive} />
          </button>
        </CustomTooltipHoverRadio>
        <CustomTooltipHoverRadio title="Marcar como não lido" placement="left">
          <button
            onClick={onHandleCheckOption}
            className={clsx(
              classes.boxIcon,
              classes[controlNotificationNotRead]
            )}
          >
            <span className={classes.notificationDisabled} />
          </button>
        </CustomTooltipHoverRadio>
      </div>
    </div>
  )
}

NotificationActivitiesModal.propTypes = {
  infor: PropTypes.string,
  time: PropTypes.string
}
