import React from 'react';
import PropTypes from 'prop-types'
import { BadgeTooltip, Button, Loading, NotFound, Tooltip } from 'src/components'
import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import makeStyles from './style'

ListSearchUsers.propTypes = {
  onChangeSelectUser: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  users: PropTypes.array,
  usersSelected: PropTypes.array,
}

export function ListSearchUsers({
  onChangeSelectUser,
  onClose,
  users,
  isLoading,
  usersSelected
}) {
  const classes = makeStyles()
  const shouldRenderContent = users.length > 0;

  function styleSelected(id){
    const isSelected = usersSelected.some(item => item.id === id);
    if(isSelected) {
      return classes.userItemSelected
    }
    return null
  }

  return (
    <div className={classes.containerList}>
      {
        isLoading ? (
          <div className={classes.containerLoading}>
            <Loading />
          </div>
        ) :
        <div className={classes.containerListUsers}>
          {shouldRenderContent ? (
            <>
              <div className={classes.listUsers}>
                {
                  users.map((itemUser, index) =>
                    <div
                      className={clsx(classes.userItem, styleSelected(itemUser.id))}
                      key={index}
                    >
                      <div
                        className={classes.userInfos}
                      >
                        <BadgeTooltip
                          placement="top"
                          disableFocusListener
                          disableTouchListener
                          user={itemUser}
                        />
                        <p>{itemUser.name}</p>
                      </div>
                      <Tooltip
                        placement="top"
                        title={itemUser.isSelected ?  "Remover" : "Adicionar" }
                      >
                        <IconButton
                          className={clsx(classes.sizeButton, classes.iconButton)}
                          onClick={() =>
                            onChangeSelectUser({
                              idUser: itemUser.id,
                              action: itemUser.isSelected ? "remove" : "add"
                            })}
                        >
                          <CloseIcon
                            style={{
                              transform: `rotate(${ itemUser.isSelected ? '0deg' : '45deg' })`,
                              transition: 'all .2s',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )
                }
              </div>
              <div className={classes.listFooter}>
                <Button label="Fechar" onClick={onClose} />
              </div>
            </>
          ) : (
            <div className={classes.notFound}>
              <NotFound
                title="Nenhuma informação"
                subTitle="Não há usuários para serem exibidos."
              />
            </div>
          )}
        </div>
      }
    </div>
  )
}
