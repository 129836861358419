import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer'
  },
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  avatarBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 120,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '50%',
    margin: theme.spacing(3)
  },
  borderBadge: {
    border: '0.5px solid',
    borderColor: theme.palette.primary.main,
    margin: theme.spacing(0.5),
    width: '90%'
  },
  nameBadge: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    color: '#FFFFFF',
    margin: theme.spacing(0.5)
  },
  iconCloseButtonComparation: {
    padding: 0,
    width: 40,
    height: 40,
    position: `absolute`,
    top: 8,
    opacity: 0.4,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      opacity: 1
    }
  },
  occupationBadge: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#FFFFFF',
    margin: theme.spacing(0.5)
  },
  emailBadge: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    color: '#FFFFFF',
    marginBottom: 10
  }
}))
