import { makeStyles } from '@material-ui/styles'
import { getColor } from '../../utils'

export default makeStyles((theme) => ({
  tooltip: {
    display: 'flex',
    alignItems: 'left',
    backgroundColor: (props) => getColor(props.backgroundColor, theme),
    color: 'white',
    padding: 10,
    fontSize: 14,
    borderRadius: 8
  }
}))
