export default function notifications() {
  return {
    global: {
      success: 'Solicitação concluída com exito',
      error: 'Não foi possível concluir sua solicitação'
    },
    users: {
      success: {
        editUser: 'Dados atualizados com sucesso',
        addNewUser: 'Usuário adicionado com sucesso',
        changePassword: 'Senha alterada com sucesso!'
      },
      error: {}
    },
    customers: {
      success: {
        addNewCustomer: 'Cliente criado com sucesso',
        updateAppearance: 'Aparência Atualizada com sucesso!',
        editCustomer: 'Cliente atualizado com sucesso'
      },
      error: {}
    },
    userGroups: {
      success: {
        editGroup: 'Dados atualizados com sucesso',
        addNewGroup: 'Grupo criado com sucesso',
        updateSettingsGroup: 'Permissões atualizadas com sucesso'
      },
      error: {}
    },
    groupers: {
      success: {
        editGroup: 'Dados atualizados com sucesso',
        addNewGroup: 'Agrupador criado com sucesso'
      }
    },
    evaluationCycles: {
      success: {
        editEvaluationCycles: 'Dados atualizados com sucesso',
        addNewEvaluationCycles: 'Ciclo de avaliação criada com sucesso'
      },
      error: {}
    },
    profile: {
      success: {
        editUser: 'Imagem do perfil atualizada com sucesso'
      },
      error: {}
    },
    competencyGrades: {
      success: {
        editCompetencyGrade: 'Avaliação de competência concluída com sucesso'
      },
      error: {}
    },
    competences: {
      success: {
        update: 'Dados atualizados com sucesso',
        archived: 'Competência arquivada com sucesso',
        unarchived: 'Competência desarquivada com sucesso',
        addedNew: 'Competência criada com sucesso'
      },
      error: {
        error: 'Não foi possível concluir sua atualização'
      }
    },
    pdi: {
      success: {
        editPDI: 'PDI atualizado com sucesso',
        addNewPDI: 'PDI criado com sucesso',
        concludedPDI: 'PDI concluído com sucesso'
      },
      error: {}
    },
    oneToOne: {
      success: {
        editOneToOne: '1:1 atualizado com sucesso',
        addNewOneToOne: '1:1 agendado com sucesso'
      },
      error: {}
    },
    feedback: {
      success: {
        addNewFeedback: 'Feedback enviado com sucesso'
      },
      error: {}
    }
  }
}
