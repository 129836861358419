import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import RecommendIcon from '@mui/icons-material/Recommend'
import makeStyles from './style'
import clsx from 'clsx'
import ModalComment from './partials/modalComment'
import { useTheme } from '@material-ui/styles'
import { BadgeTooltip, Tooltip } from 'src/components'
import { Box, IconButton } from '@material-ui/core'
import { CommentOnCelebrations } from '../CommentOnFeedBack'
import { RenderLikeSection } from './partials/RenderLikeSection'

export function CardImpressionsCelebrations({ items, userId, dataUsers }) {
  const theme = useTheme()
  const classes = makeStyles()
  const [data, setData] = useState([])
  const [isOpenModalComment, setIsOpenModalComment] = useState(false)
  const [currentCelebrationId, setCurrentCelebrationId] = useState('')

  function onHandleEditComment(celebrationId) {
    setCurrentCelebrationId(celebrationId)
    setIsOpenModalComment(!isOpenModalComment)
  }

  useEffect(() => {
    setData(items)
  }, [items])

  function getInfoComments(likes) {
    const numberOfLikes = (likes ?? []).length
    const isCurrentUserLike = likes.some(
      (itemLike) => itemLike.userId === userId
    )
    if (numberOfLikes === 1 && isCurrentUserLike) {
      return {
        isCurrentUserLike: isCurrentUserLike,
        messageComments: 'Você curtiu isso'
      }
    }
    if (numberOfLikes === 1 && !isCurrentUserLike) {
      return {
        isCurrentUserLike: isCurrentUserLike,
        messageComments: '1 pessoa curtiu isso'
      }
    }
    if (numberOfLikes === 2 && isCurrentUserLike) {
      return {
        isCurrentUserLike: isCurrentUserLike,
        messageComments: 'Você e outra pessoa curtiram isso'
      }
    }
    if (numberOfLikes > 2 && isCurrentUserLike) {
      return {
        isCurrentUserLike: isCurrentUserLike,
        messageComments: `Você e outras ${
          numberOfLikes - 1
        } pessoas curtiram isso`
      }
    }
    if (numberOfLikes > 2 && !isCurrentUserLike) {
      return {
        isCurrentUserLike: isCurrentUserLike,
        messageComments: `${numberOfLikes} pessoas curtiram isso`
      }
    }
    return {
      isCurrentUserLike: isCurrentUserLike,
      messageComments: ''
    }
  }

  return (
    <>
      <ModalComment
        userId={userId}
        celebrationId={currentCelebrationId}
        isOpenModal={isOpenModalComment}
        onCloseModal={() => setIsOpenModalComment(false)}
        dataCelebration={data}
        onUpdateDataCelebration={setData}
      />
      {(data ?? []).map((itemCelebration, index) => {
        const numberOfComments = (itemCelebration?.comments ?? []).length
        const numberOfLikes = (itemCelebration?.likes ?? []).length
        const { isCurrentUserLike, messageComments } = getInfoComments(
          itemCelebration?.likes ?? []
        )
        const isSentMultipleUsers =
          (itemCelebration?.withUsersExpanded ?? []).length > 1
        return (
          <Box key={itemCelebration?.id} className={classes.boxContainer}>
            <Box className={classes.headerData}>
              <p>{moment(itemCelebration?.createAt).format('L')}</p>
              <Box className={classes.badgeHeader}>
                {itemCelebration?.BadgesInfo?.map(({ color, name }) => (
                  <Tooltip key={color} title={name}>
                    <div
                      style={{
                        borderRadius: 15,
                        width: 15,
                        height: 15,
                        backgroundColor: `${color}`
                      }}
                    />
                  </Tooltip>
                ))}
              </Box>
            </Box>
            <Box className={classes.boxDescription}>
              <p>{itemCelebration?.description}</p>
            </Box>
            <Box
              className={clsx(
                classes.boxRodape,
                isSentMultipleUsers ? 'listMultipleUsers' : 'listOneUser'
              )}
            >
              <Box className={classes.boxUers}>
                <p>De:</p>
                <BadgeTooltip
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  user={itemCelebration?.fromUser}
                />
              </Box>
              <Box
                className={clsx(
                  classes.boxUers,
                  classes.containerListUsers,
                  'containerListUsers'
                )}
              >
                <p className="labelForUsers">Para:</p>
                {itemCelebration?.withUsersExpanded.map(
                  (itemUsersExpanded, idx) => (
                    <BadgeTooltip
                      key={idx}
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      user={itemUsersExpanded}
                    />
                  )
                )}
              </Box>
            </Box>
            <Box className={classes.boxLikes}>
              {numberOfLikes > 0 && (
                <>
                  <RecommendIcon htmlColor={theme.palette.primary.main} />
                  <p>{messageComments}</p>
                </>
              )}
            </Box>
            <Box className={classes.boxLikeAndComment}>
              <RenderLikeSection
                userId={userId}
                isCurrentUserLike={isCurrentUserLike}
                itemCelebration={itemCelebration}
                dataCelebration={data}
                onUpdateDataCelebration={setData}
              />
              <Tooltip placement="top" title={'Adicionar um comentário'}>
                <section>
                  <IconButton
                    className={classes.iconButtonClose}
                    key={0}
                    onClick={() => onHandleEditComment(itemCelebration?.id)}
                  >
                    <ChatOutlinedIcon sx={{ color: 'gray', fontSize: 20 }} />
                    <p
                      style={{
                        color: 'gray',
                        fontSize: 14
                      }}
                    >
                      Comentar
                    </p>
                  </IconButton>
                </section>
              </Tooltip>
            </Box>
            {numberOfComments > 0 && (
              <CommentOnCelebrations
                data={itemCelebration?.comments}
                dataUsers={dataUsers}
              />
            )}
          </Box>
        )
      })}
    </>
  )
}
