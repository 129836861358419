import React from 'react'
import makeStyles from './style'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

export function WordCloud({ words, className, onRemove }) {
  const classes = makeStyles()
  return (
    <div className={clsx(classes.containertCloud, className ?? '')}>
      {/* {label ? (
        <Typography typ className={classes.cloudTitle}>
          Grupo(s) do usuário:
        </Typography>
      ) : null} */}
      <div className={classes.cloudWords}>
        {words.map(({ value, title }) => (
          <div className={classes.wordItems} key={value}>
            <Typography className={classes.wordItemText}>{title}</Typography>
            <button
              type="button"
              className={classes.wordItemButton}
              onClick={() => onRemove(value)}
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

WordCloud.propTypes = {
  words: PropTypes.array,
  onRemove: PropTypes.func,
  className: PropTypes.object,
}
