import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerList: {
    marginTop: '15px',
    width: '100%'
  },
  listTextFor: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#787878',
    backgroundColor: '#F7F7FC',
    boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '30px 0 0 30px',
    padding: '0 15px',
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1
  },
  listUsers: {
    maxHeight: '35vh',
    overflow: 'auto',
    backgroundColor: '#FFFFFF',
    padding: '0 20px',
    width: '100%',
    display: 'flex',
    height: 'fit-content',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  containerListColumn: {
    '& .listUsers': {
      flexDirection: 'column',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      rowGap: '10px'
    },
    '& .userItem': {
      backgroundColor: '#F7F7FC'
    },
    '& .userInfos': {
      columnGap: '10px'
    }
  },
  containerListRow: {
    padding: '0 20px',
    display: 'flex',
    '& .listUsers': {
      backgroundColor: '#F7F7FC',
      flexDirection: 'row',
      borderRadius: '0 30px 30px 0',
      padding: '0 20px 0 5px',
      '&::-webkit-scrollbar': {
        margin: '0 40px'
      }
    },
    '& .userName, .iconHandlerItem': {
      display: 'none'
    },
    '& .badgeTooltip': {
      '& > ::after': {
        background: 'rgba(0, 0, 0, 0.6)',
        transition: 'all 0.2s',
        content: '"x"',
        position: 'absolute',
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        color: '#ffffff',
        top: 1,
        left: 5,
        height: '40px',
        width: '40px',
        zIndex: 1,
        // scale: 0,
        opacity: 0
      },
      '&:hover > ::after': {
        opacity: 1
      }
    }
  },
  badgeTooltip: {
    position: 'relative',
    backgroundColor: 'transparent',
    width: 'fit-content'
  },
  userName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    color: '#434343'
  },
  userOccupation: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    margin: 0,
    color: '#787878'
  },
  userItem: {
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between',
    borderRadius: '10px',
    height: 'fit-content'
  },

  userInfos: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center'
  },
  userButtonRemove: {
    transition: 'all 0.2s',
    background: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    scale: 0.5,
    left: 0,
    zIndex: 1,

    top: '50%',
    transform: 'translateY(-50%)'
  },
  listFooter: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  sizeButton: {
    padding: 0,
    width: 40,
    height: 40
  },
  iconButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main
    }
  }
}))
