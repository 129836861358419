import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formInfo: {
    fontSize: theme.typography.pxToRem(14)
  },
  textStatusUser: {
    display: 'flex',
    width: 120
  },
  modalContainer: {
    width: '50%',
    maxWidth: '550px'
  },
  modalButtons: {
    display: 'flex',
    columnGap: '10px',
    justifyContent: 'flex-end',
    marginTop: '30px'
  },
  inputContainer: {
    position: 'relative',
    width: '100%'
  },
  formControlButton: {
    display: 'flex',
    gap: 15
  }
}))
