import React, { createContext, useReducer } from 'react'
import { getNotificationsNotWasViewedById } from 'src/services/hooks/notifications/useNotifications'
import { putNotification } from 'src/services/hooks/notifications/useNotifications'

const Types = {
  Notification: 'NOTIFICATION'
}

const initialState = {
  notifications: [],
  notificationsTotal: null
}

const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'NOTIFICATION':
      return {
        ...state,
        notifications: action.payload.notifications,
        notificationsTotal: action.payload.notificationsTotal
      }
    default:
      return state
  }
}

const NotificationContext = createContext()

function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer(NotificationReducer, initialState)

  const getNotification = async () => {
    try {
      const accessToken =
        typeof window !== 'undefined' ? localStorage.getItem('accessToken') : ''

      if (accessToken) {
        const storage = {
          userId: localStorage.getItem('userId')
        }

        const notifications = await getNotificationsNotWasViewedById(
          storage.userId
        )

        dispatch({
          type: Types.Notification,
          payload: {
            notifications: notifications.slice(0, 15),
            notificationsTotal: notifications.length
          }
        })
      } else {
        dispatch({
          type: Types.Notification,
          payload: {
            notifications: [],
            notificationsTotal: null
          }
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: Types.Notification,
        payload: {
          notifications: [],
          notificationsTotal: null
        }
      })
    }
  }

  const updateNotification = async (notificationId, payload) => {
    try {
      await putNotification(notificationId, payload)

      const storage = {
        userId: localStorage.getItem('userId')
      }

      const notifications = await getNotificationsNotWasViewedById(
        storage.userId
      )

      dispatch({
        type: Types.Notification,
        payload: {
          notifications: notifications.slice(0, 15),
          notificationsTotal: notifications.length
        }
      })
    } catch (err) {
      console.error(err)
      dispatch({
        type: Types.Notification,
        payload: {
          notifications: [],
          notificationsTotal: null
        }
      })
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        getNotification,
        updateNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
