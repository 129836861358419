import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  titleHead: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#434343'
  },
  options: {
    columnGap: 15
  }
}))
