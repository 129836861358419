import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  chipPosition: {
    float: 'left',
    margin: '4px',
    marginBottom: '8px'
  },
  container: {
    columnGap: 10,
    width: `100%`,
    height: 'auto',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    paddingBottom: 5,
    '&::-webkit-scrollbar': {
      height: 6,
      background: '#CFD2D3',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}))
