import React, { useState, useCallback, useEffect, Fragment } from 'react'
import { Typography, Box, IconButton } from '@material-ui/core'
import { useParams, useNavigate } from 'react-router-dom'
import { CalendarPeriod, HeaderBreadcrumbs, Tooltip } from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  InfoBox,
  MultiProgressBar,
  SimpleProgressBar,
  BoxHero,
  Helmet,
  RadarChartDetails
} from 'src/components'
import {
  Close as CloseIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons'
import { getMetricsUserGroupsById } from 'src/services/hooks/metrics/useMetrics'
import { enumCategory } from 'src/@enum/metrics'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './styles'
import ModalGeneratePDF from './partials/ModalGeneratePDF'
import { AvatarGroups } from 'src/screens/Metrics/Partials/MetricsGroups'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'

export function CompetenceGroupsRadarBox({
  categoryName,
  competenceGroupName,
  competences,
  classes
}) {
  const [labelsRadar, setLabelsRadar] = useState([])

  const mapScore = (competences ?? []).map((item) => {
    return {
      checked: false,
      competenceGrouperName: item?.competenceName,
      average: item?.averageScore
    }
  })

  const [filter, setFilter] = useState(mapScore)

  const onChangeFilter = useCallback((competences) => {
    setFilter(competences)
  }, [])

  return (
    <Box className={classes.itemRadar}>
      <BoxHero
        title={competenceGroupName}
        // options={
        //   <>
        //     <ChartFilterEvolution
        //       items={filter}
        //       category={competenceGroupName}
        //       labels={labelsRadar}
        //       setLabelsRadar={setLabelsRadar}
        //       onChangeFilter={onChangeFilter}
        //     />
        //   </>
        // }
      >
        <RadarChartDetails
          data={filter}
          labels={labelsRadar}
          categoryName={categoryName}
        />
      </BoxHero>
    </Box>
  )
}

export function CompetenceGroupsRadar({ categoryName, data, classes }) {
  return (
    <>
      {data?.map(({ competenceGroupName, competences }, index) => {
        return (
          competences.length > 0 && (
            <CompetenceGroupsRadarBox
              key={index}
              categoryName={categoryName}
              competenceGroupName={competenceGroupName}
              competences={competences}
              classes={classes}
            />
          )
        )
      })}
    </>
  )
}

function TechnicalMeasuresGroup() {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()
  const navigate = useNavigate()

  const { id, category } = useParams()

  const { onCallAlert } = useAlertContext()
  const { currentPeriod } = useCurrentPeriodContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [isOpenModalGeneratePDF, setIsOpenModalGeneratePDF] = useState(false)
  const [dataUserGroup, setDataUserGroup] = useState([])
  const [dataCompetenceGroups, setDataCompetenceGroups] = useState([])

  const getDataUserGroup = useCallback(async (id) => {
    try {
      onOpenLoading()
      const formatedDatePayload = getPayloadPeriod(currentPeriod)
      const data = await getMetricsUserGroupsById({
        groupId: id,
        category: category,
        period: formatedDatePayload
      })
      setDataUserGroup(data)
      const competenceGroups = data?.radarsCompetence?.filter(
        (item) => item[0].competenceGroups
      )
      setDataCompetenceGroups(competenceGroups)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    id && getDataUserGroup(id)
  }, [getDataUserGroup, id])

  function handleMetricsUser(id) {
    navigate(`/metrics/group/${id}`)
  }

  function onHandleGeneratePDF() {
    setIsOpenModalGeneratePDF(!isOpenModalGeneratePDF)
  }

  function onUpdateDataPeriod(data) {
    setDataUserGroup(data)
    const competenceGroups = data?.radarsCompetence?.filter(
      (item) => item[0].competenceGroups
    )
    setDataCompetenceGroups(competenceGroups)
  }

  const titleHelmetMetric = category
    ? `Métricas do grupo - ${enumCategory[category]}`
    : 'Métricas gerais do grupo'
  const titleMetric = category
    ? `Métricas de ${enumCategory[category]}`
    : 'Métricas gerais'

  return (
    <>
      <Helmet title={titleHelmetMetric} />
      <ModalGeneratePDF
        data={dataUserGroup}
        dataCompetenceGroups={dataCompetenceGroups}
        isOpenModal={isOpenModalGeneratePDF}
        onCloseModal={() => setIsOpenModalGeneratePDF(false)}
      />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            { name: dataUserGroup?.name, href: `/metrics/user/${id}` },
            { name: titleMetric }
          ]}
        />
        <Box className={classes.boxBtnNavegation}>
          <CalendarPeriod
            onChangeDataMetrics={onUpdateDataPeriod}
            type="group"
            identification={id}
            category={category}
            isSearchById
            isShowIcon
          />
          <Tooltip title="Visualizar PDF">
            <IconButton
              className={classes.boxContentBtn}
              onClick={onHandleGeneratePDF}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Fechar">
            <IconButton
              className={classes.boxContentBtn}
              onClick={() => handleMetricsUser(dataUserGroup?.id)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box className={globalClasses.content}>
        <Box className={classes.containerItensLayout}>
          <Box className={classes.columnMetricsScroll}>
            <Box item xs={12} md={4} className={classes.columnMetrics}>
              <Box className={classes.titleTable}>
                <InfoBox title={titleMetric} />
              </Box>
              <Box className={classes.userContainer}>
                <Box className={classes.displayContent}>
                  <Box>
                    <Typography className={classes.name}>
                      {dataUserGroup?.name}
                    </Typography>
                  </Box>
                  <Box className={classes.groupAvatas}>
                    <AvatarGroups data={dataUserGroup?.groupMembers} />
                  </Box>
                </Box>
                {dataUserGroup?.averageMetricsByCategoryType && (
                  <MultiProgressBar
                    data={dataUserGroup?.averageMetricsByCategoryType}
                    showLegend
                  />
                )}
              </Box>
              {dataUserGroup?.radarsCompetenceGrouper && (
                <BoxHero title="Médias das sessões">
                  <Box className={classes.simpleProgress}>
                    <SimpleProgressBar
                      data={dataUserGroup?.radarsCompetenceGrouper}
                    />
                  </Box>
                </BoxHero>
              )}
            </Box>
          </Box>
          <Box className={classes.containerRadars}>
            {dataCompetenceGroups?.map((item) =>
              item.map(({ categoryName, competenceGroups }, index) => (
                <CompetenceGroupsRadar
                  categoryName={categoryName}
                  data={competenceGroups}
                  key={index}
                  classes={classes}
                />
              ))
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TechnicalMeasuresGroup
