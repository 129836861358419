import { FEEDBACK, FEEDBACK_SEARCH } from 'src/services/api'
import axios, { tokenAuthorization } from 'src/utils/axios'

export async function getFeedback() {
  const response = await axios.get(FEEDBACK, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function postFeedback(payload) {
  const response = await axios.post(FEEDBACK, payload, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function postFeedbackSearch(payload) {
  const response = await axios.post(
    FEEDBACK_SEARCH,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}
