import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  notFoundMetricsIcon: {
    fontSize: 52,
    color: '#CFD2D3'
  },
  notFoundMetricsTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  notFoundMetricsDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    color: '#787878'
  }
}))
