import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  titleLineChart: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.8,
    paddingBottom: 7,
    paddingLeft: 10
  },
  containerHeader: {
    margin: '-20px 0px 7px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #cfd2d3',
    paddingBottom: 5
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 5px',
    height: 30
  },
  filtersButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center'
  },
  buttonFilter: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'all 0.3s'
  },
  buttonFilterSelected: {
    width: 22,
    height: 22,
    borderRadius: '100%',
    cursor: 'pointer',
    boxShadow: '0 0 0 2px #f3f5f6 inset',
    boxSizing: 'border-box',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    transition: 'all 0.3s'
  },
  rightOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listUsers: {
    display: 'flex',
    rowGap: '20px',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  itemUserExpand: {
    justifyContent: 'space-between'
  },
  lineDetailsUser: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  itemUserClose: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  progressExpand: {
    marginTop: 15
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: '#787878'
  },
  functionUser: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#787878',
    marginTop: 2
  }
}))
