import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import makeStyles from './styles'
import { IconButton } from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@material-ui/icons'
import {
  RadarChartUser,
  RadarChartGroup,
  ChartFilterEvolution,
  ChartFilterCompare,
  BoxHero,
  Tooltip
} from 'src/components'
import { getMetricsUsers } from 'src/services/hooks/metrics/useMetrics'

export function AccordionMeasurement({
  categoryName,
  competenceGroupName,
  scoreAverage,
  users,
  allExpanded = false,
  type,
  page = 'radarsCompetenceGrouper'
}) {
  const classes = makeStyles()

  const [expanded, setExpanded] = useState(allExpanded)
  const [usersSelected, setUsersSelected] = useState([])
  const [labelsRadar, setLabelsRadar] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const mapScore = (scoreAverage ?? []).map((item) => {
    return {
      checked: false,
      competenceGrouperName: item?.competenceGrouperName,
      average: item?.average
    }
  })

  const [filter, setFilter] = useState(mapScore)

  const onChangeFilter = useCallback((data) => {
    setFilter(data)
  }, [])

  const handleRemove = (data) => {
    onSelectUsers(data)
  }

  const onSelectUsers = async ({
    id,
    name,
    email,
    occupationArea,
    assignment,
    imageProfile
  }) => {
    const exists = usersSelected.findIndex(function (item, i) {
      return item.id === id
    })
    if (exists === -1) {
      try {
        const userMetrics = await getMetricsUsers({ id })

        if (page === 'radarsCompetenceGrouper') {
          const index = userMetrics.radarsCompetenceGrouper.findIndex(function (
            item,
            i
          ) {
            return item.category === categoryName
          })
          if (index >= 0) {
            setUsersSelected([
              {
                id: id,
                name: name,
                email: email,
                occupationArea: occupationArea,
                assignment: assignment,
                imageProfile: imageProfile,
                scoreAverage: userMetrics.radarsCompetenceGrouper[index]
              },
              ...usersSelected
            ])
          }
        }

        if (page === 'radarsCompetence') {
          const radarsCompetence = userMetrics.radarsCompetence.flat()
          const index = radarsCompetence.findIndex(function (item, i) {
            return item.categoryName === categoryName
          })
          if (index >= 0) {
            const index2 = radarsCompetence[index]?.competenceGroups.findIndex(
              function (item, i) {
                return item.competenceGroupName === competenceGroupName
              }
            )
            setUsersSelected([
              {
                id: id,
                name: name,
                email: email,
                occupationArea: occupationArea,
                assignment: assignment,
                imageProfile: imageProfile,
                scoreAverage: radarsCompetence[index]?.competenceGroups[index2]
              },
              ...usersSelected
            ])
          }
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setUsersSelected(usersSelected.filter((item) => item.id !== id))
    }
  }

  const ComponentRadarChart = type === 'user' ? RadarChartUser : RadarChartGroup

  return (
    <BoxHero
      title={competenceGroupName || categoryName}
      options={
        <>
          {expanded && (
            <>
              {type === 'userGroup' && (
                <ChartFilterCompare
                  items={users}
                  onSelectUsers={onSelectUsers}
                  setSelectedUsers={setSelectedUsers}
                  category={categoryName}
                />
              )}
              {/* <ChartFilterEvolution
                items={filter}
                category={categoryName}
                labels={labelsRadar}
                setLabelsRadar={setLabelsRadar}
                onChangeFilter={onChangeFilter}
              /> */}
            </>
          )}
          <Tooltip title={expanded ? 'Fechar' : 'Abrir'}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </>
      }
      style={{ textTransform: 'capitalize' }}
      className={!expanded && classes.accordion}
      removeDivider={!expanded}
    >
      <>
        {expanded && (
          <ComponentRadarChart
            data={filter}
            labels={labelsRadar}
            users={usersSelected}
            usersSelected={selectedUsers}
            categoryName={categoryName}
            handleRemove={handleRemove}
            page={page}
          />
        )}
      </>
    </BoxHero>
  )
}

AccordionMeasurement.propTypes = {
  categoryName: PropTypes.string,
  scoreAverage: PropTypes.array,
  allExpanded: PropTypes.bool,
  type: PropTypes.oneOf(['user', 'userGroup'])
}
