import React, { useState } from 'react'
import { useLoadingContext } from 'src/context/LoadingContext'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@material-ui/styles'
import {
  getMetricsCustomersUsers,
  getMetricsUserGroups,
  getMetricsUserGroupsById,
  getMetricsUsers
} from 'src/services/hooks/metrics/useMetrics'
import { Tooltip } from 'src/components'
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material'
import { DateRangePicker, CustomProvider } from 'rsuite'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { IconButton, Typography, Box, Button } from '@material-ui/core'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from './style'
import 'rsuite/dist/rsuite.css'
import ptBR from 'rsuite/locales/pt_BR'
import { currentPeriodYear } from 'src/utils/constants'
import { getFormatDate } from 'src/utils/getFormatDate'

CalendarPeriodImpressions.propTypes = {
  onChangeDataMetrics: PropTypes.func,
  filterSelected: PropTypes.string,
  type: PropTypes.oneOf(['user', 'group']),
  isShowIcon: PropTypes.bool,
  isSearchById: PropTypes.bool,
  identification: PropTypes.string,
  category: PropTypes.string
}

export function CalendarPeriodImpressions({ onSelectPeriod, isShowIcon }) {
  const theme = useTheme()
  const currentPeriodStart = new Date(currentPeriodYear?.firstDay)
  const currentPeriodFinish = new Date(currentPeriodYear?.lastDay)

  const [currentPeriodSelected, setCurrentPeriodSelected] = useState({
    formatedToShow: {
      start: getFormatDate(currentPeriodStart),
      finish: getFormatDate(currentPeriodFinish)
    },
    formatedToCalendar: [currentPeriodStart, currentPeriodFinish]
  })
  const [isOpen, setIsOpen] = useState(false)
  const classes = makeStyles({
    isOpen
  })
  const isOpenComponent = isOpen ? classes.calendarOpen : classes.calendarClose

  function onHandleCalendarOpen() {
    setIsOpen(!isOpen)
  }

  function onSelectCalendarPeriod({ value, shouldHandlerCalendar }) {
    const startDate = new Date(value[0])
    const finishDate = new Date(value[1])
    const formatedDatePayload = {
      start: `${startDate.getFullYear()}-${(
        '0' +
        (startDate.getMonth() + 1)
      ).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`,
      finish: `${finishDate.getFullYear()}-${(
        '0' +
        (finishDate.getMonth() + 1)
      ).slice(-2)}-${('0' + finishDate.getDate()).slice(-2)}`
    }
    setCurrentPeriodSelected({
      formatedToShow: {
        start: getFormatDate(startDate),
        finish: getFormatDate(finishDate)
      },
      formatedToCalendar: [value[0], value[1]]
    })
    onSelectPeriod(formatedDatePayload)
    if (!shouldHandlerCalendar) {
      onHandleCalendarOpen()
    }
  }

  function onResetPeriod() {
    onSelectCalendarPeriod({
      value: [currentPeriodYear?.firstDay, currentPeriodYear?.lastDay],
      shouldHandlerCalendar: true
    })
  }

  function RenderCalendar() {
    return (
      <div className={clsx(classes.containerCalendar, isOpenComponent)}>
        <CustomProvider locale={ptBR}>
          <DateRangePicker
            className={classes.containerDateRangePicker}
            open={isOpen}
            showOneCalendar
            defaultOpen
            format="dd/MM/yyyy"
            value={currentPeriodSelected.formatedToCalendar}
            placeholder="Selecione o período"
            onOk={(e) => onSelectCalendarPeriod({ value: e })}
          />
        </CustomProvider>
      </div>
    )
  }

  return (
    <div className={classes.containerInput}>
      <div className={classes.auxBackground} onClick={onHandleCalendarOpen} />
      <Box display="flex" alignItems="center">
        <Tooltip
          title={`Exibindo período de ${currentPeriodSelected?.formatedToShow?.start} - ${currentPeriodSelected?.formatedToShow?.finish}`}
        >
          <Button
            onClick={onHandleCalendarOpen}
            className={classes.buttonPeriod}
          >
            {isShowIcon && (
              <CalendarTodayIcon htmlColor={theme.palette.primary.main} />
            )}
            <Typography className={classes.currentPeriod}>
              {`${currentPeriodSelected?.formatedToShow?.start} - ${currentPeriodSelected?.formatedToShow?.finish}`}
            </Typography>
          </Button>
        </Tooltip>
        <Tooltip
          title={`Limpar período ${currentPeriodSelected?.formatedToShow?.start} - ${currentPeriodSelected?.formatedToShow?.finish}`}
        >
          <IconButton onClick={onResetPeriod} className={classes.iconButton}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <RenderCalendar />
    </div>
  )
}
