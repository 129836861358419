import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  backgroundBox: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 2
  },
  formSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formUserActivation: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  formCompetence: {
    position: 'relative',
    zIndex: (props) => (props.isOpenCompetences ? 3 : 0)
  },
  competenceButton: {
    width: '100%',
    height: '56px',
    borderColor: (props) =>
      props.isOpenCompetences ? props.primaryColor : 'rgba(0, 0, 0, 0.23)',
    borderWidth: (props) => `${props.isOpenCompetences ? '2px' : '1px'}`,
    backgroundColor: 'transparent',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    transition: 'all .1s',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #4A4A4A'
    }
  },
  competenceButtonErro: {
    borderColor: '#f44336 !important',
    '&:hover': {
      border: '1px solid #f44336 !important'
    }
  },
  competenceButtonText: {
    color: '#000000',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18.75)
  },
  competenceBox: {
    position: 'absolute',
    left: '-6px',
    bototm: 0,
    backgroundColor: '#fff',
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'all .1s',
    width: 'calc(100% + 12px)',
    padding: '0 6px'
  },
  competenceBoxOpen: {
    maxHeight: '38vh'
  },
  competenceItems: {
    padding: 0,
    margin: 0,
    maxHeight: '30vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  inlineCenter: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10
  },
  iconEdit: {
    display: 'none'
  },
  iconCircle: {
    display: 'block'
  },
  iconCheck: {
    width: '50px'
  },
  competenceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 20px',
    backgroundColor: '#F7F7FC',
    borderBottom: '1px solid #CFD2D3',
    cursor: 'pointer',
    '& .MuiButton-root': {
      padding: 0
    },
    '&:hover': {
      '& $competenceItemButton': {
        padding: '7px 9px !important',
        height: 'auto'
      },
      '& $iconCircle': {
        display: 'none'
      },
      '& $iconEdit': {
        display: 'block'
      }
    }
  },
  competenceItemText: {
    color: '#434343',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16.41)
  },
  competenceOptions: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px'
  },
  competenceItemButton: {
    backgroundColor: '#434343',
    color: '#fff',
    borderRadius: 8,
    cursor: 'pointer',
    fontWeight: '400',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    marginRight: '10px',
    height: '0',
    overflow: 'hidden',
    transition: 'all .1s',
    '&:hover': {
      backgroundColor: '#434343',
      color: '#fff'
    }
  },
  competenceFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 32px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: '10px',
    borderRadius: '0 0 10px 10px'
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formControlButton: {
    display: 'flex',
    columnGap: '20px'
  },
  formInfo: {
    fontSize: theme.typography.pxToRem(14)
  },
  wordCloud: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    rowGap: 25
  },
  modalButtons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: '15px'
  },
  textStatusGroup: {
    display: 'flex',
    width: 140
  },
  competenceEdit: {
    padding: '12px 20px 12px 15px',
    backgroundColor: '#F7F7FC',
    borderBottom: '1px solid #CFD2D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  competenceInput: {
    height: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 10px',
    display: 'inline-block',
    color: '#000000',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18.75),
    flex: 1,
    backgroundColor: '#F7F7FC',
    border: 'none',
    borderBottom: '1px solid',
    outline: 'transparent'
  },
  wordCounter: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: "10px"
  }
}))
