import React from 'react'
import { BadgeTooltip } from '../BadgeTooltip'
import { Typography, Box } from '@material-ui/core'
import {
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import moment from 'moment'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'

export function CardMetricsCelebrations({ items = [] }) {
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()
  const shouldRenderCards = items.length > 0;
  return (
    <>
      {shouldRenderCards ? items?.map((data) =>
        <div key={data.id} className={classes.boxContainer}>
          <div className={classes.boxDescription}>
            <p>{data.description}</p>
          </div>
          <div className={classes.boxAvatar}>
            <span>
              <p className={classes.toUsers}>Para:</p>
              <div className={classes.userScroll}>
                {data.withUsersExpanded.map((item, index) =>
                  <BadgeTooltip
                    key={index}
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    user={item}
                  />
                )}
              </div>
            </span>
          </div>
          <div className={classes.boxFooter}>
            <BadgeTooltip
              placement="top"
              disableFocusListener
              disableTouchListener
              user={data.fromUser}
            />
            <span>
              <h1>{data.fromUser.name}</h1>
              <h2>{moment(data.createAt).format('LL')}</h2>
            </span>
          </div>
        </div>
      ) : (
          <Box className={globalClasses.notFoundContainer}>
            <AssessmentOutlinedIcon
              className={globalClasses.notFoundIcon}
            />
            <Typography className={globalClasses.notFoundTitle}>
              Nenhuma informação
            </Typography>
            <Typography className={globalClasses.notFoundDescription}>
              Não há celebrações para este usuário.
            </Typography>
          </Box>
        )
      }
    </>
  )
}
