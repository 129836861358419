import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import clsx from 'clsx'
import {
  HeaderBreadcrumbs,
  NewBoxWidget,
  Helmet,
  Tooltip
} from 'src/components'
import { IconButton, Box, Typography } from '@material-ui/core'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import { getCustumerById } from 'src/services/hooks/customers/useCustumers'
import {
  ColorLensOutlined as ColorLensOutlinedIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  FeedbackOutlined as FeedbackOutlinedIcon
} from '@mui/icons-material'
import { LogoGrey, Favicon as FaviconDefault } from 'src/assets'
import PlatformLogo from './Partials/PlatformLogo'
import Favicon from './Partials/Favicon'
import Color from './Partials/Color'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './style'

function AppearancePreferences() {
  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [role] = useState([1, 2])
  const { id: customerId } = useParams()

  const [isSelectedLogo, setIsSelectedLogo] = useState(true)
  const [isSelectedFavicon, setIsSelectedFavicon] = useState(false)
  const [isSelectedColor, setIsSelectedColor] = useState(false)

  const [dataAppearance, setDataAppearance] = useState({})

  const customerName = dataAppearance.name
  const customerLogo = dataAppearance.logo ?? LogoGrey
  const customerFavicon = dataAppearance.favicon ?? FaviconDefault
  const customerPrimaryColor = dataAppearance.primaryColor ?? '#38d430'

  const classes = makeStyles({
    customerPrimaryColor: customerPrimaryColor
  })

  function onCloseEdit() {
    setIsSelectedColor(false)
    setIsSelectedFavicon(false)
    setIsSelectedLogo(false)
  }
  function onCloseNextLogo() {
    setIsSelectedFavicon(true)
    setIsSelectedLogo(false)
  }
  function onCloseNextIcon() {
    setIsSelectedColor(true)
    setIsSelectedFavicon(false)
  }
  function onHandleLogo() {
    setIsSelectedLogo(!isSelectedLogo)
  }
  function onHandleFavicon() {
    setIsSelectedFavicon(!isSelectedFavicon)
  }
  function onHandleColor() {
    setIsSelectedColor(!isSelectedColor)
  }

  useEffect(() => {
    async function getDataAppearance() {
      try {
        onOpenLoading()
        const isCustomerId = !!customerId
        if (!isCustomerId) {
          navigate('/customers')
        }
        const data = await getCustumerById(customerId)
        setDataAppearance(data)
      } catch (error) {
        const messageError = messageResponse(error?.response)
        onCallAlert({
          type: 'error',
          message: messageError ?? notifications.global.error
        })
      } finally {
        onCloseLoading()
      }
    }
    getDataAppearance()
  }, [])

  function onChangeDataAppearance({ key, value }) {
    setDataAppearance({
      ...dataAppearance,
      [key]: value
    })
  }

  // <Main colorEdit={customerPrimaryColor} getImageLogoPlatform={customerLogo}>

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Preferências de aparência" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<ColorLensOutlinedIcon />}
          links={[{ name: 'Preferências de aparência' }]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <NewBoxWidget
          title="Preferências de aparência"
          subtitle="Adicione e edite a aparência da sua plataforma"
          options={
            <Tooltip
              placement="top"
              title={
                <Typography className={globalClasses.tooltipText}>
                  <strong>Para os logos: </strong>
                  De preferência a arquivos de imagem sem fundo (.png) com
                  tamanho não maior que 2000x2000px e 10mb
                </Typography>
              }
            >
              <IconButton>
                <FeedbackOutlinedIcon html={customerPrimaryColor} />
              </IconButton>
            </Tooltip>
          }
          noBodyPadding
          width={420}
        >
          <Box className={classes.boxOptions}>
            <ul
              className={clsx(
                classes.listPreferencesContentTable,
                isSelectedLogo ? classes.selected : '',
                isSelectedFavicon | isSelectedColor ? classes.opacity : ''
              )}
              onClick={() => {
                onHandleLogo()
                setIsSelectedFavicon(false)
                setIsSelectedColor(false)
              }}
            >
              <li className={classes.listPreferences}>
                <p className={classes.listPreferencesTextTitle}>
                  Logo principal
                </p>
                <p
                  className={clsx(
                    classes.listPreferencesTextSubTitle,
                    isSelectedLogo ? classes.descriptionNone : ''
                  )}
                >
                  Adicione e edite o logo principal da plataforma
                </p>
              </li>
              {customerLogo && (
                <li className={classes.imgPreferencesOne}>
                  <img
                    className={classes.boxImgPlatform}
                    src={customerLogo}
                    alt={`Logo - ${customerName}`}
                  />
                </li>
              )}
              <li className={classes.iconPreferences}>
                <ArrowForwardIosIcon />
              </li>
            </ul>
            <ul
              id="button"
              className={clsx(
                classes.listPreferencesContentTable,
                isSelectedFavicon ? classes.selected : '',
                isSelectedLogo | isSelectedColor ? classes.opacity : ''
              )}
              onClick={() => {
                onHandleFavicon()
                setIsSelectedLogo(false)
                setIsSelectedColor(false)
              }}
            >
              <li className={classes.listPreferences}>
                <p className={classes.listPreferencesTextTitle}>Favicon</p>
                <p
                  className={clsx(
                    classes.listPreferencesTextSubTitle,
                    isSelectedFavicon ? classes.descriptionNone : ''
                  )}
                >
                  É o ícone simplificado que fica na aba do seu navegador
                </p>
              </li>
              {customerFavicon && (
                <li className={classes.imgPreferencesTwo}>
                  <img
                    className={classes.boxImgPlatform}
                    src={customerFavicon}
                    alt={`Favicon - ${customerName}`}
                  />
                </li>
              )}
              <li className={classes.iconPreferences}>
                <ArrowForwardIosIcon />
              </li>
            </ul>
            <ul
              id="button"
              className={clsx(
                classes.listPreferencesContentTable,
                isSelectedColor ? classes.selected : '',
                isSelectedFavicon | isSelectedLogo ? classes.opacity : ''
              )}
              onClick={() => {
                onHandleColor()
                setIsSelectedLogo(false)
                setIsSelectedFavicon(false)
              }}
            >
              <li className={classes.listPreferences}>
                <p className={classes.listPreferencesTextTitle}>Cor</p>
                <p
                  className={clsx(
                    classes.listPreferencesTextSubTitle,
                    isSelectedColor ? classes.descriptionNone : ''
                  )}
                >
                  Escolha a cor principal da sua plataforma
                </p>
              </li>
              <li className={classes.colorPreferences}></li>
              <li className={classes.iconPreferences}>
                <ArrowForwardIosIcon />
              </li>
            </ul>
          </Box>
        </NewBoxWidget>
        {isSelectedLogo && (
          <PlatformLogo
            onCloseNextLogo={onCloseNextLogo}
            onConfirmLogo={(value) =>
              onChangeDataAppearance({ key: 'logo', value })
            }
            onCloseEdit={onCloseEdit}
            dataAppearance={dataAppearance}
          />
        )}
        {isSelectedFavicon && (
          <Favicon
            onCloseEdit={onCloseEdit}
            onConfirmFavicon={(value) =>
              onChangeDataAppearance({ key: 'favicon', value })
            }
            onCloseNextIcon={onCloseNextIcon}
            dataAppearance={dataAppearance}
          />
        )}
        {isSelectedColor && (
          <Color
            onCloseEdit={onCloseEdit}
            setCurrentColorEdit={(value) =>
              onChangeDataAppearance({ key: 'primaryColor', value })
            }
            dataAppearance={dataAppearance}
          />
        )}
      </Box>
    </RoleBasedGuard>
  )
}

export default AppearancePreferences
