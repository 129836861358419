import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

import Main from './Main'
import Header from './Header'
import NavVertical from './Nav/NavVertical'

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} />
  )

  return (
    <>
      <Header onOpenNav={handleOpen} />
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 }
        }}
      >
        {renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  )
}
