import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useCallback } from 'react'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { defaultSettings } from 'src/utils/config-setting'

const initialState = {
  ...defaultSettings,
  onToggleSidebarOpen: () => {},

  onChangeMetricsCurrentTab: () => {},
  onToggleMetricsUserView: () => {},
  onToggleMetricsGroupView: () => {},

  onResetSetting: () => {}
}

export const SettingsContext = createContext(initialState)

export const useSettingsContext = () => {
  const context = useContext(SettingsContext)

  if (!context)
    throw new Error('useSettingsContext must be use inside SettingsProvider')

  return context
}

SettingsProvider.propTypes = {
  children: PropTypes.node
}

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings)

  const onToggleSidebarOpen = useCallback(() => {
    const sidebarOpen = !settings.sidebarOpen
    setSettings({ ...settings, sidebarOpen })
  }, [setSettings, settings])

  const onChangeMetricsCurrentTab = useCallback(
    (value) => {
      const metricsCurrentTab = value
      setSettings({ ...settings, metricsCurrentTab })
    },
    [setSettings, settings]
  )

  const onToggleMetricsUserView = useCallback(() => {
    const metricsUserView =
      settings.metricsUserView === 'simplified' ? 'details' : 'simplified'
    setSettings({ ...settings, metricsUserView })
  }, [setSettings, settings])

  const onToggleMetricsGroupView = useCallback(() => {
    const metricsGroupView =
      settings.metricsGroupView === 'simplified' ? 'details' : 'simplified'
    setSettings({ ...settings, metricsGroupView })
  }, [setSettings, settings])

  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings)
  }, [setSettings])

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      onToggleSidebarOpen,

      onChangeMetricsCurrentTab,
      onToggleMetricsUserView,
      onToggleMetricsGroupView,

      onResetSetting
    }),
    [
      settings,
      onToggleSidebarOpen,

      onChangeMetricsCurrentTab,
      onToggleMetricsUserView,
      onToggleMetricsGroupView,

      onResetSetting
    ]
  )

  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  )
}
