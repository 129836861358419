import React, { useCallback, useState, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import { useTheme } from '@material-ui/styles'
import { styled } from '@mui/material/styles'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { useNavigate } from 'react-router-dom'
import { enumAssignment } from 'src/utils/constants'
import {
  EditOutlined as EditOutlinedIcon,
  PermContactCalendarOutlined as PermContactCalendarOutlinedIcon,
  PersonAdd as PersonAddIcon
} from '@material-ui/icons'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import {
  NewBoxWidget,
  Button,
  HeaderBreadcrumbs,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound,
  Helmet
} from 'src/components'
import { IconButton } from '@material-ui/core'
import { getComparator, applySortFilter } from 'src/utils'
import { getUsers } from 'src/services/hooks/users/useUsers'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { maskCPF } from 'src/utils/regex/masks'
import makeStyles from './style'

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'document', label: 'CPF', alignRight: false },
  { id: 'occupationArea', label: 'Área de atuação', alignRight: false },
  { id: 'assignment', label: 'Experiência', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Editar', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '8px 8px 8px 16px'
  }
}))

function ListUsers() {
  const theme = useTheme()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const navigate = useNavigate()

  const [role] = useState([1, 2])
  const { user } = useAuth()
  const currentRole = user?.occupation

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  const getListUsers = useCallback(async (userRole) => {
    try {
      setIsLoading(true)
      const data = await getUsers()
      const response =
        userRole !== 1 ? data.filter((item) => item.occupation !== 1) : data
      setData(response)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getListUsers(currentRole)
  }, [currentRole, getListUsers])

  function handleEdit(id) {
    navigate(`/users/edit/${id}`)
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Usuários" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<PermContactCalendarOutlinedIcon />}
          links={[{ name: 'Usuários' }]}
        />
        <Button
          label="Adicionar usuário"
          icon={PersonAddIcon}
          variant="dark"
          onClick={() => navigate('/users/add')}
        />
      </Box>
      <Box className={globalClasses.content}>
        <NewBoxWidget
          title="Usuários"
          subtitle="Visualize e edite dados dos usuários da plataforma"
          options={
            <TableToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          }
          actions={
            <Box className={classes.actions}>
              <Box />
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={'Registros por página'}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          }
          fullBody
          borderActions
          noBodyPadding
        >
          <TableContainer
            sx={{
              maxHeight: '100%',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const {
                      id,
                      name,
                      email,
                      document,
                      occupationArea,
                      assignment,
                      isActive
                    } = row
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="left">{email}</StyledTableCell>
                        <StyledTableCell align="left">
                          {maskCPF(document) ?? 'Não informado'}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {occupationArea}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {
                            enumAssignment.find(
                              (items) => items.value === assignment
                            )?.title
                          }
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {isActive ? 'Ativo' : 'Inativo'}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <IconButton
                            className={classes.icon}
                            onClick={() => handleEdit(id)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <TableLoading />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {data.length === 0 && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <TableNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Nenhum registro encontrado!
                      </Typography>

                      <Typography variant="body2">
                        Nenhum resultado encontrado para &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Tente verificar erros de digitação.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </NewBoxWidget>
      </Box>
    </RoleBasedGuard>
  )
}

export default ListUsers
