import React from 'react'
import { useTheme } from '@material-ui/styles'
import { MultiProgressBar, Avatar, StarFavorite } from 'src/components'
import { Typography, Box } from '@material-ui/core'
import { enumAssignment } from 'src/@enum/user'
import { getImageNameColor } from 'src/utils'
import makeStyles from './style'

export function CardUserNavigation({ data, onClose, userId }) {
  const theme = useTheme()
  const classes = makeStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box display="flex">
          <Box className={classes.leadersListAvatars}>
            <Avatar
              src={data.imageProfile}
              aria-label={data.name}
              alt={data.name}
              color={getImageNameColor(data.name).color}
            >
              {getImageNameColor(data.name).name}
            </Avatar>
          </Box>
          <Box className={classes.headerInformations}>
            <strong>{data.name}</strong>
            <Typography className={classes.date}>
              {data.occupationAreaName} - {enumAssignment[data.assignment]}
            </Typography>
            <p>{data.email}</p>
          </Box>
        </Box>
        <StarFavorite
          checked={data?.isFavorite}
          componentId={data?.id}
          userId={userId}
          favoriteId={data?.favoriteId}
          className={classes.starOption}
          type="user"
          colorIcon={theme.palette.primary.main}
        />
      </Box>
      <Box className={classes.leadersList}>
        <Box className={classes.leadersMultiProgressBar}>
          {data?.averageMetricsByCategoryType && (
            <MultiProgressBar data={data?.averageMetricsByCategoryType} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
