import axios, { tokenAuthorization } from 'src/utils/axios'
import { NOTIFICATION_BADGE } from 'src/services/api'

export async function getNotifications() {
  const response = await axios.get(NOTIFICATION_BADGE, tokenAuthorization())
  const { data } = response?.data
  return data
}

export async function getNotificationsById(id) {
  const response = await axios.get(
    NOTIFICATION_BADGE + `/user/${id}`,
    tokenAuthorization()
  )
  const { data } = response?.data
  return data
}

export async function getNotificationsNotWasViewedById(id) {
  const response = await axios.get(
    NOTIFICATION_BADGE + `/user/${id}`,
    tokenAuthorization()
  )
  const { data } = response?.data
  const newData = data.filter((item) => !item.wasViewed)
  return newData
}

export async function putNotification(id, payload) {
  const response = await axios.put(
    NOTIFICATION_BADGE + `/${id}`,
    payload,
    tokenAuthorization()
  )
  return response
}

// export async function postNotification(body) {
//   const bodyTest = {
//     userId: '63a1eb8ad179c97b12939b6b',
//     title: 'Teste 1',
//     description: 'SAhsa asuauh SUAuhu',
//     redirect: '/adsdads/123',
//     wasViewed: false,
//     isActive: true
//   }
//   const token = tokenAuthorization().headers
//   const parameters = {
//     method: 'POST',
//     headers: token
//   }
//   const response = await axios.post(NOTIFICATION_BADGE, bodyTest, parameters)
//   return response
// }
