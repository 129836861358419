import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { validadePassword } from 'src/utils/form/rules'
import makeStyles from './style'

export function PasswordRule({ isVisible = false, value = '' }) {
  const theme = useTheme()
  const classes = makeStyles({ isVisible })

  return (
    <div className={classes.container}>
      {validadePassword(value).map(({ id, title, status }) => (
        <div key={id} className={classes.ruleItem}>
          {status ? (
            <CheckOutlinedIcon
              sx={{ color: theme.palette.primary.main, width: 20, height: 20 }}
            />
          ) : (
            <CloseOutlinedIcon
              sx={{ color: '#E32929', width: 20, height: 20 }}
            />
          )}
          <Typography variant="inherit" className={classes.ruleDescription}>
            {title}
          </Typography>
        </div>
      ))}
    </div>
  )
}
