import axios, { tokenAuthorization } from 'src/utils/axios'
import {
  CYCLE_SETTING,
  CYCLE_SETTING_INTERVAL_TYPE,
  CYCLE_SETTING_REPEAT_ON_TYPE,
  CYCLE_SETTING_SEARCH
} from 'src/services/api'

export async function getIntervalType() {
  const response = await axios.get(CYCLE_SETTING_INTERVAL_TYPE)
  const { data } = response.data
  return data
}

export async function getRepeatOnType() {
  const response = await axios.get(CYCLE_SETTING_REPEAT_ON_TYPE)
  const { data } = response.data
  return data
}

export async function postCycleSetting(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(CYCLE_SETTING, body, parameters)
  return response
}

export async function putCycleSetting(id, body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'PUT',
    headers: token
  }
  const response = await axios.put(CYCLE_SETTING + `/${id}`, body, parameters)
  return response
}

export async function postCycleSettingSearch(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(CYCLE_SETTING_SEARCH, body, parameters)
  const { data } = response.data
  return data[0]
}
