import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { Typography, Box, IconButton } from '@material-ui/core'
import { FeedbackOutlined as FeedbackOutlinedIcon } from '@material-ui/icons'
import { putUser } from 'src/services/hooks/users/useUsers'
import { postFileUpload } from 'src/services/hooks/fileUpload/useFileUpload'
import { enumAssignment } from 'src/@enum/user'
import { convertBlobToFile } from 'src/utils'
import {
  Tooltip,
  UploadImageProfile,
  NewBoxWidget,
  Button
} from 'src/components'
import classnames from 'classnames'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'

function PhotoProfile({ isEdit = false, data }) {
  const theme = useTheme()

  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { notifications, onCallAlert } = useAlertContext()

  const [isSelectedLogo, setIsSelectedLogo] = useState(true)
  const [getImageLogoPlatform, setGetImageLogoPlatform] = useState()
  const [imageProfile, setImageProfile] = useState('')

  function onHandleLogo() {
    setIsSelectedLogo(!isSelectedLogo)
  }

  function onCloseEdit() {
    setIsSelectedLogo(false)
  }

  async function onHandleSubmit() {
    try {
      onOpenLoading()
      const payloadFileUpload = await convertBlobToFile(
        imageProfile,
        data?.id,
        1
      )
      const urlImagem = await postFileUpload(payloadFileUpload)
      if (urlImagem) {
        const payload = {
          ...data,
          imageProfile: urlImagem
        }
        await putUser(payload)
        onCallAlert({
          type: 'success',
          message: notifications.profile.success.editUser
        })
        navigate('/profile')
      }
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  useEffect(() => {
    setImageProfile(getImageLogoPlatform)
  }, [getImageLogoPlatform])

  return (
    <NewBoxWidget
      title="Resumo do perfil"
      className={classnames(classes.container, {
        [classes.containerBlur]: isEdit
      })}
      options={
        <Tooltip
          title={
            <p className={globalClasses.tooltipText}>
              <strong>Foto: </strong>
              De preferências a fotos frontais que mostrem seu rosto.
              <br />
              <br />
              <strong>Arquivo: </strong>
              Selecione arquivos de imagem (.png ou .jpeg) com tamanho não maior
              que 1000x1000px e 10mb.
            </p>
          }
        >
          <IconButton>
            <FeedbackOutlinedIcon
              onClick={() => {
                onHandleLogo()
              }}
              htmlColor={theme.palette.primary.main}
            />
          </IconButton>
        </Tooltip>
      }
      actions={
        <Button
          label="Salvar"
          onClick={onHandleSubmit}
          disabled={!imageProfile}
        />
      }
      fullBody
    >
      {getImageLogoPlatform && null}
      <Box className={classes.cardBoxInforBottom}>
        <UploadImageProfile
          onConfirmImage={setGetImageLogoPlatform}
          onCloseEdit={onCloseEdit}
          aspect={5 / 5}
          imageProfile={data?.imageProfile}
        />
        <Typography className={classes.textName}>{data?.name}</Typography>
        <Typography className={classes.textEmail}>{data?.email}</Typography>
        <Typography className={classes.textArea}>
          {data?.occupationArea} - {enumAssignment[data?.assignment]}
        </Typography>
      </Box>
    </NewBoxWidget>
  )
}

export default PhotoProfile
