import React, { useState } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useTheme } from '@material-ui/styles'
import { Typography, Box, Grid } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AssistantOutlined as AssistantOutlinedIcon } from '@material-ui/icons'
import { Button, NewBoxWidget, HeaderBreadcrumbs, Helmet } from 'src/components'
import { Input, Switch } from 'src/components/Fields'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { postCustomers } from 'src/services/hooks/customers/useCustumers'
import { newClientValidation } from 'src/utils/form/validations'
import { maskCNPJ } from 'src/utils/regex/masks'

function NewClient() {
  const theme = useTheme()
  const globalClasses = useGlobalUseStyles()

  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [role] = useState([1])

  const onSubmit = async (values) => {
    try {
      const payload = {
        name: values?.name,
        email: values?.email.toLowerCase(),
        primaryColor: theme.palette.primary.main,
        document: values?.document,
        isActive: values?.isActive
      }
      onOpenLoading()
      await postCustomers(payload)
      onCallAlert({
        type: 'success',
        message: notifications.customers.success.addNewCustomer
      })
      navigate('/customers')
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Novo cliente" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssistantOutlinedIcon />}
          links={[
            { name: 'Clientes', href: '/customers' },
            { name: 'Novo cliente' }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <Form
          onSubmit={(values) => onSubmit(values)}
          initialValues={{
            name: '',
            email: '',
            document: '',
            isActive: true
          }}
          validate={newClientValidation}
          render={({ handleSubmit, valid }) => (
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <NewBoxWidget
                title="Novo cliente"
                subtitle="Adicione um novo cliente, o novo administrador receberá um e-mail com seu novo acesso:"
                actions={
                  <>
                    <Button
                      variant="underline"
                      label="Cancelar"
                      type="button"
                      onClick={() => navigate('/customers')}
                    />
                    <Button
                      label="Adicionar"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!valid}
                    />
                  </>
                }
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    item
                    style={{
                      alignItems: 'center',
                      direction: 'column'
                    }}
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Input label="Nome do cliente*" name="name" />
                    </Grid>
                    <Grid item xs={12}>
                      <Input label="E-mail*" name="email" />
                    </Grid>
                    <Grid item xs={12}>
                      <Input label="CNPJ*" name="document" parse={maskCNPJ} />
                    </Grid>
                    <Grid item xs={12}>
                      <Switch name="isActive" />
                      <Typography variant="inherit">
                        Status do cliente
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </NewBoxWidget>
            </MuiPickersUtilsProvider>
          )}
        />
      </Box>
    </RoleBasedGuard>
  )
}

export default NewClient
