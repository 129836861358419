import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { Button, Tooltip } from 'src/components'
import makeStyles, { DefaultStyledSlider } from './style'
import getCroppedImg from './Crop'

export function UploadImage({
  dataAppearance,
  onConfirmImage,
  onCloseNext,
  aspect,
  onCloseEdit
}) {
  const customerPrimaryColor = dataAppearance.primaryColor ?? '#38d430'
  const classes = makeStyles({ customerPrimaryColor: customerPrimaryColor })

  const [isSelectedImage, setIsSelectedImage] = useState(false)
  const [image, setImage] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [zoom, setZoom] = useState(1)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onClose = useCallback(() => {
    setIsSelectedImage(false)
    setCroppedImage(null)
    setImage(null)
    onCloseEdit()
  }, [onCloseEdit])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels)
      setCroppedImage(croppedImage)
      onConfirmImage(croppedImage)
      setIsSelectedImage(false)
      onCloseNext()
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, image, onConfirmImage, onCloseNext])

  const onHandleImageUpload = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    setIsSelectedImage(true)
  }

  return (
    <>
      <div className={classes.container}>
        {isSelectedImage ? (
          <>
            <div className={classes.containerCrop}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
                //cropShape="round" // circle
                style={{
                  containerStyle: {
                    backgroundColor: 'transparent'
                  }
                }}
              />
            </div>
            <div className={classes.controls}>
              <DefaultStyledSlider
                customerPrimaryColor={customerPrimaryColor}
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
          </>
        ) : (
          <div className={classes.containerCrop}>
            <Tooltip placement="top" title="Selecionar imagem">
              <label className={classes.boxUplod}>
                <input
                  type="file"
                  name="cover"
                  onChange={onHandleImageUpload}
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                />
                {croppedImage ? (
                  <img
                    className={classes.imgCropped}
                    src={croppedImage}
                    alt="cropped"
                  />
                ) : (
                  <AddPhotoAlternateIcon sx={{ color: '#fff', fontSize: 90 }} />
                )}
              </label>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={classes.controlButton}>
        <Button label="Cancelar" onClick={onClose} variant="dark" />
        {isSelectedImage && (
          <Button
            label="Confirmar"
            onClick={showCroppedImage}
            customerPrimaryColor={customerPrimaryColor}
          />
        )}
      </div>
    </>
  )
}
