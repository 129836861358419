import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Box, Typography } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

export default function TableLoading() {
  const theme = useTheme()

  return (
    <Box
      style={{
        marginTop: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <CircularProgress
        style={{
          width: 46,
          height: 46,
          color: '#CFD2D3'
        }}
      />
      <Typography
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 18,
          color: theme.palette.primary.main
        }}
      >
        Carregando...
      </Typography>
    </Box>
  )
}
