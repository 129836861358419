import React, { useState, useCallback, useEffect } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { Form } from 'react-final-form'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  GroupOutlined as GroupOutlinedIcon,
  Edit as EditIcon
} from '@material-ui/icons'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { IconButton, Switch } from '@material-ui/core'
import {
  Button,
  NewBoxWidget,
  Tooltip,
  HeaderBreadcrumbs,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound,
  Helmet
} from 'src/components'
import { Input, MuiSwitch } from 'src/components/Fields'
import { editUserGroupValidation } from 'src/utils/form/validations'
import {
  putUserGroups,
  getUserGroupDataById
} from 'src/services/hooks/userGroup/useUserGroup'
import { getUsers } from 'src/services/hooks/users/useUsers'
import { enumAssignment } from 'src/@enum/user'
import { getComparator, applySortFilter } from 'src/utils'
import { maskCPF } from 'src/utils/regex/masks'
import makeStyles, { MuiCheckbox } from './style'

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'document', label: 'CPF', alignRight: false },
  { id: 'occupationArea', label: 'Área de atuação', alignRight: false },
  { id: 'assignment', label: 'Experiência', alignRight: false },
  { id: 'leaders', label: 'Líder', alignRight: false },
  { id: 'groups', label: 'No grupo', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '4px 4px 4px 16px'
  }
}))

function EditGroup() {
  const theme = useTheme()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const [role] = useState([1, 2])

  const { id } = useParams()
  const { user } = useAuth()

  const currentCustomerId = user?.customerId

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [isOpenEdit, setOpenEdit] = useState(false)

  const [data, setData] = useState([])
  const [dataGroup, setDataGroup] = useState()
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [checkedStatus, setCheckedStatus] = useState(false)

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selectedLeader, setSelectedLeader] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClickUserGroup = (event, id) => {
    const selectedIndex = selectedGroup.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(selectedGroup, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(selectedGroup.slice(1))
    } else if (selectedIndex === selectedGroup.length - 1) {
      selected = selected.concat(selectedGroup.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        selectedGroup.slice(0, selectedIndex),
        selectedGroup.slice(selectedIndex + 1)
      )
    }
    setSelectedGroup(selected)
  }

  const handleClickUserLeader = (event, id) => {
    const selectedIndex = selectedLeader.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(selectedLeader, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(selectedLeader.slice(1))
    } else if (selectedIndex === selectedLeader.length - 1) {
      selected = selected.concat(selectedLeader.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        selectedLeader.slice(0, selectedIndex),
        selectedLeader.slice(selectedIndex + 1)
      )
    }
    setSelectedLeader(selected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  const getListUsers = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getUsers()
      setData(response)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  const getUserGroup = useCallback(async () => {
    try {
      const response = await getUserGroupDataById(id)
      setCheckedStatus(response?.isActive)
      setDataGroup(response)
      setSelectedGroup(response.members)
      setSelectedLeader(response.leaders)
    } catch (e) {
      console.error(e)
    }
  }, [id])

  useEffect(() => {
    getListUsers()
    getUserGroup(id)
  }, [getListUsers, getUserGroup, id])

  const onSubmit = async (values) => {
    try {
      if (!dataGroup?.name && !name) {
        onCallAlert({
          type: 'error',
          message: 'De um nome ao grupo para prosseguir'
        })
        return
      }

      if (checkedStatus) {
        if (!selectedGroup.length || !selectedLeader.length) {
          onCallAlert({
            type: 'error',
            message: 'Selecione ao menos um membro e um líder'
          })
          return
        }
      }

      const leaderHasNoGroup = selectedLeader.filter(
        (item) => !selectedGroup.includes(item)
      )
      if (leaderHasNoGroup.length) {
        onCallAlert({
          type: 'error',
          message: 'Você só pode atribuir papel de líder para membros do grupo'
        })
        return
      }

      const payload = {
        id: dataGroup?.id,
        name: name || dataGroup?.name,
        customerId: currentCustomerId,
        members: selectedGroup,
        leaders: selectedLeader,
        groupPermissions: dataGroup?.groupPermissions,
        isActive: checkedStatus
      }

      onOpenLoading()
      await putUserGroups(dataGroup?.id, payload)
      await getUserGroup(dataGroup?.id)
      setOpenEdit(false)
      onCallAlert({
        type: 'success',
        message: notifications.userGroups.success.editGroup
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const handleChangeStatus = (event) => {
    setCheckedStatus(event.target.checked)
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title={`${dataGroup?.name || ''}`} />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<GroupOutlinedIcon />}
          links={[
            { name: 'Grupos de usuários', href: '/groups' },
            { name: 'Editar grupo', href: `/groups/edit/${id}` },
            { name: `${dataGroup?.name || ''}` }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <NewBoxWidget
          title={!isOpenEdit && dataGroup?.name}
          subtitle={
            !isOpenEdit &&
            'Gerencie, inclúa e de privilégios de administrador a membros:'
          }
          headerIcon={
            <>
              {!isOpenEdit && (
                <Tooltip placement="top" title="Editar">
                  <IconButton
                    className={classes.iconEditButton}
                    onClick={() => setOpenEdit(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
          header={
            <>
              {isOpenEdit && (
                <>
                  <div className={classes.inputName}>
                    <Form
                      onSubmit={(values) => onSubmit(values)}
                      initialValues={{
                        name: dataGroup?.name
                      }}
                      validate={editUserGroupValidation}
                      render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit}>
                          <Input
                            name="name"
                            label="Editar nome do grupo"
                            onChange={(e) => {
                              form.change('name', e?.target?.value)
                              setName(e?.target?.value)
                            }}
                          />
                        </form>
                      )}
                    />
                  </div>
                  <p className={classes.pTitle}>
                    Gerencie, inclúa e de privilégios de administrador a
                    membros:
                  </p>
                </>
              )}
            </>
          }
          options={
            <TableToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              options={
                <>
                  {dataGroup && (
                    <>
                      <Switch
                        name="isActive"
                        color="primary"
                        checked={checkedStatus}
                        onChange={handleChangeStatus}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Typography variant="inherit">Status do grupo</Typography>
                    </>
                  )}
                </>
              }
            />
          }
          actions={
            <Box className={classes.actions}>
              <Button
                variant="contained"
                label="Salvar alterações"
                onClick={onSubmit}
              />
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={'Registros por página'}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          }
          borderActions
          noBodyPadding
          fullBody
        >
          <TableContainer
            sx={{
              maxHeight: '100%',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: `${theme.palette.primary.main}`,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const {
                      id,
                      name,
                      email,
                      document,
                      occupationArea,
                      assignment,
                      occupation
                    } = row
                    const checkGroup = selectedGroup.indexOf(id) !== -1
                    const checkLeader = selectedLeader.indexOf(id) !== -1
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="left">{email}</StyledTableCell>
                        <StyledTableCell align="left">
                          {maskCPF(document) ?? 'Não informado'}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {occupationArea}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {enumAssignment[assignment]}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {occupation === 3 && (
                            <MuiSwitch
                              checked={checkLeader}
                              onChange={(event) =>
                                handleClickUserLeader(event, id)
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                              color="default"
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell width="10%">
                          <MuiCheckbox
                            primaryColor={theme.palette.primary.main}
                            color="default"
                            checked={checkGroup}
                            onChange={(event) =>
                              handleClickUserGroup(event, id)
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <TableLoading />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {data.length === 0 && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <TableNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <div className={classes.boxInfor}>
                        <Typography variant="h6" paragraph>
                          Nenhum registro encontrado!
                        </Typography>

                        <Typography variant="body2">
                          Nenhum resultado encontrado para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Tente verificar erros de digitação.
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </NewBoxWidget>
      </Box>
    </RoleBasedGuard>
  )
}

export default EditGroup
