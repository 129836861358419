import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  }
}))
