import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  withoutItemsContainer: {
    height: '178px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: theme.palette.primary.main
  },

  withoutItemsText: {
    marginTop: '10px',
    width: '200px',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: '#787878',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.7px'
  }
}))
