import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  notificationActivitiesContainer: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    listStyle: 'none',
    margin: '10px 0px 0px 0px '
  },

  infor: {
    fontSize: theme.typography.pxToRem(14),
    color: '#000000',
    fontWeight: 400,
    fontFamily: 'Roboto',
    letterSpacing: '0.7px',
    lineHeight: theme.typography.pxToRem(18),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    wordBreak: 'break-all',
    paddingRight: '30px'
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    color: '#787878',
    fontWeight: 400,
    fontFamily: 'Roboto',
    letterSpacing: '0.7px',
    lineHeight: theme.typography.pxToRem(14)
  },
  font: {
    fontSize: theme.typography.pxToRem(14),
    color: '#787878',
    fontWeight: 400,
    fontFamily: 'Roboto',
    letterSpacing: '0.7px',
    lineHeight: theme.typography.pxToRem(14)
  },
  boxInfor: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '70px',
    borderRadius: '8px',
    justifyContent: 'space-between'
  },
  boxIcon: {
    width: '35px',
    marginRight: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  linkNofication: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  first: {
    width: '100px',
    alingItems: 'center'
  },
  second: {
    width: 'calc(100% - 300px)',
    alingItems: 'center'
  },
  third: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alingItems: 'center'
  }
}))
