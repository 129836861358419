import React from 'react'
import { Form } from 'react-final-form'
import { Input } from 'src/components/Fields'
import { Helmet, Button } from 'src/components'
import { Box, Typography } from '@material-ui/core'
import LoginLayout from 'src/layouts/Login'
import useStyles from './styles'

function Token() {
  var classes = useStyles()

  return (
    <LoginLayout>
      <Helmet title="Token" />
      <Box>
        <Typography variant="h2" className={classes.greeting}>
          Token de <br /> acesso
        </Typography>
        <Box className={classes.formDividerContainer}>
          <p className={classes.formInfor}>
            Enviamos um código para o <br /> número cadastrado no seu
            <br />
            login
          </p>
        </Box>
        <Form
          onSubmit={() => false}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Input
                name="token"
                label="Código Token"
                placeholder="Digite o Código Token"
                maxLength={30}
              />
            </form>
          )}
        />
        <Box className={classes.formButtons}>
          <Button label="Reenviar código" variant="link" />
          <Button label="Entrar" type="submit" />
        </Box>
      </Box>
    </LoginLayout>
  )
}

export default Token
