import React from 'react'
import { useTheme } from '@material-ui/styles'
import classnames from 'classnames'
import useStyles from './styles'

export function Dot({ size = 'small', color, style }) {
  var classes = useStyles()
  var theme = useTheme()

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotBase]: size === 'small',
        [classes.dotMedium]: size === 'medium',
        [classes.dotLarge]: size === 'large'
      })}
      style={{
        backgroundColor:
          (color && theme.palette.text[color] && theme.palette.text[color]) ||
          color,
        ...style
      }}
    />
  )
}
