export const USER_LOGIN = 'oauth'
export const REFRESH_TOKEN = 'oauth/refresh'

//Customers
export const CUSTUMERS_URL = 'customer'

//Users
export const USER_URL = 'user'
export const USER_CHANGE_PASSWORD = 'oauth/change-password'
export const USER_FORGOT_PASSWORD = 'oauth/forgot-password'

//OccupationArea
export const OCCUPATION_AREA = 'occupation-area'

//User Group
export const USER_GROUP = 'user-group'
export const USER_GROUP_SEARCH = 'user-group/search'

//CompetenceGrouper
export const COMPETENCE_GROUPER = 'competence-grouper'
export const COMPETENCE_GROUPER_SEARCH = 'competence-grouper/search'
export const COMPETENCE_GROUPER_FROM_USER = 'competence-grouper/from-user'

//Competence
export const COMPETENCE = 'competence'
export const COMPETENCE_SEARCH = 'competence/search'
export const COMPETENCE_SCORE = 'competence-score'
export const COMPETENCE_SCORE_SEARCH = 'competence-score/search'
export const COMPETENCE_LAST_SCORE = 'competence-score/last-score'

//Cycle Setting
export const CYCLE_SETTING = 'cycle-setting'
export const CYCLE_SETTING_INTERVAL_TYPE = 'cycle-setting/interval-type'
export const CYCLE_SETTING_REPEAT_ON_TYPE = 'cycle-setting/repeat-on-type'
export const CYCLE_SETTING_SEARCH = 'cycle-setting/search'

//Metrics
export const METRICS_USERS = 'metrics/users'
export const METRICS_USER_GROUPS = 'metrics/customers/user-groups'
export const METRICS_USER_GROUPS_BY_ID = 'metrics/user-groups'

export const METRICS_FAVORITE_GROUP = 'user-favorite'
export const METRICS_USER_SEARCH = 'user/search'
export const METRICS_CUSTOMERS_USERS = 'metrics/customers/users'

//File Upload
export const FILE_UPLOAD = 'file-upload'

//Notifications
export const NOTIFICATION_BADGE = 'badge'

//Development Plan
export const DEVELOPMENTPLAN = 'development-plan'
export const DEVELOPMENTPLAN_SEARCH = 'development-plan/search'

//One To One
export const ONETOONE = 'one-to-one'
export const ONETOONE_SEARCH = 'one-to-one/search'

//Celebrations
export const CELEBRATION = 'celebration'
export const CELEBRATION_SEARCH = 'celebration/search'
export const CELEBRATION_LIKE = 'celebration/like'
export const CELEBRATION_COMMENT = 'celebration/comment'

//Feedback
export const FEEDBACK = 'feedback'
export const FEEDBACK_SEARCH = 'feedback/search'
