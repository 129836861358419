import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Star as StarIcon,
  StarOutline as StarOutlineIcon
} from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { Tooltip } from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  postFavoriteGroup,
  deleteFavoriteGroup
} from 'src/services/hooks/metrics/useMetrics'

export function StarFavorite({
  checked,
  componentId,
  userId,
  favoriteId = null,
  className,
  type,
  onActionRemove,
  colorIcon
}) {
  const [isFavorite, setIsFavorite] = useState(checked)
  const [currentFavoriteId, setCurrentFavoriteId] = useState(favoriteId)
  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const typeFavorite = {
    user: 1,
    userGroup: 2
  }

  useEffect(() => {
    setIsFavorite(checked)
    setCurrentFavoriteId(favoriteId)
  }, [checked, favoriteId])

  async function onFavoriteItem() {
    try {
      onOpenLoading()
      const payload = {
        userId,
        componentId,
        componentType: typeFavorite[type],
        verticalIndex: 1,
        horizontalIndex: 1
      }

      if (!isFavorite) {
        const response = await postFavoriteGroup(payload)
        setCurrentFavoriteId(response?.id)
      } else {
        await deleteFavoriteGroup({
          id: currentFavoriteId
        })
        if (onActionRemove) {
          onActionRemove()
        }
      }
      setIsFavorite(!isFavorite)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <Tooltip title={isFavorite ? 'Remover da home' : 'Adicionar a home'}>
      <IconButton onClick={onFavoriteItem} className={className}>
        {isFavorite ? (
          <StarIcon htmlColor={colorIcon} />
        ) : (
          <StarOutlineIcon htmlColor={colorIcon} />
        )}
      </IconButton>
    </Tooltip>
  )
}

StarFavorite.propTypes = {
  checked: PropTypes.bool,
  componentId: PropTypes.string,
  userId: PropTypes.string,
  className: PropTypes.string,
  favoriteId: PropTypes.string,
  type: PropTypes.oneOf(['user', 'userGroup'])
}
