import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import 'moment/locale/pt-br'
import moment from 'moment'
import { Typography, IconButton } from '@material-ui/core'
import { Box, Divider } from '@mui/material'
import { BadgeTooltip, Tooltip } from 'src/components'
import { Edit as EditIcon } from '@material-ui/icons'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import makeStyles from './style'

export function CardImpressionsOneToOne({ fromUserId, toUserId, item }) {
  const theme = useTheme()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { onCallAlert } = useAlertContext()

  const [dataUserFrom, setDataUserFrom] = useState()
  const [dataUserTo, setDataUserTo] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getDataUser = useCallback(async (userFromId, userToId) => {
    try {
      setIsLoading(true)
      const { data: dataUserFrom } = await getUserById(userFromId)
      const { data: dataUserTo } = await getUserById(userToId)
      setDataUserFrom(dataUserFrom?.data)
      setDataUserTo(dataUserTo?.data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fromUserId && toUserId && getDataUser(fromUserId, toUserId)
  }, [getDataUser, fromUserId, toUserId])

  function handleViewOneToOne(id) {
    navigate(`/view/one-to-one/${id}`)
  }

  return (
    <Box className={classes.boxContainer}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2">{item?.name}</Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Typography className={classes.date}>
            {moment(item?.start).format('LL')}
          </Typography>
          <Tooltip placement="top" title="Editar 1:1">
            <IconButton
              className={classes.iconEditButton}
              onClick={() => handleViewOneToOne(item?.id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" py={2}>
        {!isLoading && dataUserFrom && (
          <Box display="flex" mr={1}>
            <BadgeTooltip
              placement="top"
              disableFocusListener
              disableTouchListener
              user={dataUserFrom}
            />
          </Box>
        )}
        <Typography variant="body">{item.description}</Typography>
      </Box>
      {item.annotation && (
        <>
          <Divider style={{ borderColor: theme.palette.primary.main }} />
          <Box display="flex" py={2}>
            {!isLoading && dataUserTo && (
              <Box display="flex" mr={1}>
                <BadgeTooltip
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  user={dataUserTo}
                />
              </Box>
            )}
            <Typography variant="body">{item.annotation}</Typography>
          </Box>
        </>
      )}
    </Box>
  )
}
