import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, BadgeTooltip } from 'src/components'
import { Box, Typography } from '@material-ui/core'
import makeStyles from '../style'

function modalAllComment({ isOpenModal, onCloseModal, data }) {
  const classes = makeStyles()

  return (
    <Modal
      isOpen={isOpenModal}
      onHandleClose={onCloseModal}
      title="Comentários"
    >
      <Box className={classes.sectionComment}>
        <Box>
          {data.map((data) => (
            <Box className={classes.boxContent} key={data.id}>
              <BadgeTooltip
                placement="top"
                disableFocusListener
                disableTouchListener
                user={data}
              />
              <Box className={classes.boxContentBadgeOne} />
              <Box className={classes.boxContentBadgeTwo} />
              <Box className={classes.boxContentText}>
                <Typography className={classes.name}>{data.name}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.modalButtons}>
        <Button label="Cancelar" variant="underline" onClick={onCloseModal} />
      </Box>
    </Modal>
  )
}
modalAllComment.propTypes = {
  isOpenModal: PropTypes.bool,
  onCloseModal: PropTypes.func
}

export default modalAllComment
