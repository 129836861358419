import { DEFAULT_MESSAGE_RESPONSE_ERROR } from './constants'

export function messageResponse(response) {
  const errorServer =
    response?.data?.errors === undefined || response?.status === 500

  if (errorServer) {
    return DEFAULT_MESSAGE_RESPONSE_ERROR
  }

  return response?.data?.errors[0]
}
