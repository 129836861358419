import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'
import useResponsive from 'src/hooks/useResponsive'
import { useLocation } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'
import { NAV } from 'src/config-global'
import { styled } from '@mui/material/styles'
import { useSettingsContext } from 'src/context/SettingsContext'
import { List, Typography } from '@material-ui/core'
import { Tooltip } from 'src/components'
import navConfig from 'src/layouts/Dashboard/Nav/config-navigation'
import { CalendarViewDayOutlined } from '@material-ui/icons'
import SidebarLink from 'src/components/Sidebar/components/SidebarLink/SidebarLink'
import makeStyles from './styles'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props) => (
  <MuiAccordion
    TransitionProps={{ unmountOnExit: true }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#000000',
  zIndex: '10',
  transition: '2s',
  animation: '2s',
  borderRadius: '0px 0px 10px 10px',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    height: '64px',
    padding: '0px',
    width: '100%',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      animation: '1s'
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(0)
    }
  })
)

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderRadius: '20px'
}))

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
}

export default function NavVertical({ openNav, onCloseNav }) {
  const { pathname } = useLocation()

  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const classes = makeStyles()

  const location = useLocation()

  const { user } = useAuth()
  const currentRole = user?.occupation

  const { sidebarOpen, onToggleSidebarOpen } = useSettingsContext()

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
  }

  const renderContent = (
    <Box
      sx={{
        height: '100vh',
        top: '0px',
        zIndex: '10',
        minWidth: '64px',
        width: '64px',
        backgroundColor: '#000000',
        overflow: 'hidden',
        transition: 'all 0.3s',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)'
      }}
      className={sidebarOpen ? classes.sideBar : ''}
    >
      <Accordion
        className={classes.boxList}
        expanded={true}
        onChange={() => onToggleSidebarOpen()}
      >
        <AccordionSummary
          className={classes.btnClose}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Box className={classes.boxClickAccordion}>
            <Box className={classes.iconWrapper}>
              {sidebarOpen ? (
                <Box />
              ) : (
                <Tooltip title="Menu" placement="right">
                  <Box className={classes.boxAccordionIcons}>
                    <CalendarViewDayOutlined
                      className={classes.accordionIcons}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
            <Typography className={classes.accordionIcons}>
              {sidebarOpen ? 'Minimizar menu' : ''}
            </Typography>
          </Box>
        </AccordionSummary>
        {sidebarOpen ? (
          <AccordionDetails>
            <List
              className={classes.sidebarList}
              classes={{ padding: classes.padding }}
            >
              {navConfig.map(({ icon, link, label, permissions }, idx) => (
                <Box key={idx}>
                  {permissions.includes(currentRole) && (
                    <SidebarLink
                      icon={icon}
                      label={label}
                      isSidebarOpened={sidebarOpen}
                      link={link}
                      toggleDrawer={toggleDrawer(true)}
                      location={location}
                    />
                  )}
                </Box>
              ))}
            </List>
          </AccordionDetails>
        ) : (
          <Box className={classes.sidebarListIcons}>
            {navConfig.map(({ icon, link, label, permissions }, idx) => (
              <Box key={idx}>
                {permissions.includes(currentRole) && (
                  <Tooltip title={label} placement="right">
                    <Box className={classes.texte}>
                      <SidebarLink
                        icon={icon}
                        label={false}
                        isSidebarOpened={sidebarOpen}
                        link={link}
                        toggleDrawer={toggleDrawer(false)}
                        location={location}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Accordion>
    </Box>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD }
      }}
    >
      {isDesktop ? (
        <>{renderContent}</>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
