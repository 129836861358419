import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxForm: {
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
    padding: '15px 0px 0px 0px'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: '15px'
  },
  BoxSwitch: {
    display: 'flex',
    flex: '1',
    alignItems: 'center'
  },
  wordCounter: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: "10px"
  }
}))
