import React, { useEffect, useState, useCallback } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useAuth from 'src/hooks/useAuth'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Typography, Box, Grid } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import { Autocomplete, Date, Input, Switch } from 'src/components/Fields'
import { newUserValidation } from 'src/utils/form/validations'
import { maskCPF, maskName } from 'src/utils/regex/masks'
import { enumAssignment, enumOcupation } from 'src/utils/constants'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Button, NewBoxWidget, HeaderBreadcrumbs, Helmet } from 'src/components'
import { getUserById, putUser } from 'src/services/hooks/users/useUsers'
import { useLoadingContext } from 'src/context/LoadingContext'
import { getOccupationArea } from 'src/services/hooks/occupationArea/useOccupationArea'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import makeStyles from './style'
import {
  PermContactCalendarOutlined as PermContactCalendarOutlinedIcon,
  VpnKeyOutlined as VpnKeyOutlinedIcon
} from '@material-ui/icons'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import ChangePassword from './Partials/ChangePassword'

function EditUser() {
  const navigate = useNavigate()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const [role] = useState([1, 2])

  const { id } = useParams()

  const { user } = useAuth()

  const currentCustomerId = user?.customerId

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { notifications, onCallAlert } = useAlertContext()

  const [data, setData] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [populateSelectOptions, setPopulateSelectOptions] = useState({
    occupationArea: []
  })

  async function onHandleSubmit(values) {
    try {
      const payload = {
        ...values,
        customerId: currentCustomerId
      }
      onOpenLoading()
      await putUser(payload)
      onCallAlert({
        type: 'success',
        message: notifications.users.success.editUser
      })
      navigate('/users')
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  const getDataSelectOptions = useCallback(async () => {
    try {
      const resultOccupations = await getOccupationArea()
      const occupations = resultOccupations.map((key) => ({
        value: key.id,
        title: key.name
      }))

      setPopulateSelectOptions({
        occupationArea: occupations
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getUserData = async (id) => {
    try {
      onOpenLoading()
      const data = await getUserById(id)
      setData(data?.data?.data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  useEffect(() => {
    getDataSelectOptions()
    if (id) {
      getUserData(id)
    }
  }, [])

  function alertDocument(value, error) {
    if(value.length === 14 && error) {
      onCallAlert({
        type: 'error',
        message: error
      })
    }
  }

  function alertBirthData(value, error) {
    if(moment(value).isValid() && error) {
      onCallAlert({
        type: 'error',
        message: error
      })
    }
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title={`Editar usuário ${data?.name}`} />
      <ChangePassword
        isOpenModal={isOpenModal}
        onCloseModal={() => setIsOpenModal(false)}
        email={data?.email}
      />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<PermContactCalendarOutlinedIcon />}
          links={[
            {
              name: 'Gestão de usuário',
              href: '/users'
            },
            { name: `${data?.name ?? ''}` }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <Form
          onSubmit={onHandleSubmit}
          initialValues={{
            id: id,
            name: data?.name,
            email: data?.email,
            occupation: data?.occupation,
            document: maskCPF(data?.document),
            birthData: data?.birthData,
            admissionDate: data?.admissionDate,
            occupationAreaId: data?.occupationAreaId,
            assignment: data?.assignment,
            isActive: data?.isActive
          }}
          validate={newUserValidation}
          render={({ handleSubmit, pristine, errors, values }) => (
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <NewBoxWidget
                title="Detalhes usuário"
                subtitle="Adicione ou edite dados do usuário:"
                options={
                  <>
                    <Typography
                      variant="inherit"
                      className={classes.textStatusUser}
                    >
                      Status do usuário:
                    </Typography>
                    <Switch name="isActive" />
                  </>
                }
                actions={
                  <div className={classes.actions}>
                    <Typography variant="inherit" className={classes.formInfo}>
                      *Campos obrigatórios
                    </Typography>
                    <div className={classes.formControlButton}>
                      <Button
                        label="Alterar senha"
                        icon={VpnKeyOutlinedIcon}
                        variant="dark"
                        type="button"
                        onClick={() => setIsOpenModal(true)}
                      />
                      <Button
                        onClick={handleSubmit}
                        label="Salvar"
                        disabled={pristine}
                        type="submit"
                      />
                    </div>
                  </div>
                }
                fullBody
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    item
                    style={{
                      alignItems: 'center',
                      direction: 'column'
                    }}
                    spacing={3}
                  >
                    <Grid item md={6} xs={12}>
                      <Input label="Nome*" name="name" parse={maskName} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Input label="E-mail*" name="email" />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Autocomplete
                        options={enumOcupation}
                        label="Vinculo de papel*"
                        name="occupation"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Input
                        label="CPF*"
                        name="document"
                        parse={maskCPF}
                        onBlur={() => alertDocument(values?.document ?? "", errors?.document)}
                      />
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Date
                        label="Data de nascimento*"
                        name="birthData"
                        disableFuture
                        onBlur={() => alertBirthData(values?.birthData ?? "", errors?.birthData)}
                      />
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Date
                        label="Data de admissão*"
                        name="admissionDate"
                        disableFuture
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={populateSelectOptions.occupationArea}
                        label="Área de atuação*"
                        name="occupationAreaId"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={enumAssignment}
                        label="Experiência*"
                        name="assignment"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                  </Grid>
                </form>
              </NewBoxWidget>
            </MuiPickersUtilsProvider>
          )}
        />
      </Box>
    </RoleBasedGuard>
  )
}

export default EditUser
