import React, { useState, useCallback } from 'react'
import { useTheme, styled } from '@material-ui/styles'
import Menu from '@mui/material/Menu'
import {
  IconButton,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  withStyles
} from '@material-ui/core'
import { Tooltip } from 'src/components'
import { FilterListOutlined as FilterListOutlinedIcon } from '@material-ui/icons'
import { FILTER_BUTTONS_LEADER, FILTER_BUTTONS_LED } from 'src/utils/constants'
import { enumFilterType } from 'src/@enum/impressions'
import useStyles from './styles'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 190,
    backgroundColor: '#F7F7FC',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
  }
}))

const StyledCheckBox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))((props) => <Checkbox color="default" {...props} />)

export function ImpressionsFilter({
  title,
  showFilterCategory = false,
  userRole,
  selectedFilterType,
  setSelectedFilterType,
  selectedFilterCategory,
  setSelectedFilterCategory,
  ...props
}) {
  const theme = useTheme()
  const classes = useStyles({ props })

  var [buttonRef, setButtonRef] = useState(null)
  var [isOpen, setOpen] = useState(false)

  const handleSelectedFilterType = useCallback(
    async (filter) => {
      setSelectedFilterType(filter)
    },
    [setSelectedFilterType]
  )

  const handleSelectedFilterCategory = (event, id) => {
    const selectedIndex = selectedFilterCategory.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(selectedFilterCategory, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(selectedFilterCategory.slice(1))
    } else if (selectedIndex === selectedFilterCategory.length - 1) {
      selected = selected.concat(selectedFilterCategory.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        selectedFilterCategory.slice(0, selectedIndex),
        selectedFilterCategory.slice(selectedIndex + 1)
      )
    }
    setSelectedFilterCategory(selected)
  }

  const handleReset = useCallback(async () => {
    setSelectedFilterType(1)
    setSelectedFilterCategory([])
  }, [setSelectedFilterCategory, setSelectedFilterType])

  return (
    <div>
      <Tooltip placement="bottom" title={title}>
        <IconButton
          color="primary"
          className={!isOpen ? classes.iconButton : classes.iconActiveButton}
          aria-owns="widget-menu"
          aria-haspopup="true"
          onClick={() => setOpen(true)}
          ref={setButtonRef}
        >
          <FilterListOutlinedIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="impressions-filter-menu"
        open={isOpen}
        anchorEl={buttonRef}
        onClose={() => setOpen(false)}
        disableAutoFocusItem
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography className={classes.title}>{title}</Typography>
          <Button className={classes.reset} onClick={() => handleReset()}>
            Resetar
          </Button>
        </Box>
        {showFilterCategory && (
          <Box mb={1}>
            <Box>
              <Typography className={classes.title}>Categoria</Typography>
            </Box>
            {(userRole === 3 ? FILTER_BUTTONS_LEADER : FILTER_BUTTONS_LED)?.map(
              (item) => (
                <FormControlLabel
                  key={item.value.toString()}
                  control={
                    <StyledCheckBox
                      primaryColor={theme.palette.primary.main}
                      checked={
                        selectedFilterCategory.indexOf(
                          item.value.toString()
                        ) !== -1
                      }
                      onChange={(event) =>
                        handleSelectedFilterCategory(
                          event,
                          item.value.toString()
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="inherit" className={classes.label}>
                      {item.label}
                    </Typography>
                  }
                />
              )
            )}
          </Box>
        )}
        <Box>
          <Typography className={classes.title}>Tipo</Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <StyledCheckBox
                primaryColor={theme.palette.primary.main}
                name="filterFromUser"
                inputProps={{
                  'aria-label': 'controlled'
                }}
                color="default"
                checked={selectedFilterType === enumFilterType.RECEBIDOS}
                onChange={() =>
                  handleSelectedFilterType(enumFilterType.RECEBIDOS)
                }
              />
            }
            label={
              <Typography variant="inherit" className={classes.label}>
                Recebidos
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <StyledCheckBox
                primaryColor={theme.palette.primary.main}
                name="filterToUser"
                inputProps={{
                  'aria-label': 'controlled'
                }}
                color="default"
                checked={selectedFilterType === enumFilterType.ENVIADOS}
                onChange={() =>
                  handleSelectedFilterType(enumFilterType.ENVIADOS)
                }
              />
            }
            label={
              <Typography variant="inherit" className={classes.label}>
                Enviados
              </Typography>
            }
          />
        </Box>
      </StyledMenu>
    </div>
  )
}
