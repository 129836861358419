import React, { Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'
import { enumCategoryNameColor } from 'src/@enum/metrics'
import useStyles from './style'

export function CompetenceGroupsScore({ category, data }) {
  const classes = useStyles()

  return (
    <>
      {data?.map(({ competenceGrouperName, average }, index) => (
        <Fragment key={index}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
            className={classes.content}
          >
            <Typography className={classes.title}>
              {competenceGrouperName}
            </Typography>
            <Box
              className={classes.value}
              style={{
                color: enumCategoryNameColor[category.toLocaleLowerCase()]
              }}
            >
              {average}
            </Box>
          </Box>
          <div className={classes.container}>
            <div
              style={{
                background: enumCategoryNameColor[category.toLocaleLowerCase()],
                width: `${average * 20}%`,
                height: '100%'
              }}
            />
          </div>
        </Fragment>
      ))}
    </>
  )
}

export function SimpleProgressBar({ data }) {
  return (
    <Box>
      {data?.map(({ category, competenceGroups }, index) => (
        <CompetenceGroupsScore
          category={category}
          data={competenceGroups}
          key={index}
        />
      ))}
    </Box>
  )
}
