import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton, Box, Typography } from '@material-ui/core'
import {
  Close as CloseIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@material-ui/icons'
import {
  NewBoxWidget,
  Tooltip,
  MultiProgressBar,
  BadgeTooltip,
  StarFavorite
} from 'src/components'
import makeStyles from './styles'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { getMetricsUserGroups } from 'src/services/hooks/metrics/useMetrics'

export function AvatarGroups({ data }) {
  const classes = makeStyles()

  return (
    <Box className={classes.boxAvatarGroups}>
      {data.map((data) => (
        <Fragment key={data?.id}>
          <Box className={classes.avatar}>
            <BadgeTooltip
              placement="top"
              disableFocusListener
              disableTouchListener
              user={data}
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

function Groups({ data, userId, classes }) {
  const theme = useTheme()

  return (
    <Box className={classes.userContainer}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.name}>{data?.name}</Typography>
        <Box className={classes.boxArrow}>
          <StarFavorite
            checked={data?.isFavorite}
            componentId={data?.id}
            userId={userId}
            className={classes.boxBtnOptions}
            type="userGroup"
            colorIcon={theme.palette.primary.main}
          />
          <Tooltip title="Ver grupo">
            <IconButton
              className={classes.boxBtnOptions}
              href={`/metrics/group/${data?.id}`}
            >
              <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box className={classes.containerColorMetrics}>
        {data?.averageMetricsByCategoryType && (
          <MultiProgressBar
            data={data?.averageMetricsByCategoryType}
            showLegend
          />
        )}
      </Box>
      <AvatarGroups data={data?.groupMembers} />
    </Box>
  )
}

function UserGroups({ customerId, userId, titleBox, onClose }) {
  const theme = useTheme()
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()

  const [data, setData] = useState([])

  const getListMetricsGroupUsers = useCallback(async () => {
    try {
      const data = await getMetricsUserGroups({})
      const groups = data.map(
        (item) =>
          item.groupMembers.find((member) => member.id === userId) && item
      )
      const groupsUser = groups.filter((item) => !!item)
      setData(groupsUser)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    }
  }, [onCallAlert, userId])

  useEffect(() => {
    getListMetricsGroupUsers()
  }, [getListMetricsGroupUsers])

  return (
    <NewBoxWidget
      title={titleBox}
      options={
        <Box className={classes.options}>
          <Tooltip placement="top" title="Fechar">
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      width={500}
      height="100%"
    >
      {data.length > 0 && (
        <Box>
          <Box mb={2} className={classes.containerHeader}>
            <Typography className={classes.titleLineChart}>
              Grupos e médias
            </Typography>
          </Box>
          <Box className={classes.listGroups}>
            {data?.map((user, index) => (
              <Groups
                key={index}
                data={user}
                userId={userId}
                classes={classes}
              />
            ))}
          </Box>
        </Box>
      )}
    </NewBoxWidget>
  )
}

export default UserGroups
