import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containertCloud: {
    width: '100%'
  },
  cloudTitle: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    color: '#000000',
    fontWeight: 400,
    marginBottom: '8px'
  },
  cloudWords: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px 10px'
  },
  wordItems: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: '10px',
    borderRadius: '100px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: '10px 20px',
    width: 'fit-content'
  },
  wordItemText: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  wordItemButton: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    border: 'none',
    fontWeight: 700,
    fontSize: '0.8rem',
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer'
  }
}))
