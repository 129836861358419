import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Box, Slider } from '@mui/material'
import { Typography } from '@material-ui/core'
import useStyles from './style'

function valuetext(value) {
  return { value }
}

export function SliderChip({
  competenceGrouper,
  data,
  onOpenExpectedEvolution,
  onChangeScore,
  changeLabels = false
}) {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <>
      {data?.map(({ competenceGrouper, scores }, idx) => (
        <>
          <Box key={competenceGrouper.id} mt={2}>
            <Typography className={classes.titleCompetence}>
              {competenceGrouper.name}
            </Typography>
            <Typography className={classes.descriptionCompetence}>
              {competenceGrouper.description}
            </Typography>
          </Box>
          <>
            {scores?.map((item, idx_scores) => (
              <Box key={item.competenceId} className={classes.content}>
                <Typography className={classes.title}>
                  {item.name} {onOpenExpectedEvolution}
                </Typography>
                <Typography className={classes.description}>
                  {item.description} {onOpenExpectedEvolution}
                </Typography>
                <Box className={classes.container}>
                  <Box
                    className={
                      changeLabels && classes.containerInformationFirst
                    }
                  >
                    <Typography className={classes.information}>
                      {changeLabels ? 'Não conhece' : 'Insatisfatório'}
                    </Typography>
                  </Box>
                  <Slider
                    aria-label="skills-scores"
                    defaultValue={item.competenceScore}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    onChange={(value) =>
                      onChangeScore(value.target.value, idx, idx_scores)
                    }
                    step={1}
                    marks
                    min={1}
                    max={5}
                    style={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={
                      changeLabels && classes.containerInformationSecond
                    }
                  >
                    <Typography className={classes.information}>
                      {changeLabels ? 'Domina e pode ensinar' : 'Excepcional'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        </>
      ))}
    </>
  )
}
