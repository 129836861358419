import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    padding: '10px 30px 30px 30px',
    marginRight: '10px',
    overflowX: 'hidden',
    height: '100%'
  },
  boxContent: {
    width: '100%',
    gap: '25px',
    height: 'calc(100vh - 165px)',
    display: 'flex',
    padding: '20px 10px 30px 10px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'start',
    marginRight: '10px',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'flex-start',
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    '&::-webkit-scrollbar': {
      height: '10px',
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },

  boxContentAll: {
    background: '#fff',
    boxShadow: '0px 0px 6px rgb(0 0 0 / 10%)',
    borderRadius: 10,
    padding: '10px 0px 10px 0px',
    width: 415,
    maxHeight: 'calc(100vh - 230px)',
    overflow: 'hidden'
  },
  userContainer: {
    background: '#F7F7FC',
    borderRadius: 10,
    padding: 10,
    margin: '0px 10px 0px 10px'
  },
  userContainerShadow: {
    background: '#F7F7FC',
    borderRadius: 10,
    padding: 10,
    margin: '0px 10px 0px 10px',
    zIndex: '3',
    position: 'relative',
    boxShadow: '-1px 5px 8px -7px #979797'
  },

  name: {
    fontSize: 14,
    fontWeight: 600,
    color: '#787878'
  },
  date: {
    fontSize: 14,
    color: '#787878'
  },
  displayContent: {
    display: 'flex',
    gap: 10,
    marginBottom: 10
  },
  boxContentBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 50,
    border: 'none',
    color: '#787878',
    cursor: 'pointer'
  },
  breadcrumb: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70
  },
  boxBtnNavegation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20
  },
  containerBtnOptions: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  containerBtn: {
    borderRadius: 50,
    background: '#fff',
    border: '1px solid #fff',
    height: 47,
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    boxShadow: 'inset 0px 2px 7px 0px #b3b3b3',
    marginRight: '-268px'
  },
  button: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderRadius: 50,
    gap: 20,
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#787878',
    background: 'transparent'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 5px',
    borderLeft: '1px solid #CFD2D3',
    borderRight: '1px solid #CFD2D3',
    width: 150,
    height: 30
  },
  filtersButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center'
  },
  buttonFilter: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    cursor: 'pointer',
    border: 'none',
    transition: 'all 0.3s'
  },
  buttonFilterSelected: {
    width: 22,
    height: 22,
    borderRadius: '100%',
    cursor: 'pointer',
    boxShadow: '0 0 0 2px #f3f5f6 inset',
    boxSizing: 'border-box',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    transition: 'all 0.3s'
  },
  boxArrow: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flex: 1,
    gap: 5
  },
  boxBtnOptions: {
    width: 37,
    height: 37
  },
  cardContainer: {
    padding: '0 20px',
    boxShadow: 'none',
    marginBottom: 20
  },
  containerTable: {
    overflow: 'auto',
    width: '100%',
    maxHeight: '100%',
    height: 'auto',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  containerScroll: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    overflowX: 'hidden',
    height: 'calc(100vh - 400px)'
  },
  boxDatasRadar: {
    marginBottom: 80
  },
  containerScrollGroups: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    overflowX: 'hidden',
    height: 'calc(100vh - 440px)'
  },
  boxAvatarGroups: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '5px 2px',
    marginTop: '10px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: 5
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  titleLineChart: {
    fontSize: '16px',
    fontWeight: '600',
    margin: '20px 0px 10px 0px',
    borderBottom: 'solid #CFD2D3 1px',
    paddingBottom: '10px'
  },
  iconActiveSearchButton: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#787878',
      color: '#FFFFFF'
    }
  },
  iconSearchButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: '#787878',
    '&:hover': {
      backgroundColor: '#787878',
      color: '#FFFFFF'
    }
  },
  notFoundMetricsIcon: {
    fontSize: 52,
    color: '#CFD2D3'
  },
  notFoundMetricsTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  notFoundMetricsDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    color: '#787878'
  },
  notFoundMetricsSimplifiedIcon: {
    fontSize: 22,
    color: '#CFD2D3',
    marginRight: 4
  },
  notFoundMetricsSimplifiedDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#787878'
  },
  containerColorMetrics: {
    marginTop: 15
  },
  buttonTabSelected: {
    background: theme.palette.primary.main,
    border: '2px solid ',
    borderColor: theme.palette.primary.main,
    color: '#fff !important',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)'
  }
}))
