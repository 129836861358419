import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconSearchButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  iconActiveSearchButton: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#787878',
      color: '#FFFFFF'
    }
  }
}))
