import React, { useState, useCallback, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useParams, useNavigate } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import { Box, Typography, IconButton } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import {
  Avatar,
  Button,
  Tooltip,
  Helmet,
  NewBoxWidget,
  HeaderBreadcrumbs,
  Dot,
  ProgressChips
} from 'src/components'
import { AssessmentOutlined as AssessmentOutlinedIcon } from '@material-ui/icons'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { postFeedback } from 'src/services/hooks/feedback/useFeedback'
import { postUserGroupsSearch } from 'src/services/hooks/userGroup/useUserGroup'
import { getImageNameColor } from 'src/utils'
import { enumAssignment } from 'src/@enum/user'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import clsx from 'clsx'
import BasicData from './Partials/BasicData'
import GroupersAndSkills from './Partials/GroupersAndSkills'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'

export function NewFeedback() {
  const theme = useTheme()
  const classes = makeStyles()

  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const { id: userIdParams } = useParams()
  const { user } = useAuth()
  const currentUserId = user?.id
  const currentCustomerId = user?.customerId

  const [isConfirm, setIsConfirm] = useState(false)

  const [groupersAndSkills, setGroupersAndSkills] = useState(true)
  const [basicData, setBasicData] = useState(false)

  const [payloadBasicData, setPayloadBasicData] = useState()
  const [payloadCompetencies, setPayloadCompetencies] = useState([])
  const [payloadCompetenciesId, setPayloadCompetenciesId] = useState([])

  const [dataUser, setDataUser] = useState()
  const [dataUserGroups, setDataUserGroups] = useState([])

  function onClose() {
    setBasicData(false)
    setGroupersAndSkills(false)
  }

  function onHandleBasicData() {
    setBasicData(!basicData)
  }
  function onHandleGroupersAndSkills() {
    setGroupersAndSkills(!groupersAndSkills)
  }

  const onHandleSubmit = async () => {
    try {
      onOpenLoading()
      const payload = {
        description: payloadBasicData?.description,
        competencies: payloadCompetenciesId,
        fromUserId: currentUserId,
        toUserId: userIdParams,
        customerId: currentCustomerId
      }
      await postFeedback(payload)
      onCallAlert({
        type: 'success',
        message: notifications.feedback.success.addNewFeedback
      })
      navigate(`/metrics/user/${userIdParams}`)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const getDataUser = useCallback(async (customerId, id) => {
    try {
      onOpenLoading()
      const { data } = await getUserById(id)
      setDataUser(data?.data)

      const payloadGetGroups = {
        customerId: customerId,
        isActive: true
      }
      const usersGroupsSearch = await postUserGroupsSearch(payloadGetGroups)
      const groups = usersGroupsSearch.map(
        (item) => item.members.find((member) => id === member) && item
      )
      const groupsUser = groups.filter((item) => !!item)
      const userGroups = groupsUser.map((item) => item.id)

      setDataUserGroups(userGroups)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    currentUserId && getDataUser(currentUserId, userIdParams)
  }, [currentUserId, getDataUser, userIdParams])

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  const disabled = payloadBasicData && payloadCompetenciesId.length

  return (
    <>
      <Helmet title="Novo Feedback" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            { name: dataUser?.name, href: `/metrics/user/${userIdParams}` },
            { name: 'Novo Feedback' }
          ]}
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          <NewBoxWidget
            title="Novo Feedback"
            options={
              <Box className={classes.options}>
                <Tooltip placement="top" title="Fechar">
                  <IconButton onClick={() => handleMetricsUser(dataUser.id)}>
                    <CloseIcon htmlColor={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            actions={
              isConfirm ? (
                <>
                  <Button
                    label="Editar"
                    variant="dark"
                    onClick={() => setIsConfirm(false)}
                  />
                  <Button
                    label="Confirmar"
                    disabled={!disabled}
                    onClick={onHandleSubmit}
                  />
                </>
              ) : (
                <Button
                  label="Enviar"
                  disabled={!disabled}
                  onClick={() => setIsConfirm(true)}
                />
              )
            }
            width={400}
            height="100%"
          >
            <Box className={classes.boxInforUser}>
              <Avatar
                src={dataUser?.imageProfile}
                aria-label={dataUser?.name}
                alt={dataUser?.name}
                color={
                  !dataUser?.imageProfile &&
                  getImageNameColor(dataUser?.name).color
                }
              >
                {getImageNameColor(dataUser?.name).name}
              </Avatar>
              <span>
                <strong>{dataUser?.name}</strong>
                <Typography className={classes.date}>
                  {dataUser?.occupationArea} -{' '}
                  {enumAssignment[dataUser?.assignment]}
                </Typography>
              </span>
            </Box>
            {isConfirm ? (
              <Box>
                <Box mb={4}>
                  <ProgressChips
                    customerId={currentCustomerId}
                    userRole={dataUser?.occupation}
                    dataCompetencies={payloadCompetenciesId}
                    dataUserGroups={dataUserGroups}
                  />
                </Box>
                <Box>
                  <TextField
                    label="Feedback *"
                    name="description"
                    variant="outlined"
                    color={theme.palette.primary.main}
                    value={payloadBasicData?.description}
                    multiline
                    enabled
                    style={{ width: '100%' }}
                  />
                </Box>
              </Box>
            ) : (
              <Box className={classes.optionsList}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Dot
                    color={
                      payloadCompetenciesId.length
                        ? theme.palette.primary.main
                        : '#E32929'
                    }
                    size="large"
                    style={{ marginRight: '-5px', zIndex: 1 }}
                  />
                  <button
                    className={clsx(
                      classes.option,
                      groupersAndSkills ? classes.optionSelected : ''
                    )}
                    onClick={() => {
                      onHandleGroupersAndSkills()
                      setBasicData(false)
                    }}
                  >
                    <p
                      className={clsx(
                        groupersAndSkills
                          ? classes.optionTextSelected
                          : classes.optionText
                      )}
                    >
                      Atribuir a competência
                    </p>
                    {groupersAndSkills ? (
                      <CloseIcon htmlColor="#FFFFFF" />
                    ) : (
                      <ArrowForwardIosIcon htmlColor="#787878" />
                    )}
                  </button>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Dot
                    color={
                      payloadBasicData ? theme.palette.primary.main : '#E32929'
                    }
                    size="large"
                    style={{ marginRight: '-5px', zIndex: 1 }}
                  />
                  <button
                    className={clsx(
                      classes.option,
                      basicData ? classes.optionSelected : ''
                    )}
                    onClick={() => {
                      onHandleBasicData()
                      setGroupersAndSkills(false)
                    }}
                  >
                    <p
                      className={
                        basicData
                          ? classes.optionTextSelected
                          : classes.optionText
                      }
                    >
                      Incluir descrição
                    </p>
                    {basicData ? (
                      <CloseIcon htmlColor="#FFFFFF" />
                    ) : (
                      <ArrowForwardIosIcon htmlColor="#787878" />
                    )}
                  </button>
                </Box>
              </Box>
            )}
          </NewBoxWidget>
          {groupersAndSkills && dataUser && (
            <GroupersAndSkills
              onClose={onClose}
              userId={userIdParams}
              userRole={dataUser?.occupation}
              payloadCompetencies={payloadCompetencies}
              setPayloadCompetencies={setPayloadCompetencies}
              payloadCompetenciesId={payloadCompetenciesId}
              setPayloadCompetenciesId={setPayloadCompetenciesId}
            />
          )}
          {basicData && (
            <BasicData
              onClose={onClose}
              payloadBasicData={payloadBasicData}
              setPayloadBasicData={setPayloadBasicData}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default NewFeedback
