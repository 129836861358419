export const enumCycleSettingIntervalType = {
  2: 'semana',
  3: 'mês',
  4: 'ano'
}

export const enumCycleSettingRepeatOnType = {
  1: 'Mesmo dia do mês',
  2: 'Mesmo dia da semana'
}
