import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useCallback } from 'react'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { currentPeriodYear } from 'src/utils/constants'

const initialState = {
  date: {
    start: currentPeriodYear?.firstDay,
    finish: currentPeriodYear?.lastDay
  },
  onChangeCurrentPeriod: () => {},
  onResetCurrentPeriod: () => {}
}

export const CurrentPeriodContext = createContext(initialState)

export const useCurrentPeriodContext = () => {
  const context = useContext(CurrentPeriodContext)

  if (!context)
    throw new Error(
      'useCurrentPeriodContext must be use inside CurrentPeriodProvider'
    )

  return context
}

CurrentPeriodProvider.propTypes = {
  children: PropTypes.node
}

export function CurrentPeriodProvider({ children }) {
  const [currentPeriod, setCurrentPeriod] = useLocalStorage(
    'currentPeriod',
    initialState?.date
  )

  const onChangeCurrentPeriod = useCallback(
    (value) => {
      setCurrentPeriod(value)
    },
    [setCurrentPeriod]
  )

  const onResetCurrentPeriod = useCallback(() => {
    setCurrentPeriod(initialState?.date)
  }, [setCurrentPeriod])

  const memoizedValue = useMemo(
    () => ({
      currentPeriod,
      onChangeCurrentPeriod,
      onResetCurrentPeriod
    }),
    [currentPeriod, onChangeCurrentPeriod, onResetCurrentPeriod]
  )

  return (
    <CurrentPeriodContext.Provider value={memoizedValue}>
      {children}
    </CurrentPeriodContext.Provider>
  )
}
