import React from 'react';
import PropTypes from 'prop-types'
import makeStyles from './style'
import {
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'

NotFound.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

export function NotFound({ title, subTitle}) {
  const classes = makeStyles()
  return (
    <Box className={classes.containerNotFound}>
      <AssessmentOutlinedIcon
        className={classes.notFoundIcon}
      />
      <Typography className={classes.notFoundTitle}>
        {title}
      </Typography>
      {
        !!subTitle && (
          <Typography className={classes.notFoundDescription}>
            {subTitle}
          </Typography>
        )
      }
    </Box>
  )
}
