import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  boxMetricsBarProgress: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& section': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 40
    },
    '& p': {
      margin: 0
    },
    '& span': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  numberAnd: {
    color: '#CFD2D3'
  },
  metricsBarProgress: {
    width: '100%',
    height: 4,
    backgroundColor: '#D8D8D8',
    display: 'flex',
    '& dot': {
      border: 'solid #fff 0.5px',
      height: 6,
      width: 6,
      marginTop: -1,
      borderRadius: 5
    }
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    marginRight: 2
  },
  value: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14
  },
  boxMetricsMedia: {
    backgroundColor: '#F7F7FC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    width: 56.38,
    height: 120,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 10,
    marginLeft: 15,
    marginTop: 13,
    '& strong': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 0
    },
    '& p': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 0,
      color: '#787878'
    }
  }
}))
