import { makeStyles } from '@material-ui/core/styles'

const useGlobalUseStyles = makeStyles((theme) => ({
  containerForm: {
    paddingLeft: '32px',
    paddingRight: '32px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  fieldsForm: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 25,
    rowGap: 25,
    marginTop: 20,
    flex: '1',
    alignContent: 'flex-start'
  },
  Container: {
    height: '100%',
    padding: '10px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  tooltipText: {
    margin: '5px 5px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '400'
  },
  modalButtonsPassword: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingTop: '20px',
    '& button': {
      marginLeft: '20px'
    }
  },

  section: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    padding: '10px'
  },
  breadcrumb: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '15px 30px'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 5
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formFull: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  contentHorizontal: {
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 165px)',
    gap: '20px',
    padding: '20px 10px 30px 10px',
    overflowX: 'scroll',
    marginRight: '10px',
    scrollBehavior: 'smooth',
    transition: 'all .3s',
    transitionDelay: '0.2s',
    overflowY: (props) => (props.selectedGroup ? 'auto' : 'hidden'),
    '&::-webkit-scrollbar': {
      height: 10,
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 10
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  notFoundIcon: {
    fontSize: 52,
    color: '#CFD2D3'
  },
  notFoundTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  notFoundDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    color: '#787878'
  }
}))

export default useGlobalUseStyles
