import { makeStyles, withStyles, Checkbox } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxList: {
    backgroundColor: '#FFFFFF',
    padding: '20px 0 0 0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    width: 'calc(100% - 20%)',
    marginLeft: '10%',
    height: 'calc(100vh - 200px)'
  },
  listNotificastionTitleTable: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px'
  },
  listNotificationOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '20px'
  },
  listNotificationButtonIcon: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none'
  },
  first: {
    width: '100px'
  },
  second: {
    width: 'calc(100% - 300px)'
  },
  third: {
    width: '200px'
  },
  listNotificationContainerTable: {
    borderTop: 'solid 1px ',
    borderColor: theme.palette.primary.main,
    borderBottom: 'solid 1px ',
    height: 'calc(100vh - 370px)'
  },
  listNotificationPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '4%',
    height: '40px'
  },
  BodyTable: {
    width: '100%',
    display: 'flex',
    listStyle: 'none',
    marginTop: '30px',
    alignItems: 'center'
  },
  icon: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

export const MuiCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))(Checkbox)
