import React, { useState } from 'react'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useTheme } from '@material-ui/styles'
import {
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon
} from '@mui/icons-material'
import {
  getMetricsCustomersUsers,
  getMetricsUserGroups
} from 'src/services/hooks/metrics/useMetrics'
import { Tooltip } from 'src/components'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from './style'
import 'rsuite/dist/rsuite.css'
import ptBR from 'rsuite/locales/pt_BR'
import { DateRangePicker, CustomProvider } from 'rsuite'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { IconButton, Typography, Box, Button } from '@material-ui/core'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { currentPeriodYear } from 'src/utils/constants'
import { getFormatDate } from 'src/utils/getFormatDate'

CalendarPeriodHome.propTypes = {
  onChangeDataUserMetrics: PropTypes.func,
  onChangeDataGroupUsersMetrics: PropTypes.func,
  shouldShowGroups: PropTypes.bool
}

export function CalendarPeriodHome({
  onChangeDataUserMetrics,
  onChangeDataGroupUsersMetrics,
  shouldShowGroups
}) {
  const theme = useTheme()

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { onCallAlert } = useAlertContext()
  const { currentPeriod, onChangeCurrentPeriod, onResetCurrentPeriod } =
    useCurrentPeriodContext()

  const currentPeriodStart = new Date(
    currentPeriod ? currentPeriod?.start : currentPeriodYear?.firstDay
  )
  const currentPeriodFinish = new Date(
    currentPeriod ? currentPeriod?.finish : currentPeriodYear?.lastDay
  )

  const [currentPeriodSelected, setCurrentPeriodSelected] = useState({
    formatedToShow: {
      start: getFormatDate(currentPeriodStart),
      finish: getFormatDate(currentPeriodFinish)
    },
    formatedToCalendar: [currentPeriodStart, currentPeriodFinish]
  })
  const [isOpen, setIsOpen] = useState(false)
  const classes = makeStyles({
    isOpen
  })
  const isOpenComponent = isOpen ? classes.calendarOpen : classes.calendarClose

  function onHandleCalendarOpen() {
    setIsOpen(!isOpen)
  }

  async function onSelectPeriod({ value, shouldHandlerCalendar }) {
    try {
      const startDate = new Date(value[0])
      const finishDate = new Date(value[1])
      const formatedDatePayload = {
        start: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        finish: `${finishDate.getFullYear()}-${
          finishDate.getMonth() + 1
        }-${finishDate.getDate()}`
      }
      setCurrentPeriodSelected({
        formatedToShow: {
          start: getFormatDate(startDate),
          finish: getFormatDate(finishDate)
        },
        formatedToCalendar: [value[0], value[1]]
      })
      onChangeCurrentPeriod({
        start: new Date(value[0]),
        finish: new Date(value[1])
      })
      if (!shouldHandlerCalendar) {
        onHandleCalendarOpen()
      }
      onOpenLoading()
      const resultUsers = await getMetricsCustomersUsers({
        period: formatedDatePayload
      })
      const getOnlyUsersFavorites =
        resultUsers.filter((element) => element?.isFavorite) ?? []
      onChangeDataUserMetrics(getOnlyUsersFavorites)

      if (shouldShowGroups) {
        const resultGroupUsers = await getMetricsUserGroups({
          period: formatedDatePayload
        })
        const getOnlyGroupUsersFavorites =
          resultGroupUsers.filter((element) => element?.isFavorite) ?? []
        onChangeDataGroupUsersMetrics(getOnlyGroupUsersFavorites)
      }
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  function onResetPeriod() {
    onResetCurrentPeriod()
    onSelectPeriod({
      value: [currentPeriodYear?.firstDay, currentPeriodYear?.lastDay],
      shouldHandlerCalendar: true
    })
  }

  function RenderCalendar() {
    return (
      <div className={clsx(classes.containerCalendar, isOpenComponent)}>
        <CustomProvider locale={ptBR}>
          <DateRangePicker
            className={classes.containerDateRangePicker}
            open={isOpen}
            showOneCalendar
            defaultOpen
            format="dd/MM/yyyy"
            value={currentPeriodSelected.formatedToCalendar}
            placeholder="Selecione o período"
            onOk={(e) => onSelectPeriod({ value: e })}
          />
        </CustomProvider>
      </div>
    )
  }

  return (
    <div className={classes.containerInput}>
      <div className={classes.auxBackground} onClick={onHandleCalendarOpen} />
      <Box display="flex" alignItems="center">
        <Tooltip
          title={`Exibindo período de ${currentPeriodSelected?.formatedToShow?.start} - ${currentPeriodSelected?.formatedToShow?.finish}`}
        >
          <Button
            // onClick={() => alert('adiconar a parda la')}
            onClick={onHandleCalendarOpen}
            className={classes.buttonPeriod}
          >
            <CalendarTodayIcon htmlColor={theme.palette.primary.main} />
            <Typography className={classes.currentPeriod}>
              {`${currentPeriodSelected?.formatedToShow?.start} - ${currentPeriodSelected?.formatedToShow?.finish}`}
            </Typography>
          </Button>
        </Tooltip>
        <Tooltip
          title={`Limpar período ${currentPeriodSelected?.formatedToShow?.start} - ${currentPeriodSelected?.formatedToShow?.finish}`}
        >
          <IconButton onClick={onResetPeriod} className={classes.iconButton}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <RenderCalendar />
    </div>
  )
}
