import React from 'react'
import { Box, Typography } from '@material-ui/core'
import classnames from 'classnames'
import useStyles from './styles'

export function NewBoxWidget({
  children,
  title,
  subtitle,
  header,
  headerIcon,
  noBodyPadding,
  options,
  tabOptions,
  actions,
  borderActions,
  width,
  height,
  fullBody,
  removeScroll,
  style,
  ...props
}) {
  const classes = useStyles({
    fullBody,
    width,
    height,
    borderActions,
    removeScroll
  })

  return (
    <Box className={classes.container} style={style} {...props}>
      {(title || header) && (
        <>
          <Box className={classes.header}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                {headerIcon && (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.headerIconSpacing}
                  >
                    {headerIcon}
                  </Box>
                )}
                {title && (
                  <Box className={classes.headerSpacing}>
                    <Typography className={classes.title} noWrap>
                      {title}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {subtitle}
                    </Typography>
                  </Box>
                )}
                {header}
              </Box>

              {options && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.options}
                >
                  {options}
                </Box>
              )}
            </Box>
          </Box>
          {tabOptions && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.tabOptions}
            >
              {tabOptions}
            </Box>
          )}
        </>
      )}
      <Box
        className={classnames(classes.content, {
          [classes.noPadding]: noBodyPadding
        })}
      >
        {children}
      </Box>
      {actions && (
        <Box className={classes.footer}>
          <Box className={classes.actions}>{actions}</Box>
        </Box>
      )}
    </Box>
  )
}
