import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px',
    padding: '13px',
    marginRight: '10px',
    height: 'fit-content',
  },
  boxDescription: {
    width: '100%',
    padding: '10px 5px 20px 5px',
    '& p': {
      color: '#000',
      margin: 0,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400'
    }
  },

  boxAvatar: {
    width: '100%',
    display: 'flex',
    gap: 15,
    marginBottom: '15px',
    '& span': {
      backgroundColor: '#F7F7FC',
      display: 'flex',
      padding: '8px 10px',
      alignItems: 'center',
      width: '100%',
      gap: 15,
      borderRadius: 100,
    }
  },
  toUsers: {
    color: '#787878',
    fontSize: 12,
    margin: 0
  },
  userScroll: {
    display: 'flex',
    gap: 15,
    overflow: 'auto',
    paddingBottom: 5,
    '&::-webkit-scrollbar': {
      height: '5px',
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  boxFooter: {
    width: 'calc(100% + 20px)',
    justifyContent: 'end',
    gap: 15,
    marginLeft: -10,
    marginBottom: -10,
    display: 'flex',
    padding: '8px 10px',
    alignItems: 'center',
    backgroundColor: '#F7F7FC',
    '& span': {
      width: '100%',
      gap: 15,
      borderRadius: 10,
      '& h1': {
        color: '#434343',
        fontSize: '14px',
        margin: 0,
        padding: 0,
        fontWeight: '500',
        lineHeight: '20px'
      },
      '& h2': {
        color: '#787878',
        fontSize: '14px',
        margin: 0,
        padding: 0,
        fontWeight: '400',
        lineHeight: '20px'
      }
    }
  }
}))
