import { Checkbox, makeStyles, Switch, withStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  pTitle: {
    display: 'flex',
    flex: '1',
    paddingTop: 5,
    paddingLeft: 15
  }
}))

export const MuiSwitch = withStyles((theme) => ({
  root: {
    '& .Mui-checked': {
      color: theme.palette.primary.main
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5
    }
  }
}))(Switch)

export const MuiCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))(Checkbox)
