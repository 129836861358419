import React, { useEffect, useState, useCallback } from 'react'
import { useTheme } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { NewBoxWidget, Tooltip, BoxHero } from 'src/components'
import { IconButton, Box, Typography } from '@material-ui/core'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  Close as CloseIcon,
  ZoomOutMap as ZoomOutMapIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { enumCategoryId } from 'src/@enum/metrics'
import CompetenceGroups from './CompetenceGroups'
import makeStyles from './styles'

function MeasurementDetails({ data, userRole, userId, onClose }) {
  const theme = useTheme()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { onCallAlert } = useAlertContext()

  const [dataCompetenceGroups, setDataCompetenceGroups] = useState([])

  const getCompetenceGroups = useCallback(async (dataCompetenceGroups) => {
    try {
      const response = dataCompetenceGroups
        ?.filter((item) => item[0].competenceGroups)
        ?.flat()

      const filterRoleCategory =
        (userRole === 3 && 'Conhecimento') || (userRole === 4 && 'Liderança')
      const dataGroupFilterRole = (response || []).filter(
        (item) => item.categoryName !== filterRoleCategory
      )

      setDataCompetenceGroups(dataGroupFilterRole)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    }
  }, [])

  useEffect(() => {
    getCompetenceGroups(data)
  }, [getCompetenceGroups, data])

  function handleMetricsFull(categoryId) {
    if (categoryId) {
      navigate(`/metrics/user/metrics-full/${userId}/${categoryId}`)
      return 0
    }
    navigate(`/metrics/user/metrics-full/${userId}`)
  }

  return (
    <NewBoxWidget
      title="Detalhes de métricas"
      options={
        <>
          <Tooltip placement="top" title="Ver completo">
            <IconButton
              className={classes.iconButton}
              onClick={() => handleMetricsFull()}
            >
              <ZoomOutMapIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Fechar">
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </>
      }
      width={400}
      height="100%"
    >
      {dataCompetenceGroups.length > 0 ? (
        <>
          {dataCompetenceGroups?.map(
            ({ competenceGroups, categoryName }, index) => (
              <BoxHero
                key={index}
                title={categoryName}
                mb={2}
                options={
                  <Tooltip placement="top" title={`Ver ${categoryName}`}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() =>
                        handleMetricsFull(enumCategoryId[categoryName])
                      }
                    >
                      <ZoomOutMapIcon htmlColor={theme.palette.primary.main} />
                    </IconButton>
                  </Tooltip>
                }
                removeDivider
              >
                <CompetenceGroups
                  type="userTechnicalMetrics"
                  data={competenceGroups}
                  categoryName={categoryName}
                />
              </BoxHero>
            )
          )}
        </>
      ) : (
        <Box
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="100%"
        >
          <Box>
            <AssessmentOutlinedIcon className={classes.notFoundMetricsIcon} />
            <Typography className={classes.notFoundMetricsTitle}>
              Nenhuma informação
            </Typography>
            <Typography className={classes.notFoundMetricsDescription}>
              Não há avaliações para este usuário.
            </Typography>
          </Box>
        </Box>
      )}
    </NewBoxWidget>
  )
}

export default MeasurementDetails
