export function validadePassword(value = '') {
  const checkExistAtLeastNumber = /[0-9]/
  const checkExistAtLeastUpperCase = /[A-Z]/
  const checkExistAtLeastLowerCase = /[a-z]/
  const checkExistEightCharacters = value.length >= 8
  return [
    {
      id: 0,
      title: 'Deve ter pelo menos um número.',
      status: checkExistAtLeastNumber.test(value)
    },
    {
      id: 1,
      title: 'Deve ter pelo menos uma letra maiúscula.',
      status: checkExistAtLeastUpperCase.test(value)
    },
    {
      id: 2,
      title: 'Deve ter pelo menos uma letra minúscula.',
      status: checkExistAtLeastLowerCase.test(value)
    },
    {
      id: 3,
      title: 'Deve ter pelo menos 8 caracteres.',
      status: checkExistEightCharacters
    }
  ]
}

export function yupValidadePassword(value = '') {
  const checkExistAtLeastNumber = /[0-9]/
  const checkExistAtLeastUpperCase = /[A-Z]/
  const checkExistAtLeastLowerCase = /[a-z]/
  const checkExistEightCharacters = value.length >= 8
  return (
    checkExistAtLeastNumber.test(value) &&
    checkExistAtLeastUpperCase.test(value) &&
    checkExistAtLeastLowerCase.test(value) &&
    checkExistEightCharacters
  )
}


export function validateMinAge(value = '') {
  const getToday = new Date();
  const getBirthDate = new Date(value);
  let currentAge = getToday.getFullYear() - getBirthDate.getFullYear();
  getBirthDate.setFullYear(getToday.getFullYear());
    if (getBirthDate > getToday) {
      currentAge = currentAge - 1;
    }
    return currentAge >= 16;
}


export function validateCPF(value = '') {
  const cpf = value.replace(/\D/g, "");

  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = soma % 11;
  let digito1 = resto < 2 ? 0 : 11 - resto;

  // Calcula o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = soma % 11;
  let digito2 = resto < 2 ? 0 : 11 - resto;

  // Verifica se os dígitos verificadores são válidos
  if (digito1 !== parseInt(cpf.charAt(9)) || digito2 !== parseInt(cpf.charAt(10))) {
    return false;
  }

  // CPF válido
  return true;
}
