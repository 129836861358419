import React from 'react'
import { useTheme } from '@material-ui/styles'
import {
  Tooltip,
  Avatar,
  MultiProgressBar,
  ProgressBar,
  StarFavorite,
  AccordionMeasurement,
  SimpleLineChart
} from 'src/components'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ChromeReaderModeOutlined as ChromeReaderModeOutlinedIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { getImageNameColor } from 'src/utils'
import { Typography, Box, IconButton } from '@material-ui/core'
import { enumAssignment } from 'src/@enum/user'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export function CardUser({
  classes,
  primaryColor,
  data,
  currentUserId,
  onActionRemove
}) {
  const theme = useTheme()

  const navigate = useNavigate()

  const [isExpandCard, setIsExpandCard] = useState(false)

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  function onHandleExpand() {
    setIsExpandCard(!isExpandCard)
  }

  return (
    <>
      <Box
        className={
          isExpandCard ? classes.userContainerShadow : classes.userContainer
        }
      >
        <Box className={classes.displayContent}>
          <Box className={classes.avatar}>
            <Avatar
              src={data.imageProfile}
              aria-label={data.name}
              alt={data.name}
              color={getImageNameColor(data.name).color}
            >
              {getImageNameColor(data.name).name}
            </Avatar>
          </Box>
          <Box>
            <Typography className={classes.name}>{data.name}</Typography>
            <Typography className={classes.date}>
              {data.occupationAreaName} - {enumAssignment[data.assignment]}
            </Typography>
          </Box>
          <Box className={classes.boxArrow}>
            <Tooltip
              title={
                isExpandCard
                  ? 'Visualizar simplificado'
                  : 'Visualizar com detalhes'
              }
            >
              <IconButton
                onClick={onHandleExpand}
                style={{
                  background: isExpandCard === true && primaryColor,
                  color: isExpandCard === true && '#fff'
                }}
                className={classes.boxContentBtn}
              >
                <ChromeReaderModeOutlinedIcon />
              </IconButton>
            </Tooltip>
            <StarFavorite
              onActionRemove={() =>
                onActionRemove({
                  type: 'user',
                  id: data?.id
                })
              }
              checked
              componentId={data?.id}
              userId={currentUserId}
              favoriteId={data?.favoriteId}
              className={classes.boxBtnOptions}
              type="user"
              colorIcon={theme.palette.primary.main}
            />
            <Tooltip title="Ver perfil">
              <IconButton
                className={classes.boxBtnOptions}
                onClick={() => handleMetricsUser(data.id)}
              >
                <ArrowForwardIosIcon style={{ color: primaryColor }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {data?.averageMetricsByCategoryType && (
          <MultiProgressBar
            data={data?.averageMetricsByCategoryType}
            showLegend
          />
        )}
        {!data?.overallMetric && (
          <Box>
            <Box display="flex" alignItems="center">
              <AssessmentOutlinedIcon
                className={classes.notFoundMetricsSimplifiedIcon}
              />
              <Typography
                className={classes.notFoundMetricsSimplifiedDescription}
              >
                Não há avaliações para este usuário.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {isExpandCard && (
        <Box className={classes.containerScroll}>
          {data?.overallMetric ? (
            <Box className={classes.containerTable}>
              <Box className={classes.cardContainer}>
                <p className={classes.titleLineChart}>Médias gerais</p>
                <SimpleLineChart data={data?.timeline} />
              </Box>
              <Box className={classes.cardContainer}>
                <ProgressBar
                  data={data?.averageMetricsByCategoryType}
                  overallMetric={data?.overallMetric}
                  overallMetricGap={data?.overallMetricGap}
                />
              </Box>
              <Box className={classes.cardContainer}>
                <Box mt={5}>
                  {data?.radarsCompetenceGrouper?.map(
                    ({ category, competenceGroups }, index) => (
                      <>
                        {competenceGroups && competenceGroups.length > 0 && (
                          <Box key={index} mb={2}>
                            <AccordionMeasurement
                              categoryName={category}
                              scoreAverage={competenceGroups}
                              type="user"
                            />
                          </Box>
                        )}
                      </>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              height="100%"
            >
              <Box>
                <AssessmentOutlinedIcon
                  className={classes.notFoundMetricsIcon}
                />
                <Typography className={classes.notFoundMetricsTitle}>
                  Nenhuma informação
                </Typography>
                <Typography className={classes.notFoundMetricsDescription}>
                  Não há avaliações para este usuário.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
