import { LoadingComponent } from 'src/components'
import React, { useState, useMemo, useContext } from 'react'

const LoadingStateContext = React.createContext()

export function LoadingProvider({ children }) {
  const [visible, setVisible] = useState(false)

  const dataLoading = useMemo(
    () => ({
      visible,
      onCloseLoading: () => setVisible(false),
      onOpenLoading: () => setVisible(true)
    }),
    [visible]
  )

  return (
    <LoadingStateContext.Provider value={dataLoading}>
      <LoadingComponent />
      {children}
    </LoadingStateContext.Provider>
  )
}

export function useLoadingContext() {
  const context = useContext(LoadingStateContext)
  return context
}
