import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    background: '#F7F7FC'
  },
  options: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 15
  },
  accordion: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    backgroundColor: '#F7F7FC',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#000000',
    padding: 10
  },
  summary: {
    background: '#F7F7FC',
    borderRadius: 20
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#000000',
    textTransform: 'capitalize'
  },
  accordionSelected: {
    background: '#FFFFFF',
    boxShadow: 'none',
    '&:first-child': {
      boxShadow: 'none'
    },
    '&:last-child': {
      boxShadow: 'none'
    },
    '&:before': {
      boxShadow: 'none',
      display: 'none'
    }
  },
  summarySelected: {
    background: '#FFFFFF',
    border: 'none',
    height: '50px !important',
    borderBottom: '1px solid #CFD2D3',
    minHeight: '0px !important'
  },
  titleSelected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#434343',
    marginLeft: '-18px',
    textTransform: 'capitalize'
  },
  boxRadar: {
    height: '200px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  iconActiveButton: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF'
  }
}))
