import React, { useState, useCallback, useEffect } from 'react'
import { useTheme, styled } from '@material-ui/styles'
import Menu from '@mui/material/Menu'
import {
  Grid,
  IconButton,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  withStyles
} from '@material-ui/core'
import { MultilineChart as MultilineChartIcon } from '@material-ui/icons'
import { Tooltip } from 'src/components'
import useStyles from './styles'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 190,
    backgroundColor: '#F7F7FC',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
  }
}))

const StyledCheckBox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))((props) => <Checkbox color="default" {...props} />)

export function ChartFilterEvolution({
  items,
  category,
  fullData,
  labels,
  onChangeFilter,
  setLabelsRadar,
  ...props
}) {
  const theme = useTheme()
  const classes = useStyles({ props })

  const [initialItems, setInitialItems] = useState(items)

  const [reset, setReset] = useState(true)

  var [buttonRef, setButtonRef] = useState(null)
  var [isOpen, setOpen] = useState(false)

  const [data, setData] = useState()

  async function handleChecked(e, value) {
    const index = items.findIndex(function (item, i) {
      return item.competenceGrouperName === e.target.value
    })

    let editArray = [...items]

    editArray[index] = {
      checked: e.target.checked,
      competenceGrouperName: e.target.value,
      average: value
    }

    await onChangeFilter(editArray)

    if (e.target.checked) {
      await setLabelsRadar([e.target.value, ...labels])
    } else {
      await setLabelsRadar(labels.filter((item) => item !== e.target.value))
    }
  }

  const handleReset = () => {
    getList(initialItems)
    setLabelsRadar([])
    setReset(false)

    setTimeout(() => {
      setReset(true)
    }, 1)
  }

  const getList = useCallback((objects) => {
    const result = Object.entries(objects).map((currentData) => {
      const [competenceGrouperName, average] = currentData
      return {
        checked: average?.checked,
        label: average?.competenceGrouperName,
        value: average?.average
      }
    })
    setData(result)
  }, [])

  useEffect(() => {
    getList(items)
  }, [getList, items])

  return (
    <div>
      <Tooltip placement="top" title="Evolução">
        <IconButton
          color="primary"
          className={!isOpen ? classes.iconButton : classes.iconActiveButton}
          aria-owns="widget-menu"
          aria-haspopup="true"
          onClick={() => setOpen(true)}
          ref={setButtonRef}
        >
          <MultilineChartIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="chart-filter-evolution-menu"
        open={isOpen}
        anchorEl={buttonRef}
        onClose={() => setOpen(false)}
        disableAutoFocusItem
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.title}>Evolução</Typography>
          {/* <Button className={classes.reset} onClick={() => handleReset()}>
            Resetar
          </Button> */}
        </Box>
        {reset && (
          <Grid container direction={'column'}>
            {data?.map((item) => (
              <FormControlLabel
                key={item.key}
                control={
                  <StyledCheckBox
                    primaryColor={theme.palette.primary.main}
                    defaultChecked={item.checked}
                    onChange={(e) => handleChecked(e, item.value)}
                    value={item.label}
                  />
                }
                label={item.label}
                className={classes.label}
              />
            ))}
          </Grid>
        )}
      </StyledMenu>
    </div>
  )
}
