import React, { useEffect, useState, useCallback } from 'react'
import { useTheme } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { NewBoxWidget, Tooltip, BoxHero } from 'src/components'
import { IconButton, Box, Typography } from '@material-ui/core'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  Close as CloseIcon,
  ZoomOutMap as ZoomOutMapIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { enumCategoryId } from 'src/@enum/metrics'
import CompetenceGroups from './CompetenceGroups'
import makeStyles from './styles'

function MeasurementDetails({ data, users, groupId, onClose }) {
  const theme = useTheme()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { onCallAlert } = useAlertContext()

  const [dataCompetenceGroups, setDataCompetenceGroups] = useState([])

  const getCompetenceGroups = useCallback(async () => {
    try {
      const competenceGroups = data?.filter((item) => item[0].competenceGroups)
      setDataCompetenceGroups(competenceGroups)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    }
  }, [onCallAlert, data])

  useEffect(() => {
    getCompetenceGroups()
  }, [data])

  function handleMetricsFull(categoryId) {
    if (categoryId) {
      navigate(`/metrics/group/metrics-full/${groupId}/${categoryId}`)
      return 0
    }
    navigate(`/metrics/group/metrics-full/${groupId}`)
  }

  return (
    <NewBoxWidget
      title="Métricas técnicas"
      options={
        <Box className={classes.options}>
          <Tooltip placement="top" title="Ver completo">
            <IconButton
              className={classes.iconButton}
              onClick={() => handleMetricsFull()}
            >
              <ZoomOutMapIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Fechar">
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      width={400}
    >
      {dataCompetenceGroups ? (
        <>
          {dataCompetenceGroups?.map((item) =>
            item?.map(({ competenceGroups, categoryName }, index) => (
              <>
                <BoxHero
                  key={index}
                  title={categoryName}
                  mb={2}
                  options={
                    <Tooltip placement="top" title={`Ver ${categoryName}`}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          handleMetricsFull(enumCategoryId[categoryName])
                        }
                      >
                        <ZoomOutMapIcon
                          htmlColor={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                  removeDivider
                >
                  <CompetenceGroups
                    users={users}
                    categoryName={categoryName}
                    data={competenceGroups}
                  />
                </BoxHero>
              </>
            ))
          )}
        </>
      ) : (
        <Box
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="100%"
        >
          <Box>
            <AssessmentOutlinedIcon className={classes.notFoundMetricsIcon} />
            <Typography className={classes.notFoundMetricsTitle}>
              Nenhuma informação
            </Typography>
            <Typography className={classes.notFoundMetricsDescription}>
              Não há avaliações para este grupo.
            </Typography>
          </Box>
        </Box>
      )}
    </NewBoxWidget>
  )
}

export default MeasurementDetails
