export default function localStorageAvailable() {
  try {
    const key =
      'lHDTYW7hdI4yZ5ew18JH4JW9jbhUFrviQzM7xlELEVf4h9lFX5QVkbPppSwg0cda3'
    window.localStorage.setItem(key, key)

    window.localStorage.removeItem(key)

    return true
  } catch (err) {
    return false
  }
}
