import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from './style'
import { Box, Typography } from '@material-ui/core'

export function InfoBox({ title, subTitle }) {
  const classes = makeStyles()
  return (
    <Box className={classes.infoBox}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      {subTitle ? (
        <Typography variant="h3" className={classes.subTitle}>
          {subTitle}
        </Typography>
      ) : null}
    </Box>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}
