import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '416px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginLeft: '20px'
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
    marginBottom: 20
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  btnClose: {
    marginRight: -10,
    marginTop: -32
  },
  formControlButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    height: 60
  },
  boxFormControlButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    alignItems: 'end'
  }
}))
