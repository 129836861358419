import React, { useState, useEffect, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import { alpha } from '@mui/material/styles'
import { Stack, MenuItem } from '@mui/material'
import { IconButton } from '@material-ui/core'
import { RoomPreferences as RoomPreferencesIcon } from '@mui/icons-material'
import { getCustumers } from 'src/services/hooks/customers/useCustumers'
import { getUserById } from 'src/services/hooks/users/useUsers'
import MenuPopover from 'src/components/MenuPopover'
import ModalPassword from './Partials/ModalPassword'

export default function RootPopover() {
  const { user } = useAuth()
  const currentUser = user?.id
  const currentCustomerId = user?.customerId

  const [openPopover, setOpenPopover] = useState(null)
  const [openModalPassword, setOpenModalPassword] = useState(false)
  const [dataCustomers, setDataCustomers] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getListCustomers = useCallback(async (customerId) => {
    try {
      setIsLoading(true)
      const response = await getCustumers()
      setDataCustomers(response.filter((item) => item.id !== customerId))
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    currentCustomerId && getListCustomers(currentCustomerId)
  }, [getListCustomers, currentCustomerId])

  const getUser = async (customerId) => {
    try {
      const response = await getUserById(currentUser)
      const { data } = response?.data
      const payload = {
        ...data,
        customerId: customerId
      }
      setDataUser(payload)
      handleClosePopover()
      setOpenModalPassword(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleClickItem = async (customerId) => {
    await getUser(customerId)
  }

  return (
    <>
      {dataUser && (
        <ModalPassword
          openModal={openModalPassword}
          onCloseModal={() => setOpenModalPassword(false)}
          payloadUser={dataUser}
        />
      )}
      <IconButton
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <RoomPreferencesIcon sx={{ width: 34, height: 34, color: '#FFFFFF' }} />
      </IconButton>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Stack sx={{ p: 1 }}>
          {!isLoading &&
            dataCustomers &&
            dataCustomers?.map((item, idx) => (
              <MenuItem key={idx} onClick={() => handleClickItem(item.id)}>
                {item.name}
              </MenuItem>
            ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
