import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% + 30px)',
    justifyContent: 'flex-end',
    marginLeft: '-15px',
    marginRight: '-15px',
    columnGap: 20,
    marginBottom: '-20px'
  },
  competenceOptions: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    width: 'fit-content'
    // width: '400px',
  },
  boxOptionsEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    marginLeft: '-10px'
  },
  competenceItem: {
    '&:hover': {
      '& $competenceButton': {
        padding: '7px 9px !important',
        height: 'auto',
        backgroundColor: '#434343'
      },
      '& $editButton': {
        maxHeight: 35,
        height: 35,
        width: 35,
        maxWidth: 35,

        borderRadius: 35,
        padding: 0,
        backgroundColor: '#959595',

        transition: 'all .2s'
      },
      '& $editBadge': {
        display: 'none'
      }
    }
  },
  competenceButton: {
    color: '#fff',
    borderRadius: 8,
    cursor: 'pointer',
    fontWeight: '400',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    marginRight: '10px',
    height: 0,
    overflow: 'hidden',
    transition: 'all .2s'
  },
  editBadge: {
    height: 10,
    width: 10,
    backgroundColor: '#959595',
    borderRadius: 10
  },
  editButton: {
    color: '#fff',
    cursor: 'pointer',
    padding: 0,
    fontWeight: '400',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    maxHeight: 0,
    maxWidth: 0,
    border: 'none',
    backgroundColor: '#959595',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  columnActionShelve: {
    width: 'fit-content'
  },
  boxEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-15px',
    '& form': {
      flex: 1
    }
  },
  boxForm: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    padding: '15px 0px 0px 0px'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '45px',
    columnGap: '15px'
  },
  textTitleFormEdit: {
    textAlign: 'start',
    display: 'flex',
    color: '#14142B',
    fontWeight: '700',
    fontSize: '14px'
  },
  containerTab: {
    '& td': {
      '& .MuiBox-root': {
        marginTop: '0px !important',
        paddingTop: '20px',
        paddingBottom: '20px'
      }
    }
  },
  wordCounter: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: "10px",
    textAlign: "left",
  }
}))
