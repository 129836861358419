import React, { useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'
import {
  CalendarPeriod,
  CardGroupNavigation,
  HeaderBreadcrumbs,
  Helmet
} from 'src/components'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import {
  Close as CloseIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './styles'
import { useCallback, useEffect, useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import GeneralData from '../Partials/GeneralData'
import GroupMembers from '../Partials/GroupMembers'
import MeasurementDetails from '../Partials/MeasurementDetails'
import { getMetricsUserGroupsById } from 'src/services/hooks/metrics/useMetrics'
import { messageResponse } from 'src/utils/messageResponse'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'

function GroupNavigation() {
  const initialControlTabs = {
    isGeneralData: true,
    isGroupMembers: false,
    isActionPlans: false,
    isTechnicalMetrics: false
  }
  const { id: groupId } = useParams()

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { currentPeriod } = useCurrentPeriodContext()

  const [dataMetricsGroupUsers, setDataMetricsGroupUsers] = useState([])
  const [controlTabs, setControlTabs] = useState(initialControlTabs)

  const { user } = useAuth()
  const userId = user?.id
  const customerId = user?.customerId

  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  function onChangeTab(tab) {
    const isTabSelected = controlTabs[tab]
    setControlTabs({
      ...controlTabs,
      [tab]: !isTabSelected
    })
  }

  const tabOptions = [
    {
      id: 'isGeneralData',
      isActive: controlTabs?.isGeneralData,
      label: 'Dados gerais'
    },
    {
      id: 'isGroupMembers',
      isActive: controlTabs?.isGroupMembers,
      label: 'Membros do grupo'
    },
    {
      id: 'isTechnicalMetrics',
      isActive: controlTabs?.isTechnicalMetrics,
      label: 'Métricas técnicas'
    },
    {
      id: 'isActionPlans',
      isActive: controlTabs?.isActionPlans,
      label: 'Planos de ação'
    }
  ]

  const breadcrumbsLast = tabOptions.find((filter) => filter?.isActive)?.label

  const getDataMetricsGroupUsers = useCallback(async () => {
    try {
      onOpenLoading()
      const formatedDatePayload = getPayloadPeriod(currentPeriod)
      const groupUsers = await getMetricsUserGroupsById({
        groupId,
        period: formatedDatePayload
      })
      setDataMetricsGroupUsers(groupUsers)
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    groupId && getDataMetricsGroupUsers()
  }, [getDataMetricsGroupUsers, groupId])

  return (
    <>
      <Helmet title="Métricas do grupo" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            { name: `${dataMetricsGroupUsers?.name || ''}` },
            { name: breadcrumbsLast }
          ].slice(0, breadcrumbsLast ? 3 : 2)}
        />
        <CalendarPeriod
          onChangeDataMetrics={setDataMetricsGroupUsers}
          type="group"
          identification={groupId}
          isSearchById
          isShowIcon
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.contentHorizontalLeft}>
          <Box className={classes.boxContentNavigation}>
            <Box className={classes.contentNavigation}>
              <CardGroupNavigation
                data={dataMetricsGroupUsers}
                userId={userId}
              />
              <div className={classes.optionsList}>
                {tabOptions.slice(0, 3).map(({ id, isActive, label }) => (
                  <button
                    key={id}
                    className={
                      isActive ? classes.optionSelected : classes.option
                    }
                    onClick={() => {
                      onChangeTab(id)
                    }}
                  >
                    <p
                      className={
                        isActive
                          ? classes.optionTextSelected
                          : classes.optionText
                      }
                    >
                      {label}
                    </p>
                    {isActive ? (
                      <CloseIcon style={{ color: '#fff' }} />
                    ) : (
                      <ArrowForwardIosIcon style={{ color: '#787878' }} />
                    )}
                  </button>
                ))}
              </div>
            </Box>
          </Box>
          {controlTabs?.isGeneralData && (
            <GeneralData
              data={dataMetricsGroupUsers}
              onClose={() => onChangeTab('isGeneralData')}
            />
          )}
          {controlTabs?.isGroupMembers && (
            <GroupMembers
              data={dataMetricsGroupUsers?.groupMembers}
              customerId={customerId}
              userId={userId}
              onClose={() => onChangeTab('isGroupMembers')}
            />
          )}
          {controlTabs?.isTechnicalMetrics && (
            <MeasurementDetails
              data={dataMetricsGroupUsers?.radarsCompetence}
              groupId={groupId}
              users={dataMetricsGroupUsers?.groupMembers}
              onClose={() => onChangeTab('isTechnicalMetrics')}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default GroupNavigation
