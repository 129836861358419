import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxCalendar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: -20,
    marginBottom: 30
  },
  calendar: {
    background: '#fff',
    width: 340,
    '& .react-calendar__navigation__arrow': {
      width: 27,
      height: 27,
      borderRadius: 27
    },
    '& .react-calendar__navigation__arrow:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff !important'
    },
    '& .react-calendar__navigation': {
      background: '#F7F7FC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      height: 35,
      padding: '0px 5px',
      borderRadius: 40,
      columnGap: 3
    },
    '& .react-calendar__month-view__days': {
      display: 'flex',
      flexWrap: 'wrap'
    },
    '& button': {
      margin: 0,
      border: 0,
      outline: 'none',
      font: 'inherit',
      cursor: 'pointer',
      fontSize: 14,
      backgroundColor: 'transparent'
    },
    '& .react-calendar__tile': {
      maxWidth: '100%',
      padding: '14px 0px',
      background: 'none',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '30px'
    },
    '& .react-calendar__tile:hover': {
      backgroundColor: theme.palette.primary.main,
      border: 'solid 5px #fff',
      boxSizing: 'border-box',
      color: '#fff !important',
      padding: '8.5px 0px 0px 0px'
    },
    '& .react-calendar__tile--active': {
      color: '#fff !important',
      border: 'solid 5px #fff',
      boxSizing: 'border-box',
      padding: '8.5px 0px 0px 0px',
      backgroundColor: theme.palette.primary.main
    },
    '& .react-calendar__month-view__weekdays': {
      textAlign: 'center',
      padding: '10px 0px',
      color: theme.palette.primary.main,
      fontSize: 14,
      '& abbr': {
        textDecoration: 'none !important',
        textTransform: 'uppercase'
      }
    },
    '& .react-calendar__month-view__days__day--weekend ': { color: '#CFD2D3' },
    '& .react-calendar__month-view__days__day--neighboringMonth': {
      color: '#CFD2D3'
    }
  }
}))
