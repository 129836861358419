import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '0 30px 30px 30px'
  },
  contentHorizontalLeft: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    padding: '0px 0px 20px 0px',
    width: '100%',
    height: 'calc(100vh - 165px)',
    columnGap: 25,
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    overflowX: 'scroll',
    overflowY: 'hidden',
    justifyContent: 'stretch',
    '&::-webkit-scrollbar': {
      height: '10px',
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  boxContentNavigation: {
    marginLeft: 10
  },
  breadcrumb: {
    padding: '10px 20px 10px 30px'
  },
  contentNavigation: {
    width: '456px',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content'
  },
  head: {
    display: 'flex',
    paddingTop: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },

  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boxStarTooltip: {
    height: 40,
    width: 40,
    marginTop: -8
  },
  headerInformations: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& strong': {
      lineHeight: '30px',
      margin: 0,
      fontSize: 18
    },
    '& p': {
      lineHeight: '28px',
      margin: 0,
      fontSize: 16
    }
  },
  leadersList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '10px',
    paddingBottom: '20px'
  },
  leadersListAvatars: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: 15,
    gap: 9.43
  },
  leadersMultiProgressBar: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
    display: 'flex'
  },
  optionsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15
  },
  option: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: '#F7F7FC',
    padding: '14px 10px 14px 14px',
    animation: '0.3s',
    transition: '0.3s'
  },
  optionSelected: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    padding: '14px 10px 14px 14px',
    animation: '0.3s',
    transition: '0.3s'
  },
  optionText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#000000'
  },
  optionTextSelected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#F7F7FC'
  },
  Container: {
    height: '100%',
    padding: '10px 30px 30px 30px',
    overflow: 'hidden'
  }
}))
