import React, { useEffect, useState } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Typography, IconButton, Box, Grid } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  FeedbackOutlined as FeedbackOutlinedIcon,
  AssistantOutlined as AssistantOutlinedIcon
} from '@material-ui/icons'
import {
  Button,
  NewBoxWidget,
  Tooltip,
  HeaderBreadcrumbs,
  Helmet
} from 'src/components'
import { Input, Switch } from 'src/components/Fields'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getCustumerById,
  putCustomers
} from 'src/services/hooks/customers/useCustumers'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { editClientValidation } from 'src/utils/form/validations'
import { maskCNPJ } from 'src/utils/regex/masks'
import makeStyles from './style'

function EditClient() {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [role] = useState([1])
  const { id } = useParams()

  const [data, setData] = useState([])
  const [maskedCNPJ, setMaskedCNPJ] = useState('')

  const getCustumerInfo = async (id) => {
    try {
      onOpenLoading()
      const data = await getCustumerById(id)
      setData(data)
      const maskedCNPJ = maskCNPJ(data?.document)
      setMaskedCNPJ(maskedCNPJ)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const onSubmit = async (values) => {
    try {
      const payload = {
        id: data?.id,
        name: values?.name,
        email: values?.email.toLowerCase(),
        document: values?.document,
        primaryColor: data?.primaryColor,
        isActive: values?.isActive
      }
      onOpenLoading()
      await putCustomers(id, payload)
      onCallAlert({
        type: 'success',
        message: notifications.customers.success.editCustomer
      })
      getCustumerInfo(id)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  useEffect(() => {
    getCustumerInfo(id)
  }, [id])

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title={`Editar cliente ${data?.name}`} />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssistantOutlinedIcon />}
          links={[
            { name: 'Clientes', href: '/customers' },
            { name: 'Editar cliente', href: `/customers/edit/${id}` },
            { name: `${data?.name ?? ''}` }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <Form
          onSubmit={(values) => onSubmit(values)}
          validate={editClientValidation}
          initialValues={{
            name: data?.name,
            email: data?.email,
            document: maskedCNPJ,
            isActive: data?.isActive
          }}
          render={({ handleSubmit, valid, pristine }) => (
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <NewBoxWidget
                title={'Cliente ' + data?.name}
                subtitle="Veja e edite dados do cliente:"
                options={
                  <>
                    <Tooltip
                      placement="top"
                      title="Ao alterar o campo e-mail, uma validação será enviada para o novo destinatário."
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-owns="feedback-form"
                      >
                        <FeedbackOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                actions={
                  <>
                    <Button
                      variant="underline"
                      label="Cancelar"
                      onClick={() => navigate('/customers')}
                    />
                    <Button
                      label="Salvar"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!valid || pristine}
                    />
                  </>
                }
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    item
                    style={{
                      alignItems: 'center',
                      direction: 'column'
                    }}
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Input label="Nome do cliente*" name="name" />
                    </Grid>
                    <Grid item xs={12}>
                      <Input label="E-mail*" name="email" />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="CNPJ*"
                        name="document"
                        parse={maskCNPJ}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Switch name="isActive" />
                      <Typography variant="inherit">
                        Status do cliente
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </NewBoxWidget>
            </MuiPickersUtilsProvider>
          )}
        />
      </Box>
    </RoleBasedGuard>
  )
}

export default EditClient
