import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Radar } from 'react-chartjs-2'
import { getHexToRGBA } from 'src/utils'
import { enumCategoryNameColor } from 'src/@enum/metrics'
import { Chip, BadgeTooltip } from 'src/components'
import { Grid, Box } from '@material-ui/core'
import useStyles from './styles'

export function RadarChartGroup({
  title = false,
  legend = false,
  aspectRatio = 2,
  data,
  handleRemove,
  categoryName,
  users,
  usersSelected,
  labels,
  page
}) {
  const theme = useTheme()
  const classes = useStyles()

  const [dataUsers, setDataUsers] = useState([])
  const [dataState, setDataState] = useState([])

  const options = {
    responsive: true,
    aspectRatio: aspectRatio,
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
          beginAtZero: true,
          fontColor: 'white',
          showLabelBackdrop: false
        },
        grid: {
          color: '#CFD2D3',
          circular: true
        },
        angleLines: {
          color: '#CFD2D3'
        },
        pointLabels: {
          color: '#787878'
        }
      }
    },
    plugins: {
      title: {
        display: title
      },
      legend: {
        display: legend
      }
    }
  }

  const color = enumCategoryNameColor[categoryName.toLocaleLowerCase()]

  const getData = useCallback(() => {
    const mapUsers = (dataUsers ?? []).map((user) => {
      return {
        name: user?.name,
        id: user?.id,
        score:
          page === 'radarsCompetence'
            ? user?.scoreAverage?.competences
            : user?.scoreAverage?.competenceGroups
      }
    })

    const mapScore = (mapUsers ?? []).map((score) => {
      return {
        data: score?.score?.map((item) =>
          page === 'radarsCompetence' ? item?.averageScore : item.average
        ),
        labels: score?.score?.map((item) =>
          page === 'radarsCompetence'
            ? item?.competenceName
            : item?.competenceGrouperName
        ),
        label: score?.name,
        id: score?.id,
        backgroundColor: getHexToRGBA(color ? color : '#CCCCCC', 0.1),
        borderColor: color,
        borderWidth: 1
      }
    })

    return {
      labels: labels.length >= 1 ? labels : mapScore[0]?.labels,
      datasets: mapScore
    }
  }, [dataUsers, labels, page, color])

  const getDataInitial = useCallback(() => {
    const radar = (dataState ?? []).map((currentData) => {
      return {
        label: currentData?.competenceGrouperName,
        value: currentData?.average
      }
    })

    const labelsMap = radar?.map((item) => item.label)
    const score = radar?.map((item) => item.value)

    return {
      labels: labels && labels.length >= 1 ? labels : labelsMap,
      datasets: [
        {
          data: score,
          backgroundColor: getHexToRGBA(color ? color : '#CCCCCC', 0.1),
          borderColor: color,
          borderWidth: 1
        }
      ]
    }
  }, [color, dataState, labels])

  useEffect(() => {
    setDataUsers(users)
    setDataState(data)
  }, [users, labels, data])

  return (
    <Box my={2}>
      <Radar
        data={dataUsers.length ? getData() : getDataInitial()}
        options={options}
      />
      <Grid container spacing={2} direction="row" justifyContent="center">
        {labels?.map((item) => {
          return (
            <Grid item xs="auto" key={item}>
              <Chip
                name={item}
                dotColor={theme.palette.primary.main}
                id={item}
                handleRemove={handleRemove}
              />
            </Grid>
          )
        })}

        <Grid item md={12}>
          <Box display="flex" alignItems="center" className={classes.container}>
            {dataUsers?.map((item, index) => (
              <BadgeTooltip
                key={index}
                placement="top"
                disableFocusListener
                disableTouchListener
                user={item}
                selected={false}
                borderColor={color}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
