import { makeStyles, withStyles, Checkbox } from '@material-ui/core'

export default makeStyles((theme) => ({
  inputName: {
    width: '300px',
    paddingTop: '5px'
  },
  pTitle: {
    display: 'flex',
    flex: '1',
    paddingTop: 5,
    paddingLeft: 15
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  iconEditButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  }
}))

export const MuiCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))(Checkbox)
