import React from 'react'
import { useTheme } from '@material-ui/styles'
import makeStyles from './style'

export function ButtonGroup({ buttons, selectedButton, setSelectedButton }) {
  const theme = useTheme()
  const classes = makeStyles()

  return (
    <div className={classes.container}>
      {buttons?.map((e) => (
        <button
          key={e?.key}
          onClick={() => setSelectedButton(e.key)}
          style={{
            background: selectedButton === e?.key && theme.palette.primary.main,
            border: selectedButton === e?.key && '1px solid',
            borderColor:
              selectedButton === e?.key && theme.palette.primary.main,
            color: selectedButton === e?.key && '#fff',
            boxShadow:
              selectedButton === e?.key && '0px 4px 20px rgba(0, 0, 0, 0.25)'
          }}
          className={classes.button}
        >
          {e?.label}
        </button>
      ))}
    </div>
  )
}

export default ButtonGroup
