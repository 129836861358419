import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'max-content'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30
  },
  headerRightSide: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#000000'
  },
  button: {
    width: '100%',
    padding: '13px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(20),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#000000',
    cursor: 'pointer'
  },
  buttonAddMetaAndAction: {
    width: '100%',
    padding: 20,
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000000',
    cursor: 'pointer',
    border: '#D9DBE9 solid 1px',
    background: 'none',
    borderRadius: '8px'
  },

  buttonDescription: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    h6: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(15)
    }
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    gap: 20
  },
  closeButton: {
    cursor: 'pointer'
  },
  userAndInformations: {
    background: '#F7F7FC',
    borderRadius: '10px',
    width: '100%',
    height: '59px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10,
    gap: 20,
    marginBottom: 20
  },
  informationsOfUser: {
    height: 'max-content'
  },
  optionsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 9.43
  },
  option: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: '#F7F7FC',
    padding: '14px 10px'
  },
  optionSelected: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    padding: '14px 10px'
  },
  optionText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#000000'
  },
  optionTextSelected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#F7F7FC'
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15
  },
  actionOption: {
    width: '100%',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    gap: 10,
    flexDirection: 'column',
    background: '#F7F7FC',
    '& section': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }
  },
  actionOptionBox: {
    flexDirection: 'column'
  },
  actionOptionLeftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10
    }
  },
  boxAction: {
    gap: '25px !important'
  },
  metasContainer: {
    borderRadius: '20px',
    overflow: 'hidden',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  closeIcon: {
    width: '24px important!',
    height: '24px important!',
    color: 'white',
    borderRadius: '30px',
    background: '#787878'
  },
  buttonAddMetaToAction: {
    '& button': {
      border: 'none',
      width: '100%',
      height: 40,
      background: '#F7F7FC',
      borderRadius: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '25px 10px',
      cursor: 'pointer',
      gap: 10
    },
    '& :hover': {
      backgroundColor: '#e1e1e1'
    }
  },
  actionOptionContent: {
    width: '100%',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '15px',
    gap: 10,
    flexDirection: 'column',
    background: '#F7F7FC',
    '& section': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }
  }
}))
