import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { Avatar } from 'src/components'
import { useTheme } from '@material-ui/styles'
import { styled } from '@mui/material/styles'
import { Box } from '@material-ui/core'
import { getImageNameColor } from 'src/utils'
import { enumAssignment } from 'src/@enum/user'
import useStyles from './styles'

function Badge({ user }) {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Avatar
        primaryColor={theme.palette.primary.main}
        src={user.imageProfile}
        aria-label={user.name}
        alt={user.name}
        color={getImageNameColor(user.name).color}
        className={classes.avatarBadge}
        style={{ width: 120, height: 120 }}
      >
        {getImageNameColor(user.name).name}
      </Avatar>
      <Box className={classes.nameBadge}>{user.name}</Box>
      <Box className={classes.borderBadge} />
      <Box className={classes.occupationBadge}>
        {user?.occupationAreaName || user?.occupationArea} -{' '}
        {enumAssignment[user?.assignment]}
      </Box>
      <Box className={classes.emailBadge}>{user.email}</Box>
    </Box>
  )
}

export const Styled = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#434343'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    alignItems: 'left',
    padding: '0 10px 10px 10px',
    fontSize: 14,
    borderRadius: 20,
    background: '#434343'
  }
}))

export function BadgeTooltip({
  user,
  selected = false,
  borderColor,
  ...props
}) {
  const theme = useTheme()
  const classes = useStyles({
    borderColor
  })

  return (
    <Styled
      primaryColor={theme.palette.primary.main}
      arrow
      placement="top"
      disableFocusListener
      disableTouchListener
      title={<Badge user={user} />}
      {...props}
    >
      <Box>
        <Avatar
          primaryColor={theme.palette.primary.main}
          src={user.imageProfile}
          aria-label={user.name}
          alt={user.name}
          optionRemove={selected}
          hoverColor={selected && user.imageProfile && '#000000'}
          color={getImageNameColor(user.name).color}
          className={classes.avatar}
          borderColor={borderColor}
        >
          {getImageNameColor(user.name).name}
        </Avatar>
      </Box>
    </Styled>
  )
}
