import Metrics from 'src/screens/Metrics'
import UserNavigation from 'src/screens/UserMetrics/UserNavigation'
import TechnicalMeasures from 'src/screens/UserMetrics/TechnicalMeasures'
import GroupNavigation from 'src/screens/GroupMetrics/GroupNavigation'
import TechnicalMeasuresGroup from 'src/screens/GroupMetrics/TechnicalMeasuresGroup'

export default [
  {
    path: '/metrics',
    element: <Metrics />
  },
  {
    path: '/metrics/user/:id',
    element: <UserNavigation />
  },
  {
    path: '/metrics/development-plan',
    element: <UserNavigation />
  },
  {
    path: '/metrics/user/metrics-full/:id/:category?',
    element: <TechnicalMeasures />
  },
  {
    path: '/metrics/group/:id',
    element: <GroupNavigation />
  },
  {
    path: '/metrics/group/metrics-full/:id/:category?',
    element: <TechnicalMeasuresGroup />
  }
]
