import React from 'react'
import { Modal as ModalUi } from '@mui/material'
import makeStyles from './style'
import { useLoadingContext } from 'src/context/LoadingContext'
import './effect.css'

export function LoadingComponent() {
  const { visible } = useLoadingContext()
  const classes = makeStyles()

  return (
    <ModalUi
      open={visible}
      onClose={() => false}
      className={classes.loadingContainer}
    >
      <div className={classes.loadingEffect} />
    </ModalUi>
  )
}
