import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 30px 30px 30px',
    overflowX: 'hidden'
  },
  boxContent: {
    width: '100%',
    gap: '25px',
    height: 'calc(100vh - 165px)',
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    display: 'flex',
    padding: '0 0 30px 0',
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'start',
    marginRight: '10px',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'flex-start',
    '&::-webkit-scrollbar': {
      height: '10px',
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  boxAll: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 20,
    height: 'calc(100vh - 250px)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    marginTop: -10,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btnClose: {
    marginRight: -10
  },
  boxInforUser: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#F7F7FC',
    padding: 10,
    borderRadius: 10,
    marginBottom: 25,
    marginTop: -23,
    fontSize: 14,
    '& span': {
      marginLeft: 15
    },
    '& strong': {
      margin: 0
    },
    '& p': {
      margin: 0,
      marginTop: 3
    }
  },
  leadersMultiProgressBar: {
    marginTop: 30
  },
  optionsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 14
  },
  option: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    padding: '14px 10px',
    backgroundColor: '#F7F7FC'
  },
  optionSelected: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    padding: '14px 10px'
  },

  optionText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#000000'
  },
  optionTextSelected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#F7F7FC'
  },
  metricsBarProgress: {
    width: '100%',
    height: '20px',
    backgroundColor: '#D8D8D8',
    marginTop: '30px',
    display: 'flex'
  },
  formControlButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    marginTop: 15,
    height: 60
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    height: 60,
    gap: 14
  },
  Container: {
    height: '100%',
    padding: '10px 30px 30px 30px',
    overflow: 'hidden'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
}))
