import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { Tooltip, Dot } from 'src/components'
import { enumCategory, enumCategoryColor } from 'src/@enum/metrics'
import useStyles from './style'

function Doted({ title, value, color }) {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center">
      <Dot color={color} size="medium" style={{ marginRight: 6 }} />
      <Typography className={classes.title}>{title}:</Typography>
      <Typography className={classes.value}> {value}</Typography>
    </Box>
  )
}

export function MultiProgressBar({ data, showLegend = false }) {
  const classes = useStyles()

  const [dataCategoryMetrics, setDataCategoryMetrics] = useState()

  const getData = useCallback(() => {
    const response = Object.entries(data).map((currentData) => {
      const [key, value] = currentData
      return {
        label: key,
        value: value
      }
    })
    setDataCategoryMetrics(response)
  }, [data])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
      <Box className={classes.metricsBarProgress}>
        {dataCategoryMetrics?.map(({ label, value }, index) => (
          <Tooltip
            key={index}
            title={
              <Doted
                title={enumCategory[label]}
                value={value}
                color={enumCategoryColor[label]}
              />
            }
          >
            <Box
              style={{
                background: enumCategoryColor[label],
                width: `${value * 10}%`,
                height: '100%'
              }}
            />
          </Tooltip>
        ))}
      </Box>
      {showLegend && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.containerLegend}
        >
          {dataCategoryMetrics?.map(({ label, value }, index) => (
            <Fragment key={index}>
              {value > 0 && (
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.titleLegend}>
                      {enumCategory[label]}:
                    </Typography>
                    <Typography className={classes.valueLegend}>
                      {value}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      )}
    </>
  )
}
