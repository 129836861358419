import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxTooltip: {
    backgroundColor: '#434343',
    padding: 10,
    borderRadius: 8,
    '& p': {
      margin: 0,
      color: '#fff',
      display: 'flex',
      alignItems: 'center'
    },
    '& span': {
      width: 8,
      height: 8,
      borderRadius: 8,
      marginRight: 4,
      marginTop: -3,
      display: 'block'
    }
  },
  tooltiptText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    textTransform: 'capitalize'
  },
  tooltiptValue: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14
  }
}))
