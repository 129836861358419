import React, { useCallback, useState, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton, Box } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import {
  Button,
  Tooltip,
  NewBoxWidget,
  CardGroupCompetences
} from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { getCompetenceGrouperFromUser } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { enumCategory } from 'src/@enum/metrics'
import makeStyles from './style'

export default function GroupersAndSkills({
  onClose,
  userId,
  userRole,
  payloadCompetencies,
  setPayloadCompetencies,
  payloadCompetenciesId,
  setPayloadCompetenciesId
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext([])

  const [data, setData] = useState([])

  const getListGroupUsers = useCallback(
    async (id) => {
      try {
        const competenceGrouperFromUser = await getCompetenceGrouperFromUser(id)

        const dataGrouperFromUser = competenceGrouperFromUser.map((item) => {
          return {
            categoryId: item.category,
            category: enumCategory[item.category],
            groupers: item.competenceGrouper.flat()
          }
        })

        setData(dataGrouperFromUser)
      } catch (error) {
        onCallAlert({
          type: 'error',
          message: messageResponse(error?.response)
        })
      }
    },
    [userId]
  )

  useEffect(() => {
    getListGroupUsers(userId)
  }, [getListGroupUsers, userId])

  const onHandleSubmit = () => {
    onClose()
  }

  return (
    <NewBoxWidget
      title="Agrupadores e competências"
      subtitle="Selecione competências e/ou agrupadores  que podem ser melhorados com o novo plano de ação."
      options={
        <Box className={classes.options}>
          <Tooltip placement="top" title="Fechar">
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      actions={
        <Button
          label="Adicionar"
          type="submit"
          onClick={onHandleSubmit}
          disabled={!payloadCompetenciesId.length}
        />
      }
      width={500}
      height="100%"
    >
      {data && (
        <CardGroupCompetences
          dataGrouperFromUser={data}
          userRole={userRole}
          payloadCompetencies={payloadCompetencies}
          setPayloadCompetencies={setPayloadCompetencies}
          payloadCompetenciesId={payloadCompetenciesId}
          setPayloadCompetenciesId={setPayloadCompetenciesId}
        />
      )}
    </NewBoxWidget>
  )
}
