import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton, Box, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined'
import {
  NewBoxWidget,
  Tooltip,
  MultiProgressBar,
  BadgeTooltip
} from 'src/components'
import makeStyles from './styles'
import { FILTER_BUTTONS } from 'src/utils/constants'
import { enumAssignment } from 'src/@enum/user'
import { useAlertContext } from 'src/context/AlertContext'
import { getMetricsCustomersUsers } from 'src/services/hooks/metrics/useMetrics'
import { messageResponse } from 'src/utils/messageResponse'

function MemberGroup({ data, isExpandCard, classes }) {
  const classContainer = isExpandCard
    ? classes.itemUserExpand
    : classes.itemUserClose
  const classProgressBar = isExpandCard ? classes.progressExpand : ''

  return (
    <div className={classContainer}>
      <Box className={classes.lineDetailsUser}>
        <BadgeTooltip
          placement="top"
          disableFocusListener
          disableTouchListener
          user={data}
        />
        {isExpandCard && (
          <div>
            <Typography className={classes.name}>{data?.name}</Typography>
            <Typography className={classes.functionUser}>
              {data?.occupationAreaName}
              {data?.assignment ? ` - ${enumAssignment[data?.assignment]}` : ''}
            </Typography>
          </div>
        )}
      </Box>
      <div
        className={classProgressBar}
        style={{
          width: '100%'
        }}
      >
        <MultiProgressBar
          data={data?.averageMetricsByCategoryType}
          showLegend={isExpandCard}
        />
      </div>
    </div>
  )
}

function GroupMembers({ data, customerId, userId, onClose }) {
  const theme = useTheme()

  const classes = makeStyles()
  const { onCallAlert } = useAlertContext()

  const [dataMetricsUsers, setDataMetricsUsers] = useState([])
  const [controlTools, setControlTools] = useState({
    isExpandCard: false,
    filterSelected: null
  })
  const { filterSelected, isExpandCard } = controlTools

  function onChangeExpandCard() {
    setControlTools({
      ...controlTools,
      isExpandCard: !controlTools?.isExpandCard
    })
  }

  const getMetricsUser = useCallback(async (category) => {
    try {
      const response = await getMetricsCustomersUsers({ category })
      const membersId = data.map((user) => user?.id)
      const metricsUsersMembers = response.filter((elem) =>
        membersId.includes(elem.id)
      )
      setDataMetricsUsers(metricsUsersMembers)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    }
  }, [])

  function onChangeValue({ item, value }) {
    setControlTools({
      ...controlTools,
      [item]: value
    })
    getMetricsUser(value)
  }

  useEffect(() => {
    getMetricsUser()
  }, [getMetricsUser])

  return (
    <NewBoxWidget
      title="Membros do grupo"
      options={
        <Box className={classes.options}>
          <Tooltip placement="top" title="Fechar">
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      width={400}
    >
      <Box mb={2} className={classes.containerHeader}>
        <Typography className={classes.titleLineChart}>
          Usuários e médias
        </Typography>
        <div className={classes.rightOptions}>
          <Box className={classes.filterContainer}>
            <Box className={classes.filtersButtons}>
              {FILTER_BUTTONS.map((item) => (
                <Tooltip title={item.label} key={item.value}>
                  <div
                    className={
                      filterSelected != null && filterSelected === item.value
                        ? classes.buttonFilterSelected
                        : classes.buttonFilter
                    }
                    onClick={
                      filterSelected === item.value
                        ? () =>
                            onChangeValue({
                              item: 'filterSelected',
                              value: null
                            })
                        : () =>
                            onChangeValue({
                              item: 'filterSelected',
                              value: item.value
                            })
                    }
                    style={{ backgroundColor: item.color }}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
          <IconButton
            onClick={onChangeExpandCard}
            style={{
              background: isExpandCard && theme.palette.primary.main,
              color: isExpandCard && '#fff'
            }}
          >
            <CallToActionOutlinedIcon
              htmlColor={isExpandCard ? '#fff' : theme.palette.primary.main}
            />
          </IconButton>
        </div>
      </Box>
      <Box mb={4} className={classes.listUsers}>
        {dataMetricsUsers?.map((user) => (
          <MemberGroup
            key={user?.id}
            data={user}
            isExpandCard={isExpandCard}
            classes={classes}
          />
        ))}
      </Box>
    </NewBoxWidget>
  )
}

export default GroupMembers
