import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  titleLineChart: {
    color: '#434343',
    fontSize: 16,
    fontWeight: 600
  },
  containerHeader: {
    margin: '-20px 0px 7px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #cfd2d3'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 5px',
    height: 30
  },
  filtersButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center'
  },
  buttonFilter: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'all 0.3s'
  },
  buttonFilterSelected: {
    width: 22,
    height: 22,
    borderRadius: '100%',
    cursor: 'pointer',
    boxShadow: '0 0 0 2px #f3f5f6 inset',
    boxSizing: 'border-box',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    transition: 'all 0.3s'
  },
  rightOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listGroups: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  itemUserExpand: {
    justifyContent: 'space-between'
  },
  lineDetailsUser: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  itemUserClose: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  progressExpand: {
    marginTop: 15
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: '#787878'
  },
  functionUser: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#787878',
    marginTop: 2
  },
  userContainer: {
    background: '#F7F7FC',
    borderRadius: 10,
    padding: 10,
    marginTop: 10
  },
  boxArrow: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start',
    flex: 1,
    gap: 5
  },
  boxBtnOptions: {
    width: 37,
    height: 37
  },
  containerScroll: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    overflowX: 'hidden',
    height: 'calc(100vh - 400px)'
  },
  containerScrollGroups: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    overflowX: 'hidden',
    height: 'calc(100vh - 440px)'
  },
  boxAvatarGroups: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '5px 2px',
    marginTop: '10px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: 5
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  containerColorMetrics: {
    marginTop: 15
  }
}))
