import React from 'react'
import { UploadImage, NewBoxWidget } from 'src/components'

function PlatformLogo({
  onCloseNextLogo,
  onConfirmLogo,
  onCloseEdit,
  dataAppearance
}) {
  function onChangeLogo(value) {
    onConfirmLogo(value)
  }

  return (
    <NewBoxWidget
      title="Logo da plataforma"
      subtitle="Adicione o logo sem fundo; lembre-se ele normalmente será apresentado sobre sua cor principal."
      noBodyPadding
      width={420}
      style={{ marginLeft: '30px' }}
    >
      <UploadImage
        dataAppearance={dataAppearance}
        onConfirmImage={onChangeLogo}
        onCloseNext={onCloseNextLogo}
        onCloseEdit={onCloseEdit}
        aspect={5 / 2.5}
      />
    </NewBoxWidget>
  )
}

export default PlatformLogo
