import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import { alpha, styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF'
}))

export function LoadingScreen() {
  const customer = JSON.parse(localStorage.getItem('customer'))

  return (
    <RootStyle>
      <motion.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1]
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity
        }}
      >
        <svg
          width="743"
          height="596"
          viewBox="0 0 743 596"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: 60 }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M639.506 0.477631L742.779 196.419H636.126L607.533 144.968L390.771 595.336L283.042 389.594L175.312 595.336L0.503784 223.995H112.933L189.862 387.691L283.042 222.199L376.222 387.691L496.416 141.249L429.192 162.863L366.432 85.4621L635.031 1.84973L635.056 1.90841L639.506 0.477631Z"
            fill={customer?.primaryColor ?? '#38D430'}
          />
        </svg>
      </motion.div>
      <Box
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%']
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: 100,
          height: 100,
          borderRadius: '25%',
          position: 'absolute',
          border: `solid 5px ${alpha(
            customer?.primaryColor ?? '#38D430',
            0.24
          )}`
        }}
      />

      <Box
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%']
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity
        }}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '25%',
          position: 'absolute',
          border: `solid 5px ${alpha(
            customer?.primaryColor ?? '#38D430',
            0.24
          )}`
        }}
      />
    </RootStyle>
  )
}
