import Impressions from 'src/screens/Impressions'
import ViewOneToOne from 'src/screens/UserMetrics/Impressions/Partials/ViewOneToOne'
import NewFeedback from 'src/screens/UserMetrics/Impressions/Partials/NewFeedback'

export default [
  {
    path: '/impressions/:id?',
    element: <Impressions />
  },
  {
    path: '/view/one-to-one/:id',
    element: <ViewOneToOne />
  },
  {
    path: '/feedback/add/:id',
    element: <NewFeedback />
  }
]
