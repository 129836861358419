import React from 'react'
import { Close as CloseIcon } from '@material-ui/icons'
import { Box, Typography, IconButton } from '@material-ui/core'
import { Dot } from 'src/components'
import useStyles from './styles'

export function Chip({ name, dotColor, id, handleRemove, ...props }) {
  var classes = useStyles()

  return (
    <Box display="flex" alignItems="center" className={classes.chip} {...props}>
      <Dot color={dotColor} size="medium" />
      <Typography className={classes.title}>{name}</Typography>
      {/* <IconButton
        type="button"
        className={classes.close}
        onClick={() => handleRemove({ id, name })}
      >
        <CloseIcon className={classes.iconClose} />
      </IconButton> */}
    </Box>
  )
}
