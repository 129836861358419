import React, { useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { InputAdornment, IconButton, Box, Typography } from '@material-ui/core'
import { Input } from 'src/components/Fields'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { Form } from 'react-final-form'
import { emailLoginValidation, loginValidation } from 'src/utils/form/validations'
import { Button, Helmet } from 'src/components'
import { getGreeting } from 'src/utils'
import { messageResponse } from 'src/utils/messageResponse'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'
import LoginLayout from 'src/layouts/Login'
import useStyles from './styles'

function Login() {
  var classes = useStyles()
  const navigate = useNavigate()
  const { login } = useAuth()

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [viewPassword, setViewPassword] = useState(false)
  const [isShowInputPassword, setIsShowInputPassword] = useState(false)



  const onSubmit = async (values) => {
    try {
      const isVerifyEmail = !isShowInputPassword
      onOpenLoading()
      if(isVerifyEmail){
        await login(values.email, '0')
      } else {
        await login(values.email, values.password)
      }
    } catch (error) {
      const isResetPassword = error?.data?.resetPassword
      if (isResetPassword) {
        navigate(`/esqueci-senha/${values?.email}`)
        return 0
      }
      const shouldRenderPassword = isResetPassword === false;
      if(shouldRenderPassword && !isShowInputPassword) {
        setIsShowInputPassword(true);
        return 0
      }
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }
  const currentValidationForm =  isShowInputPassword ? loginValidation : emailLoginValidation;
  const labelButton =  isShowInputPassword ? "Entrar" : "Avançar";

  return (
    <LoginLayout>
      <Helmet title="Login" />
      <Box>
        <Typography variant="h2" className={classes.greeting}>
          {getGreeting()}
        </Typography>
        <Box className={classes.formDividerContainer}>
          <p className={classes.formInfor}>
            Faça seu login para acessar a plataforma
          </p>
        </Box>
        <Form
          onSubmit={(values) => onSubmit(values)}
          validate={currentValidationForm}
          render={({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className={classes.formFields}>
              <Input
                label="E-mail"
                name="email"
                placeholder="Digite seu e-mail"
              />
              {
                isShowInputPassword && (
                  <Input
                    name="password"
                    label="Senha"
                    type={viewPassword ? 'text' : 'password'}
                    placeholder="Digite sua senha"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setViewPassword(!viewPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            {viewPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )
              }
              <Typography className={classes.labelRequired}>
                Obrigatório
              </Typography>
              <Box className={classes.formButtons}>
                <Button
                  variant="link"
                  label="Esqueceu sua senha?"
                  onClick={() => navigate('/esqueci-senha')}
                />
                <Button type="submit" label={labelButton} />
              </Box>
            </form>
          )}
        />
      </Box>
    </LoginLayout>
  )
}

export default Login
