import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxContainer: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '13px',
    marginRight: '10px',
    overflowX: 'hidden',
    height: 'fit-content'
  },
  badgeHeader: {
    display: 'flex',
    gap: 10
  },
  date: {
    color: theme.palette.primary.main,
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    padding: '8px 12px',
    width: 'fit-content',
    margin: 0,
    fontSize: 12,
    borderRadius: 10,
    fontWeight: 600
  },
  boxDescription: {
    width: '100%',
    marginTop: 25,
    marginBottom: 15
  },
  boxAnnotation: {
    width: '100%',
    marginTop: 15,
    marginBottom: 25
  },
  iconButtonClose: {
    padding: 0,
    width: 50,
    height: 50
  },
  boxRodape: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    gap: 15,
    '& span': {
      backgroundColor: '#F7F7FC',
      display: 'flex',
      padding: '8px 10px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 15,
      borderRadius: 100,
      '& p': {
        color: '#787878',
        fontSize: 12,
        margin: 0
      }
    }
  },
  iconEditButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  }
}))
