import React, { useCallback, useEffect, useState } from 'react'
import { getUserCelebration } from 'src/services/hooks/celebrations/useCelebrations'
import { CardMetricsCelebrations, Loading } from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import makeStyles from './style'

export function Celebrations({ customerId, fromUserId }) {
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()

  const [dataCelebrations, setDataCelebrations] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getDataCelebrations = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await getUserCelebration({
        customerId,
        withUsers: [fromUserId]
      })
      setDataCelebrations(result?.data?.data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getDataCelebrations()
  }, [getDataCelebrations])

  return (
    <div className={classes.containerCelebrations}>
      {isLoading ? (
        <Loading />
      ) : (
        <CardMetricsCelebrations items={dataCelebrations} />
      )}
    </div>
  )
}
