import React, { useState, useCallback, useEffect } from 'react'
import {
  Button,
  Avatar,
  ModalViewPDF,
  MultiProgressBar,
  InfoBox,
  BoxHero,
  SimpleProgressBar,
  RadarChartDetails,
  ChartFilterEvolution
} from 'src/components'
import { Grid, Typography, Box } from '@material-ui/core'
import { enumAssignment } from 'src/@enum/user'
import { getImageNameColor } from 'src/utils'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import makeStyles from '../../styles'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useReactToPrint } from 'react-to-print'

export function CompetenceGroupsRadar({ categoryName, data }) {
  return (
    <>
      {data?.map(({ competenceGroupName, competences }, index) => {
        return (
          competences.length > 0 && (
            <CompetenceGroupsRadarBox
              key={index}
              categoryName={categoryName}
              competenceGroupName={competenceGroupName}
              competences={competences}
            />
          )
        )
      })}
    </>
  )
}

export function CompetenceGroupsRadarBox({
  categoryName,
  competenceGroupName,
  competences
}) {
  const [labelsRadar, setLabelsRadar] = useState([])

  const mapScore = (competences ?? []).map((item) => {
    return {
      checked: false,
      competenceGrouperName: item?.competenceName,
      average: item?.averageScore
    }
  })

  const [filter, setFilter] = useState(mapScore)

  const onChangeFilter = useCallback((competences) => {
    setFilter(competences)
  }, [])

  return (
    <Grid item xs={12}>
      <BoxHero
        title={competenceGroupName}
        // options={
        //   <>
        //     <ChartFilterEvolution
        //       items={filter}
        //       category={competenceGroupName}
        //       labels={labelsRadar}
        //       setLabelsRadar={setLabelsRadar}
        //       onChangeFilter={onChangeFilter}
        //     />
        //   </>
        // }
      >
        <RadarChartDetails
          data={filter}
          labels={labelsRadar}
          categoryName={categoryName}
        />
      </BoxHero>
    </Grid>
  )
}

function ModalGeneratePDF({
  data,
  dataCompetenceGroups,
  isOpenModal,
  onCloseModal
}) {
  const classes = makeStyles()

  const ref = React.createRef()

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const handlePrint = useReactToPrint({
    bodyClass: classes.body,
    pageStyle: ' ',
    documentTitle: `${data?.name}.pdf`,
    content: () => ref.current,
    onBeforeGetContent: () => onOpenLoading(),
    onAfterPrint: () => {
      onCloseLoading()
      onCloseModal()
    }
  })

  return (
    <ModalViewPDF
      isOpen={isOpenModal}
      onHandleClose={onCloseModal}
      actions={
        <>
          <Button label="Fechar" variant="underline" onClick={onCloseModal} />
          <Button
            label="Extrair PDF"
            icon={DownloadOutlinedIcon}
            onClick={handlePrint}
          />
        </>
      }
      width={610}
      height="90%"
    >
      <Grid container spacing={3} ref={ref}>
        <Grid item xs={12} md={12}>
          <Box className={classes.titleTable}>
            <InfoBox title="Métricas técnicas" />
          </Box>
          <Box className={classes.userContainer}>
            <Box className={classes.displayContent}>
              <Box className={classes.avatar}>
                <Avatar
                  src={data?.profileImage}
                  aria-label={data?.name}
                  alt={data?.name}
                  color={getImageNameColor(data?.name).color}
                >
                  {getImageNameColor(data.name).name}
                </Avatar>
              </Box>
              <Box>
                <Typography className={classes.name}>{data?.name}</Typography>
                <Typography className={classes.date}>
                  {data?.occupationAreaName} -{' '}
                  {enumAssignment[data?.assignment]}
                </Typography>
                <Typography className={classes.email}>{data?.email}</Typography>
              </Box>
            </Box>
            {data?.averageMetricsByCategoryType && (
              <MultiProgressBar
                data={data?.averageMetricsByCategoryType}
                showLegend
              />
            )}
          </Box>
          {data?.radarsCompetenceGrouper && (
            <BoxHero title="Médias das sessões">
              <Box className={classes.simpleProgress}>
                <SimpleProgressBar data={data?.radarsCompetenceGrouper} />
              </Box>
            </BoxHero>
          )}
        </Grid>
        {dataCompetenceGroups?.map((item) =>
          item.map(({ categoryName, competenceGroups }, index) => (
            <CompetenceGroupsRadar
              categoryName={categoryName}
              data={competenceGroups}
              key={index}
            />
          ))
        )}
      </Grid>
    </ModalViewPDF>
  )
}

export default ModalGeneratePDF
