import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  loadingContainer: {
    background: 'rgba(67, 67, 67, 0.6)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingEffect: {
    margin: '60px auto',
    fontsize: '10px',
    position: 'relative',
    textIndent: '-9999em',
    borderTop: '1.1em solid #CFD2D3',
    borderRight: '1.1em solid #CFD2D3',
    borderBottom: '1.1em solid #CFD2D3',
    borderLeft: '1.1em solid',
    borderLeftColor: theme.palette.primary.main,
    '-webkit-transform': 'translateZ(0)',
    '-ms-transform': 'translateZ(0)',
    transform: 'translateZ(0)',
    '-webkit-animation': 'load8 1.1s infinite linear',
    animation: 'load8 1.1s infinite linear',
    borderRadius: '50%',
    width: '10em',
    height: '10em',
    border: '1px solid red',
    '& ::after': {
      borderRadius: '50%',
      width: '10em',
      height: '10em'
    }
  }
}))
