import React from 'react'
import makeStyles from './styles'
import { Box, Typography } from '@material-ui/core'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'

const LINE_CATEGORIES = [
  { id: 1, label: 'performance', color: '#FFA520' },
  { id: 2, label: 'conhecimento', color: '#D81B60' },
  { id: 3, label: 'comportamento', color: '#9C27B0' },
  { id: 4, label: 'liderança', color: '#0063BF' }
]

export function SimpleLineChart({ data }) {
  const classes = makeStyles()

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box className={classes.boxTooltip}>
          {payload.map((item) => (
            <Typography key={item.key} className={classes.tooltiptText}>
              <span style={{ backgroundColor: item.stroke }} />
              {item.dataKey}:{' '}
              <strong className={classes.tooltiptValue}>{item.value}</strong>
            </Typography>
          ))}
        </Box>
      )
    }
    return null
  }

  return (
    <ResponsiveContainer width="100%" height={160}>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 3,
          left: -20,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontSize={10}
          dataKey="name"
          style={{ textTransform: 'uppercase' }}
        />
        <YAxis fontSize={10} />
        <Tooltip content={<CustomTooltip />} />
        {LINE_CATEGORIES?.map((item) => (
          <Line
            type="monotone"
            dataKey={item.label}
            stroke={item.color}
            key={item.key}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
