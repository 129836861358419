import React, { useState, useRef, useEffect } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import { Button, HeaderBreadcrumbs, Helmet } from 'src/components'
import { useNavigate } from 'react-router-dom'
import Groups from './Partials/Groups'
import DataGroup from './Partials/DataGroup'
import SettingsGroup from './Partials/SettingsGroup'
import { GroupOutlined as GroupOutlinedIcon } from '@material-ui/icons'
import { Box } from '@material-ui/core'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './style'

function ListGroups() {
  const classes = makeStyles()
  const navigate = useNavigate()

  const [role] = useState([1, 2])

  const [links, setLinks] = useState()
  const [editGroup, setEditGroup] = useState(false)
  const [groupSelected, setGroupSelected] = useState()
  const [selectedGroup, setSelectedGroup] = useState(false)
  const globalClasses = useGlobalUseStyles({ selectedGroup })
  const scrollRef = useRef(null)

  const shouldRenderButton = !editGroup

  useEffect(() => {
    setLinks([
      {
        name: 'Grupos de usuários',
        href: '/groups'
      }
    ])
  }, [])

  function onAddNew() {
      navigate('/groups/add')
  }

  function onGetGroupSelected(value) {
    setLinks([
      {
        name: 'Grupos de usuários',
        href: '/groups'
      },
      {
        name: `${value.name}`,
        href: `/groups/view/${value.id}`
      }
    ])
    setGroupSelected(value)
  }

  function onCloseEditGroup() {
    setLinks(links.slice(0, -1))
    setEditGroup(false)
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Grupos de usuários" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs icon={<GroupOutlinedIcon />} links={links || [{}]} />
        {shouldRenderButton && (
          <Button
            label="Criar um novo grupo"
            variant="dark"
            onClick={onAddNew}
          />
        )}
      </Box>
      <Box className={globalClasses.content}>
        <Box ref={scrollRef} className={classes.contentHorizontal}>
          <Groups
            onSelectGroup={setSelectedGroup}
            onGetGroupSelected={onGetGroupSelected}
          />
          {selectedGroup && (
            <>
              <DataGroup groupSelected={groupSelected} />
              {editGroup && (
                <SettingsGroup
                  groupSelected={groupSelected}
                  onCloseEditGroup={onCloseEditGroup}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </RoleBasedGuard>
  )
}

export default ListGroups
