import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from './style'
import { Modal as ModalUi } from '@mui/material'
import clsx from 'clsx'

export function Modal({
  title,
  subTitle,
  children,
  className,
  isOpen,
  onHandleClose,
  variant
}) {
  const classes = makeStyles({ isOpen })

  return (
    <ModalUi open={isOpen} onClose={onHandleClose}>
      <div className={clsx(classes.modalChildren, classes[variant], className)}>
        {title ? <h1 className={classes.modalTitle}>{title}</h1> : null}
        {subTitle ? (
          <h2 className={classes.modalSubTitle}>{subTitle}</h2>
        ) : null}
        {children}
      </div>
    </ModalUi>
  )
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subTitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onHandleClose: PropTypes.func
}
