import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'
import { Button, Modal } from 'src/components'
import { Form } from 'react-final-form'
import { Input } from 'src/components/Fields'
import { InputAdornment, IconButton, Box } from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material'
import { changeCustomerPassword } from 'src/utils/form/validations'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { putUser } from 'src/services/hooks/users/useUsers'
import useGlobalUseStyles from 'src/themes/globalUseStyles'

function ChangePassword({ openModal, onCloseModal, payloadUser }) {
  const globalClasses = useGlobalUseStyles()

  const { login } = useAuth()

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { notifications, onCallAlert } = useAlertContext()

  const [viewPassword, setViewPassword] = useState({
    password: false
  })

  function handlePassword(key) {
    setViewPassword({
      ...viewPassword,
      [key]: !viewPassword[key]
    })
  }

  function renderIconViewPassword(key) {
    return viewPassword[key] ? <VisibilityIcon /> : <VisibilityOffIcon />
  }

  function renderTypeInput(key) {
    return viewPassword[key] ? 'text' : 'password'
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const onHandleSubmit = async (values) => {
    try {
      onOpenLoading()
      await putUser(payloadUser)
      await login(values.email, values.password)
      onCloseModal()
      refreshPage()
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <Modal
      isOpen={openModal}
      onHandleClose={onCloseModal}
      title="Acessar cliente"
      subTitle="Faça seu login novamente para acesso"
    >
      <Form
        onSubmit={onHandleSubmit}
        initialValues={{
          email: payloadUser?.email
        }}
        validate={changeCustomerPassword}
        render={({ handleSubmit, valid, values }) => (
          <form onSubmit={handleSubmit} style={{ width: 300 }}>
            <Box className={globalClasses.fieldsForm}>
              <Input
                label="Senha*"
                name="password"
                placeholder="Digite sua senha"
                type={renderTypeInput('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handlePassword('password')}>
                        {renderIconViewPassword('password')}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Button
                label="Cancelar"
                variant="underline"
                onClick={() => onCloseModal(false)}
              />
              <Button type="submit" label="Entrar" disabled={!valid} />
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

ChangePassword.propTypes = {
  isOpenModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  email: PropTypes.string
}

export default ChangePassword
