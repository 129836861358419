import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 30px 30px 30px',
    overflowX: 'hidden'
  },
  boxContent: {
    width: '100%',
    gap: '25px',
    height: 'calc(100vh - 165px)',
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    display: 'flex',
    padding: '0 0 30px 0',
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'start',
    marginRight: '10px',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'flex-start',
    '&::-webkit-scrollbar': {
      height: '10px',
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  boxBtnNavegation: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxContentBtn: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    boxShadow: '0px 0px 6px -3px #3e3e3e',
    borderRadius: 50,
    border: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}))
