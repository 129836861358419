import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    height: 'auto'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boxStarTooltip: {
    height: 40,
    width: 40,
    marginTop: -8
  },
  headerInformations: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& strong': {
      lineHeight: '30px',
      margin: 0,
      fontSize: 18
    },
    '& p': {
      lineHeight: '28px',
      margin: 0,
      fontSize: 16
    }
  },
  leadersList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '10px',
    paddingBottom: '20px'
  },
  leadersListAvatars: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: 15,
    gap: 9.43
  },
  leadersMultiProgressBar: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
    display: 'flex'
  },
  optionsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15
  },
  option: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: '#F7F7FC',
    padding: '14px 10px 14px 14px'
  },
  optionSelected: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    padding: '14px 10px 14px 14px'
  },
  optionText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#000000'
  },
  optionTextSelected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#F7F7FC'
  },
  starOption: {
    height: 'fit-content'
  }
}))
