import React from 'react'
import { Box, Typography, Divider } from '@material-ui/core'
import useStyles from './styles'

export function BoxHero({ children, title, removeDivider, options, ...props }) {
  var classes = useStyles()

  return (
    <Box>
      <Box
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        {...props}
      >
        <Typography className={classes.titleHead} {...props}>
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.options}
        >
          {options}
        </Box>
      </Box>
      {!removeDivider && <Divider style={{ margin: '8px 0' }} />}
      {children}
    </Box>
  )
}
