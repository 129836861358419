import axios, { tokenAuthorization } from 'src/utils/axios'
import { USER_GROUP, USER_GROUP_SEARCH } from 'src/services/api'

export async function getUserGroups() {
  const response = await axios.get(USER_GROUP, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function postUserGroups(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(USER_GROUP, body, parameters)
  const { data } = response.data
  return data
}

export async function putUserGroups(id, body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'PUT',
    headers: token
  }
  const response = await axios.put(USER_GROUP + `/${id}`, body, parameters)
  const { data } = response.data
  return data
}

export async function getUserGroupById(id) {
  const result = await axios.get(USER_GROUP + `/${id}`, tokenAuthorization())
  const { leaders, members } = result?.data?.data
  const response = members.map((members) => ({
    ...leaders.find((leader) => leader.id === members.id),
    ...members
  }))
  return response
}

export async function getUserGroupDataById(idUserGroup) {
  const result = await axios.get(
    USER_GROUP + `/${idUserGroup}`,
    tokenAuthorization()
  )
  const { id, name, leaders, members, groupPermissions, isActive } =
    result?.data?.data
  const leadersList = leaders.map((item) => item.id)
  const membersList = members.map((item) => item.id)
  const groupPermissionsList = groupPermissions.map((item) => item.permissionId)
  const response = {
    id,
    name,
    leaders: leadersList,
    members: membersList,
    groupPermissions: groupPermissions,
    permissions: groupPermissionsList,
    isActive: isActive
  }
  return response
}

export async function getUserGroupNameById(id) {
  const response = await axios.get(USER_GROUP + `/${id}`, tokenAuthorization())
  const { name } = response?.data?.data
  return name
}

export async function getUserGroupsPermission() {
  const response = await axios.get(USER_GROUP + `/permission`)
  const { data } = response.data
  return data
}

export async function postUserGroupsSearch(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(USER_GROUP_SEARCH, body, parameters)
  const { data } = response.data
  return data
}
