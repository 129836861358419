import NewPDI from 'src/screens/UserMetrics/PDI/NewPDI'
import EditPDI from 'src/screens/UserMetrics/PDI/EditPDI'
import ArchivePDI from 'src/screens/UserMetrics/PDI/ArchivePDI'
import ReviewPDI from 'src/screens/UserMetrics/PDI/ReviewPDI'

export default [
  {
    path: '/development-plan/add/:id',
    element: <NewPDI />
  },
  {
    path: '/development-plan/edit/:user/:id',
    element: <EditPDI />
  },
  {
    path: '/development-plan/archive/:id',
    element: <ArchivePDI />
  },
  {
    path: '/development-plan/review/:user/:id',
    element: <ReviewPDI />
  }
]
