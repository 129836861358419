import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  modalContainer: {
    background: '#fff',
  },
  tableWidth: {
    minWidth: '50vw',
  },
  BoxContainer: {
    padding: '0px',
  },
  BoxBtn: {
    display: 'flex',
    gap: 3,
    marginTop: 20,
    '& button': {
      border: 'none',
      padding: '0px 20px 0px 20px',
      background: 'transparent',
      fontSize: '16px',
      borderBottom: '3px solid transparent',
      height: '45px',
      '& p': {
        padding: '0px',
        margin: '0px'
      }
    }
  },
  BoxContent: {
    maxHeight: '40vh',
    gap: 10,
    overflowX: 'hidden',
    overflowY: 'scroll',
    alignItems: 'start',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    },
    '& Section': {
      padding: '20px 0px 0px 0px'
    }
  },
  boxForm: {
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
    padding: '15px 0px 0px 0px'
  },
  modalButtons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: '15px'
  },
  BoxSwitch: {
    display: 'flex',
    flex: '1',
    alignItems: 'center'
  }
}))
