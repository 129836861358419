import { makeStyles } from '@material-ui/styles'
import { getColor } from 'src/utils'

export default makeStyles((theme) => ({
  avatar: {
    backgroundColor: (props) =>
      props.hoverColor
        ? props.hoverColor
        : getColor(props.color, theme, props.colorBrightness)
  },
  avatarRemove: {
    cursor: 'pointer',
    backgroundColor: (props) =>
      props.hoverColor
        ? props.hoverColor
        : getColor(props.color, theme, props.colorBrightness),
    '&:hover img': {
      opacity: 0.3
    },
    '&:hover:before': {
      content: "''",
      position: 'absolute',
      border: '1px solid #FFFFFF',
      left: '50%',
      height: 16,
      width: 2,
      transform: 'rotate(45deg)',
      opacity: 1
    },
    '&:hover:after': {
      content: "''",
      position: 'absolute',
      border: '1px solid #FFFFFF',
      left: '50%',
      height: 16,
      width: 2,
      transform: 'rotate(-45deg)',
      opacity: 0.8
    }
  },
  container: {
    borderRadius: 50,
    border: '2px solid',
    borderColor: (props) => props.borderColor,
    padding: 2
  }
}))
