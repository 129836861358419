import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  actionOptionContent: {
    width: '100%',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '15px',
    gap: 10,
    flexDirection: 'column',
    background: '#F7F7FC',
    '& section': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }
  },
  actionOption: {
    width: '100%',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    flexDirection: 'column',
    background: '#F7F7FC'
  },
  actionOptionLeftSide: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  deleteIcon: {
    width: '24px important!',
    height: '24px important!',
    color: 'white',
    borderRadius: '30px',
    background: '#787878'
  }
}))
