import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerModal: {
    display: 'flex',
    justifyContent: 'center',
  },
  boxModal: {
    margin: 'auto',
    minWidth: '500px',
    height: '80vh',
    width: 'fit-Content',
    padding: '30px',
    borderRadius: '10px',
    position: 'relative',
    display: 'flex',
    columnGap: '30px'
  },
  contentBox: {
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
  },
  buttonAddOneToOne: {
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    animation: '0.3s',
    transition: '0.3s',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#000000',
    cursor: 'pointer'
  },
  buttonAddOneToOneSelected: {
    backgroundColor: theme.palette.primary.main,
    animation: '0.3s',
    transition: '0.3s',
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#fff',
    cursor: 'pointer'
  },
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  iconButtonSelect: {
    padding: 0,
    width: 40,
    height: 40,
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  button: {
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    animation: '0.3s',
    transition: '0.3s',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#000000'
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary.main,
    animation: '0.3s',
    transition: '0.3s',
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#fff'
  },
  data: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 10,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500
  },
  dataSelect: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 10,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500
  },
  buttonDetailsOneToOneSelect: {
    color: '#FFFFFF',
    textAlign: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 20,
      padding: 10
    }
  },
  buttonDetailsOneToOne: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 20,
      padding: 10
    }
  },
  iconSelected: {
    color: '#ffffff'
  },
  dateSection: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '20px',
    width: '100%',
    fontWeight: '600',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    letterSpacing: theme.typography.pxToRem(0.15),
    background: '#F7F7FC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#787878',
    padding: 5
  },
}))
