import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerAlert: {
    position: 'fixed',
    top: '83px',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '15px',
    padding: '12px',
    alignItems: 'center',
    borderRadius: '5px',
    maxWidth: '450px',
    zIndex: 1301,
    transition: 'all .2s',
    '& .MuiButtonBase-root': {
      minWidth: '30px'
    }
  },
  alertMessage: {
    color: '#fff',
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(17.58),
    letterSpacing: '0.74976px',
    margin: 0
  },
  default: {
    backgroundColor: '#787878'
  },
  success: {
    backgroundColor: '#787878'
  },
  error: {
    backgroundColor: '#E32929'
  },
  show: {
    right: '35px'
  },
  hidden: {
    right: '-450px'
  }
}))
