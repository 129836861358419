import React, { useCallback, useState, useEffect } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useNotification from 'src/hooks/useNotification'
import { useTheme } from '@material-ui/styles'
import moment from 'moment'
import useAuth from 'src/hooks/useAuth'
import { styled } from '@mui/material/styles'
import {
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon
} from '@material-ui/icons'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import {
  NewBoxWidget,
  HeaderBreadcrumbs,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound,
  Helmet,
  Tooltip
} from 'src/components'
import { FormControlLabel, IconButton } from '@material-ui/core'
import { getComparator, applySortFilter } from 'src/utils'
import { getNotificationsById } from 'src/services/hooks/notifications/useNotifications'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles, { MuiCheckbox } from './style'

const TABLE_HEAD = [
  { id: 'createAt', label: 'Data', alignRight: false },
  { id: 'title', label: 'Título', alignRight: false },
  { id: 'description', label: 'Descrição', alignRight: false },
  { id: 'action', label: 'Lido/Não lido', alignRight: true },
  { id: 'action', label: '', alignRight: true }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '8px 8px 8px 16px'
  }
}))

function Notifications() {
  const theme = useTheme()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const { updateNotification } = useNotification()

  const [role] = useState([1, 2, 3, 4])

  const { user } = useAuth()

  const currentUserId = user?.id

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('createAt')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClickWasViewed = (event, value, body) => {
    const payload = { ...body, wasViewed: value }
    updateWasViewed(body.id, payload)
  }

  const handleClickWasViewedRedirect = (body, redirect, userId) => {
    const payload = { ...body, wasViewed: true }
    updateWasViewed(body.id, payload)

    setTimeout(() => {
      window.location.href = redirect
    }, 300)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  const getListNotification = useCallback(async (id) => {
    try {
      setIsLoading(true)
      const response = await getNotificationsById(id)
      setData(response)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getListNotification(currentUserId)
  }, [currentUserId, getListNotification])

  const updateWasViewed = useCallback(async (id, payload) => {
    try {
      await updateNotification(id, payload)
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Histórico de Notificações" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<PersonOutlineOutlinedIcon />}
          links={[{ name: 'Histórico de notificações' }]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <NewBoxWidget
          title="Todas as ações"
          subtitle="Visualize o histórico de notificações e suas atividades na plataforma"
          options={
            <TableToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          }
          actions={
            <Box className={classes.actions}>
              <Box />
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={'Registros por página'}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          }
          fullBody
          borderActions
          noBodyPadding
        >
          <TableContainer
            sx={{
              maxHeight: '100%',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: `${theme.palette.primary.main}`,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const {
                      id,
                      createAt,
                      title,
                      description,
                      redirect,
                      wasViewed,
                      userId
                    } = row

                    const payload = {
                      id: id,
                      userId: userId,
                      title: title,
                      description: description,
                      redirect: redirect
                    }

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <StyledTableCell align="left">
                          {moment(createAt).format('L')}
                        </StyledTableCell>
                        <StyledTableCell align="left">{title}</StyledTableCell>
                        <StyledTableCell align="left">
                          {description}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <FormControlLabel
                            control={
                              <MuiCheckbox
                                primaryColor={theme.palette.primary.main}
                                color="default"
                                defaultChecked={wasViewed}
                                onChange={(e) => {
                                  handleClickWasViewed(
                                    e,
                                    e.target.checked,
                                    payload
                                  )
                                }}
                              />
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {redirect && (
                            <Tooltip title="Visualizar">
                              <IconButton
                                className={classes.icon}
                                onClick={(e) => {
                                  handleClickWasViewedRedirect(
                                    payload,
                                    redirect,
                                    userId
                                  )
                                }}
                              >
                                <ArrowForwardIosOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <TableLoading />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {data.length === 0 && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <TableNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{ py: 10, border: 'none' }}
                    >
                      <Typography variant="h6" paragraph>
                        Nenhum registro encontrado!
                      </Typography>

                      <Typography variant="body2">
                        Nenhum resultado encontrado para &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Tente verificar erros de digitação.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </NewBoxWidget>
      </Box>
    </RoleBasedGuard>
  )
}

export default Notifications
