import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@material-ui/styles'
import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext
} from 'src/context/ThemeContext'
import { CssBaseline } from '@material-ui/core'
import Router from 'src/routes'
import { AuthProvider } from 'src/context/JWTContext'
import { NotificationProvider } from 'src/context/NotificationContext'
import { AlertProvider } from 'src/context/AlertContext'
import { LoadingProvider } from 'src/context/LoadingContext'
import { SettingsProvider } from 'src/context/SettingsContext'
import { CurrentPeriodProvider } from './context/CurrentPeriodContext'

// if (process.env.ENVIRONMENT === 'production') {
//   Sentry.init({
//     dsn: process.env.SENTRY_DSN,
//     environment: process.env.ENVIRONMENT,
//     integrations: [new BrowserTracing()]
//   })
// }

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthProvider>
    <HelmetProvider>
      <SettingsProvider>
        <NotificationProvider>
          <BrowserRouter>
            <CurrentPeriodProvider>
              <ThemeChangeProvider>
                <ThemeStateContext.Consumer>
                  {(theme) => (
                    <ThemeProvider theme={theme}>
                      <LoadingProvider>
                        <AlertProvider>
                          <CssBaseline />
                          <Router />
                        </AlertProvider>
                      </LoadingProvider>
                    </ThemeProvider>
                  )}
                </ThemeStateContext.Consumer>
              </ThemeChangeProvider>
            </CurrentPeriodProvider>
          </BrowserRouter>
        </NotificationProvider>
      </SettingsProvider>
    </HelmetProvider>
  </AuthProvider>
)
