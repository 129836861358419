import React from 'react'
import { Button as ButtonBase, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import makeStyles from './style'
import classnames from 'classnames'

export function Button({
  label,
  className,
  icon: Icon,
  variant,
  disabled,
  customerPrimaryColor,
  ...props
}) {
  const classes = makeStyles({ disabled, customerPrimaryColor })

  return (
    <ButtonBase
      className={classnames(
        classes.containerButton,
        {
          [classes[variant]]: variant,
          [classes.disabled]: disabled
        },
        className
      )}
      disabled={disabled}
      {...props}
    >
      <Box
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        className={classes.button}
      >
        {label}
        {Icon ? <Icon className={classes.sizeIcon} /> : null}
      </Box>
    </ButtonBase>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.object,
  variant: PropTypes.oneOf(['dark', 'link', 'underline'])
}
