import ListGroups from 'src/screens/Groups/ListGroups'
import NewGroups from 'src/screens/Groups/NewGroups'
import EditGroup from 'src/screens/Groups/EditGroups'

export default [
  {
    path: '/groups',
    element: <ListGroups />
  },
  {
    path: '/groups/view/:group',
    element: <ListGroups />
  },
  {
    path: '/groups/add',
    element: <NewGroups />
  },
  {
    path: '/groups/edit/:id',
    element: <EditGroup />
  }
]
