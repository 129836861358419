import { makeStyles, Switch, withStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  formSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '32px'
  },
  formUserActivation: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  formInput2: {
    width: 'calc(50% - 17px)'
  },
  formInput3: {
    width: 'calc(33.33% - 23px)'
  },
  formDoubleInputSmall: {
    display: 'flex',
    columnGap: '18px'
  },
  formInputSmall: {
    width: 'calc(50% - 9px)'
  },
  formGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px 10px',
    position: 'relative',
    marginTop: 10
  },
  formLabelInput: {
    position: 'absolute',
    left: 0,
    top: '-23px'
  },
  formItemGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: '10px',
    borderRadius: '100px',
    border: '1px solid green',
    padding: '10px 20px'
  },
  formItemText: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  formItemButton: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    border: 'none',
    fontWeight: 700,
    fontSize: '0.8rem',
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formControlButton: {
    display: 'flex',
    columnGap: '20px'
  },
  formInfo: {
    fontSize: theme.typography.pxToRem(14)
  },

  Container: {
    height: '100%',
    padding: '10px',
    overflow: 'hidden'
  },
  textStatusUser: {
    display: 'flex',
    width: 120
  }
}))

export const MuiSwitch = withStyles((theme) => ({
  root: {
    '& .Mui-checked': {
      color: theme.palette.primary.main
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5
    }
  }
}))(Switch)
