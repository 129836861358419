import React, { useState, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import useNotification from 'src/hooks/useNotification'
import { alpha } from '@mui/material/styles'
import { Badge } from '@mui/material'
import { IconButton } from '@material-ui/core'
import { NotificationsNoneOutlined as NotificationsNoneOutlinedIcon } from '@mui/icons-material'
import Notification from 'src/screens/Notification'
import MenuPopover from 'src/components/MenuPopover'

export default function NotificationsPopover() {
  const { user } = useAuth()
  const { notifications, notificationsTotal, getNotification } =
    useNotification()

  const currentUserId = user?.id

  const [openPopover, setOpenPopover] = useState(null)

  useEffect(() => {
    getNotification()
  }, [])

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <>
      <IconButton
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <Badge badgeContent={notificationsTotal} color="error">
          <NotificationsNoneOutlinedIcon
            sx={{ width: 34, height: 34, color: '#FFFFFF' }}
          />
        </Badge>
      </IconButton>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ p: 0, borderRadius: 4 }}
      >
        <Notification data={notifications} userId={currentUserId} />
      </MenuPopover>
    </>
  )
}
