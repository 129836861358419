import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxContainer: {
    width: '416px',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '13px',
    marginRight: '10px',
    overflowX: 'hidden',
    height: 'fit-content',
    '& .listOneUser': {
      justifyContent: 'flex-end'
    },
    '& .listMultipleUsers': {
      '& .labelForUsers': {
        position: 'absolute',
        height: 'fit-content',
        margin: 'auto',
        left: 13,
        top: 0,
        bottom: 0,
        zIndex: 1
      },
      '& .containerListUsers': {
        overflow: 'auto',
        justifyContent: 'flex-start',
        paddingLeft: 55,
        flex: 1,
        '&::-webkit-scrollbar': {
          height: 5
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.primary.main,
          borderRadius: '10px'
        }
      }
    }
  },
  badgeHeader: {
    display: 'flex',
    gap: 10
  },
  headerData: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      color: theme.palette.primary.main,
      border: 'solid 1px',
      borderColor: theme.palette.primary.main,
      padding: '8px 12px',
      width: 'fit-content',
      margin: 0,
      fontSize: 12,
      borderRadius: 10,
      fontWeight: 600
    }
  },
  boxDescription: {
    width: '100%',
    padding: '15px 0px 25px 0px',
    '& p': {
      color: '#000',
      margin: 0,
      fontSize: 14,
      lineHeight: '18px'
    }
  },
  boxRodape: {
    maxWidth: '100%',
    display: 'flex',
    gap: 15,
    borderRadius: '10px'
  },
  boxUers: {
    backgroundColor: '#F7F7FC',
    display: 'flex',
    padding: '8px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    borderRadius: 100,
    flex: 0.2,
    '& p': {
      color: '#787878',
      fontSize: 12,
      margin: 0
    }
  },
  containerListUsers: {
    display: 'flex',
    position: 'relative'
  },
  boxLikes: {
    marginTop: 20,
    marginBottom: 10,
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  iconButtonClose: {
    width: '100%',
    height: 35,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 4,
    padding: '0px 20px 0px 20px'
  },
  boxLikeAndComment: {
    borderTop: 'solid 1px #d9d8d8',
    display: 'flex',
    gap: 10,
    justifyContent: 'space-around',
    '& section': {
      display: 'flex',
      '& p': {
        fontSize: 17,
        paddingLeft: 10
      }
    }
  }
}))
