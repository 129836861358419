import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import makeStyles from './styles'
import { IconButton } from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@material-ui/icons'
import {
  RadarChartDetails,
  BoxHero,
  Tooltip,
  ChartFilterEvolution,
  ChartFilterCompare,
  RadarChartGroup
} from 'src/components'
import { getMetricsUsers } from 'src/services/hooks/metrics/useMetrics'

export function AccordionMeasurementDetails({
  categoryName,
  competenceGroupName,
  competences,
  allExpanded = false,
  type,
  users
}) {
  const classes = makeStyles()

  const [usersSelected, setUsersSelected] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const mapScore = competences.map((item) => {
    return {
      checked: false,
      competenceGrouperName: item?.competenceName,
      average: item?.averageScore
    }
  })

  const [labelsRadar, setLabelsRadar] = useState([])
  const [filter, setFilter] = useState(mapScore)

  const onChangeFilter = useCallback((data) => {
    setFilter(data)
  }, [])

  const [expanded, setExpanded] = useState(allExpanded)

  const onSelectUsers = async ({ id, name }) => {
    const exists = usersSelected.findIndex(function (item, i) {
      return item.id === id
    })
    if (exists === -1) {
      try {
        const userMetrics = await getMetricsUsers({ id })
        const index = userMetrics.radarsCompetenceGrouper.findIndex(function (
          item,
          i
        ) {
          return item.category === categoryName
        })
        if (index >= 0) {
          setUsersSelected([
            {
              id: id,
              name: name,
              scoreAverage: userMetrics.radarsCompetenceGrouper[index]
            },
            ...usersSelected
          ])
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setUsersSelected(usersSelected.filter((item) => item.id !== id))
    }
  }

  const handleRemove = (data) => {
    onSelectUsers(data)
  }

  return (
    <BoxHero
      title={competenceGroupName}
      options={
        <>
          {type === 'userGroup' && (
            <ChartFilterCompare
              items={users}
              onSelectUsers={onSelectUsers}
              setSelectedUsers={setSelectedUsers}
            />
          )}
          <ChartFilterEvolution
            items={filter}
            category={categoryName}
            labels={labelsRadar}
            setLabelsRadar={setLabelsRadar}
            onChangeFilter={onChangeFilter}
          />
          <Tooltip title={expanded ? 'Fechar' : 'Abrir'}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </>
      }
      style={{ textTransform: 'capitalize' }}
      className={!expanded && classes.accordion}
      removeDivider={!expanded}
      mt={2}
    >
      <>
        {expanded && (
          <RadarChartGroup
            data={filter}
            labels={labelsRadar}
            users={usersSelected}
            usersSelected={selectedUsers}
            categoryName={categoryName}
            handleRemove={handleRemove}
          />
        )}
      </>
    </BoxHero>
  )
}

AccordionMeasurementDetails.propTypes = {
  competenceGroupName: PropTypes.string,
  competences: PropTypes.array,
  allExpanded: PropTypes.bool,
  type: PropTypes.oneOf(['user', 'userGroup'])
}
