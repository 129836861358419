import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { Input } from 'src/components/Fields'
import { Form } from 'react-final-form'
import { emailLoginValidation } from 'src/utils/form/validations'
import { Button } from 'src/components'
import { postForgotPassword } from 'src/services/hooks/users/useUsers'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import useStyles from '../styles'

function GetEmail({ onNextScreen, goToLogin }) {
  const classes = useStyles()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  async function onHandleSubmit({ email }) {
    try {
      onOpenLoading()
      await postForgotPassword({ email })
      onNextScreen({ email })
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <Box>
      <Typography variant="h2" className={classes.greeting}>
        Esqueceu sua
        <br />
        senha?
      </Typography>
      <div className={classes.formDividerContainer}>
        <p className={classes.formInfor}>
          Confirme seu e-mail e <br />
          enviaremos um novo acesso
        </p>
      </div>
      <Form
        onSubmit={(values) =>
          onHandleSubmit({
            email: values.email
          })
        }
        validate={emailLoginValidation}
        render={({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="email"
              label="Seu e-mail"
              placeholder="Digite seu email"
            />
            <div className={classes.formButtons}>
              <Button label="Voltar Login" variant="link" onClick={goToLogin} />
              <Button disabled={!valid} label="Enviar" type="submit" />
            </div>
          </form>
        )}
      />
    </Box>
  )
}

export default GetEmail
