import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '45px'
  },
  paginationText: {
    fontSize: theme.typography.pxToRem(12),
    color: '#434343',
    fontWeight: 400
  },
  paginationControl: {
    display: 'flex',
    columnGap: '10px'
  },
  paginationButton: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none'
  }
}))

export const iconsDefaultStyle = {
  color: '#CFD2D3',
  transition: 'all .5s',
  '&:hover': {
    color: '#434343'
  }
}
