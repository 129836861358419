import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from './style'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import { Button } from '@mui/material'
import { useAlertContext } from 'src/context/AlertContext'

export function Alert() {
  const { visible, type = 'default', message, onClose } = useAlertContext()
  const classes = makeStyles({ visible })
  const RenderIcon = type === 'success' ? CheckIcon : DoNotDisturbIcon
  const colorIcon = type === 'success' ? '#FFFFFF' : '#d0001a'
  const sholdShowAlert = visible ? 'show' : 'hidden'
  return (
    <div
      className={clsx(
        classes.containerAlert,
        classes[type],
        classes[sholdShowAlert]
      )}
    >
      <RenderIcon htmlColor={colorIcon} />
      <p className={classes.alertMessage}>{message}</p>
      <Button size="small" className={classes.alertClose} onClick={onClose}>
        <CloseIcon htmlColor="#fff" />
      </Button>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.oneOf(['default', 'success', 'error'])
}
