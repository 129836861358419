import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  iconCloseButton: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  iconSearchButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: '#787878',
    backgroundColor: '#D9D9D9',
    '&:hover': {
      backgroundColor: '#787878',
      color: '#FFFFFF'
    }
  },
  iconActiveSearchButton: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#787878',
      color: '#FFFFFF'
    }
  },
  container: {
    columnGap: 10,
    width: 250,
    height: 'auto',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    paddingBottom: 5,
    '&::-webkit-scrollbar': {
      height: 6,
      background: '#CFD2D3',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  containerSearch: {
    width: 290
  },
  containerSearchUser: {
    height: 'auto',
    maxHeight: 250,
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  containerResultUser: {
    paddingTop: theme.spacing(1.5)
  },
  containerAvatar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: 30,
    backgroundColor: '#F7F7FC',
    '&:hover': {
      backgroundColor: '#CFD2D3'
    },
    cursor: 'pointer'
  },
  avatar: {
    width: 30,
    height: 30
  },
  nameUser: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#000000',
    marginLeft: 10
  }
}))
