import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  listPreferencesHead: {
    display: 'flex',
    paddingTop: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  boxListPreferencesContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 200px)',
    width: '100%'
  },
  Container: {
    height: '100%',
    padding: '10px 30px 30px 30px',
    overflow: 'hidden'
  },
  listPreferencesTitleTable: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '5px 25px 25px 25px'
  },
  listPreferencesContainer: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    transition: 'all .3s',
    overflow: 'hidden',
    height: 'auto',
    boxSizing: 'border-box',
    paddingTop: '20px',
    paddingBottom: '30px',
    width: '415.61px'
  },

  listPreferencesContainerTable: {
    height: 'calc(100% - 100px)'
  },
  listPreferencesTextTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: '#434343',
    fontWeight: 600,
    maxWidth: '90%',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    margin: 0,
    marginBottom: 5
  },
  listPreferencesTextSubTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: '#434343',
    fontWeight: 400,
    maxWidth: '90%',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    margin: 0
  },
  selected: {
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    color: 'white',
    pointerEvents: 'none',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    borderBottom: 'solid 1px transparent !important',
    '& li p': {
      color: 'white'
    }
  },
  listPreferencesContentTable: {
    cursor: 'pointer',
    padding: '0px 25px',
    height: '110px',
    transition: 'all 0.2s',
    display: 'flex',
    margin: '0px',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    borderBottom: 'solid 1px #F7F7FC',
    '&:hover': {
      backgroundColor: (props) =>
        props.customerPrimaryColor ?? theme.palette.primary.main,
      borderBottom: 'solid 1px transparent !important',
      color: 'white',
      '& li p': {
        color: 'white'
      }
    }
  },
  listPreferencesOptions: {
    display: 'flex',
    columnGap: '20px',
    height: '100%',
    width: '50px',
    justifyContent: 'center'
  },

  listPreferences: {
    width: '75%'
  },
  opacity: {
    cursor: 'disabled',
    opacity: '0.3',
    pointerEvents: 'none'
  },
  descriptionNone: {
    display: 'none'
  },
  boxOptions: {
    borderTop: 'solid 1px',
    borderColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main
  },
  imgPreferencesOne: {
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    width: '160px',
    height: '60px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
    overflow: 'hidden',
    border: 'solid 3px #fff',
    '& img': {
      overflow: 'auto'
    }
  },
  imgPreferencesTwo: {
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    width: '60px',
    height: '60px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
    overflow: 'hidden',
    border: 'solid 3px #fff',
    '& img': {
      overflow: 'auto'
    }
  },

  iconPreferences: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  colorPreferences: {
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    width: '60px',
    height: '60px',
    borderRadius: '8px',
    marginRight: '15px',
    border: 'solid 3px #fff'
  }
}))
