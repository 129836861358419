import React, { useState, useEffect, useCallback, useMemo } from 'react'
import useAuth from 'src/hooks/useAuth'
import MeasurementDetails from 'src/screens/UserMetrics/MeasurementDetails'
import GeneralData from 'src/screens/UserMetrics/GeneralData'
import UserGroups from 'src/screens/UserMetrics/UserGroups'
import PDI from 'src/screens/UserMetrics/PDI'
import Impressions from 'src/screens/UserMetrics/Impressions'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  CalendarPeriod,
  CardUserNavigation,
  HeaderBreadcrumbs,
  Helmet,
  NewBoxWidget
} from 'src/components'
import { Box } from '@material-ui/core'
import {
  Close as CloseIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { getMetricsUsers } from 'src/services/hooks/metrics/useMetrics'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './styles'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function UserNavigation() {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  const queryUserId = query.get('user')
  const { id: paramUserId = queryUserId } = useParams()
  const { user } = useAuth()
  const currentUserId = user?.id
  const customerId = user?.customerId
  const currentRole = user?.occupation
  const [role] = useState([1, 2, 3])
  const [roleCompetenceGrades] = useState([3, 4])
  const currentPath = location?.pathname

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { currentPeriod } = useCurrentPeriodContext()

  const [dataUser, setDataUser] = useState([])
  const [labelGroupUser, setLabelGroupUser] = useState('')
  const [isVisiblePdi, setIsVisiblePdi] = useState(false)

  const [currentTab, setCurrentTab] = useState({
    isTabGeneralData: false,
    isTabPDI: false,
    isTabRechnicalMetrics: false,
    isTabImpressions: false,
    isTabUsersGroup: false
  })

  function onHandlerTab(tab) {
    setCurrentTab({ ...currentTab, [tab]: !currentTab[tab] })
  }

  const {
    isTabGeneralData,
    isTabPDI,
    isTabTechnicalMetrics,
    isTabImpressions,
    isTabUsersGroup
  } = currentTab

  const [competencyGrades, setCompetencyGrades] = useState(false)
  function onHandleCompetencyGrades() {
    setCompetencyGrades(!competencyGrades)
    navigate(`/competency-grades/user/${paramUserId}`)
  }

  const getDataUser = useCallback(async (id) => {
    try {
      onOpenLoading()
      const formatedDatePayload = getPayloadPeriod(currentPeriod)
      const response = await getMetricsUsers({
        id,
        period: formatedDatePayload
      })

      const filterRoleCategory =
        (response?.occupation === 3 && 'Conhecimento') ||
        (response?.occupation === 4 && 'Liderança')
      const dataMetricsUsers = response?.radarsCompetenceGrouper?.filter(
        (item) => item.category !== filterRoleCategory
      )

      const dataUser = {
        ...response,
        radarsCompetenceGrouper: dataMetricsUsers
      }

      setDataUser(dataUser)
      setLabelGroupUser(
        response?.occupation === 4 ? 'Grupo do usuário' : 'Grupos do usuário'
      )

      let isVisiblePdi

      if (currentRole === 4 || currentRole === 2) {
        isVisiblePdi = response?.occupation === 4
      }

      if (currentRole === 3 || currentRole === 2) {
        isVisiblePdi = response?.occupation === 3 || response?.occupation === 4
      }

      setIsVisiblePdi(isVisiblePdi)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  function checkPath(value) {
    const regex = new RegExp(value)
    return regex.test(currentPath)
  }

  const handlerInitialTab = useCallback(() => {
    if (checkPath('development-plan')) {
      onHandlerTab('isTabPDI')
      return
    }
    return 0
  }, [])

  useEffect(() => {
    handlerInitialTab()
    getDataUser(paramUserId)
  }, [getDataUser, paramUserId])

  return (
    <>
      <Helmet title="Métricas do usuário" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            { name: `${dataUser?.name || ''}` }
          ]}
        />
        <CalendarPeriod
          onChangeDataMetrics={setDataUser}
          type="user"
          identification={paramUserId}
          isSearchById
          isShowIcon
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          <NewBoxWidget width={450}>
            <CardUserNavigation data={dataUser} userId={currentUserId} />
            <Box className={classes.optionsList}>
              <button
                className={
                  isTabGeneralData ? classes.optionSelected : classes.option
                }
                onClick={() => {
                  onHandlerTab('isTabGeneralData')
                }}
              >
                <p
                  className={
                    isTabGeneralData
                      ? classes.optionTextSelected
                      : classes.optionText
                  }
                >
                  Dados gerais
                </p>
                {isTabGeneralData ? (
                  <CloseIcon htmlColor="#FFFFFF" />
                ) : (
                  <ArrowForwardIosIcon htmlColor="#787878" />
                )}
              </button>
              {isVisiblePdi && (
                <button
                  className={isTabPDI ? classes.optionSelected : classes.option}
                  onClick={() => {
                    onHandlerTab('isTabPDI')
                  }}
                >
                  <p
                    className={
                      isTabPDI ? classes.optionTextSelected : classes.optionText
                    }
                  >
                    PDI
                  </p>
                  {isTabPDI ? (
                    <CloseIcon htmlColor="#FFFFFF" />
                  ) : (
                    <ArrowForwardIosIcon htmlColor="#787878" />
                  )}
                </button>
              )}
              <button
                className={
                  isTabTechnicalMetrics
                    ? classes.optionSelected
                    : classes.option
                }
                onClick={() => {
                  onHandlerTab('isTabTechnicalMetrics')
                }}
              >
                <p
                  className={
                    isTabTechnicalMetrics
                      ? classes.optionTextSelected
                      : classes.optionText
                  }
                >
                  Detalhes de métricas
                </p>
                {isTabTechnicalMetrics ? (
                  <CloseIcon htmlColor="#FFFFFF" />
                ) : (
                  <ArrowForwardIosIcon htmlColor="#787878" />
                )}
              </button>
              <button
                className={
                  isTabImpressions ? classes.optionSelected : classes.option
                }
                onClick={() => {
                  onHandlerTab('isTabImpressions')
                }}
              >
                <p
                  className={
                    isTabImpressions
                      ? classes.optionTextSelected
                      : classes.optionText
                  }
                >
                  Impressões
                </p>
                {isTabImpressions ? (
                  <CloseIcon htmlColor="#FFFFFF" />
                ) : (
                  <ArrowForwardIosIcon htmlColor="#787878" />
                )}
              </button>
              {role.includes(currentRole) && (
                <button
                  className={
                    isTabUsersGroup ? classes.optionSelected : classes.option
                  }
                  onClick={() => {
                    onHandlerTab('isTabUsersGroup')
                  }}
                >
                  <p
                    className={
                      isTabUsersGroup
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    {labelGroupUser}
                  </p>
                  {isTabUsersGroup ? (
                    <CloseIcon htmlColor="#FFFFFF" />
                  ) : (
                    <ArrowForwardIosIcon htmlColor="#787878" />
                  )}
                </button>
              )}
              {roleCompetenceGrades.includes(currentRole) && (
                <>
                  {currentUserId !== paramUserId && (
                    <button
                      className={
                        competencyGrades
                          ? classes.optionSelected
                          : classes.option
                      }
                      onClick={() => {
                        onHandleCompetencyGrades()
                      }}
                    >
                      <p
                        className={
                          competencyGrades
                            ? classes.optionTextSelected
                            : classes.optionText
                        }
                      >
                        Avaliação de competência
                      </p>
                      {competencyGrades ? (
                        <CloseIcon htmlColor="#FFFFFF" />
                      ) : (
                        <ArrowForwardIosIcon htmlColor="#787878" />
                      )}
                    </button>
                  )}
                </>
              )}
            </Box>
          </NewBoxWidget>
          {isTabGeneralData && (
            <GeneralData
              data={dataUser}
              onClose={() => onHandlerTab('isTabGeneralData')}
            />
          )}
          {isTabPDI && (
            <PDI
              userId={paramUserId}
              onClose={() => onHandlerTab('isTabPDI')}
            />
          )}
          {isTabTechnicalMetrics && (
            <MeasurementDetails
              data={dataUser?.radarsCompetence}
              userRole={dataUser?.occupation}
              userId={paramUserId}
              onClose={() => onHandlerTab('isTabTechnicalMetrics')}
            />
          )}
          {isTabImpressions && (
            <Impressions onClose={() => onHandlerTab('isTabImpressions')} />
          )}
          {isTabUsersGroup && (
            <UserGroups
              customerId={customerId}
              userId={paramUserId}
              onClose={() => onHandlerTab('isTabUsersGroup')}
              titleBox={labelGroupUser}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default UserNavigation
