import React from 'react'
import { PATH_DASHBOARD } from 'src/routes/paths'
import {
  HouseOutlined as HouseOutlinedIcon,
  PermContactCalendarOutlined as PermContactCalendarOutlinedIcon,
  AssistantOutlined as AssistantOutlinedIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  GroupOutlined as GroupOutlinedIcon,
  TodayOutlined as TodayOutlinedIcon,
  DesignServicesOutlined as DesignServicesOutlinedIcon,
  MarkChatReadOutlined as MarkChatReadOutlinedIcon
} from '@mui/icons-material'

const navConfig = [
  {
    id: 0,
    label: 'Home',
    link: PATH_DASHBOARD.home,
    icon: <HouseOutlinedIcon />,
    permissions: [1, 2, 3, 4]
  },
  {
    id: 1,
    label: 'Métricas',
    link: PATH_DASHBOARD.metrics,
    icon: <AssessmentOutlinedIcon />,
    permissions: [1, 2, 3, 4]
  },
  {
    id: 2,
    label: 'Clientes',
    link: PATH_DASHBOARD.customers,
    icon: <AssistantOutlinedIcon />,
    permissions: [1]
  },
  {
    id: 3,
    label: 'Usuários',
    link: PATH_DASHBOARD.users,
    icon: <PermContactCalendarOutlinedIcon />,
    permissions: [1, 2]
  },
  {
    id: 4,
    label: 'Grupos de usuários',
    link: PATH_DASHBOARD.groups,
    icon: <GroupOutlinedIcon />,
    permissions: [1, 2]
  },
  {
    id: 5,
    label: 'Agrupadores de competência',
    link: PATH_DASHBOARD.competenceGrouper,
    icon: <DesignServicesOutlinedIcon />,
    permissions: [1, 2]
  },
  {
    id: 6,
    label: 'Ciclos de avaliação',
    link: PATH_DASHBOARD.evaluationCycle,
    icon: <TodayOutlinedIcon />,
    permissions: [1, 2]
  },
  {
    id: 7,
    label: 'Impressões',
    link: PATH_DASHBOARD.impressions,
    icon: <MarkChatReadOutlinedIcon />,
    permissions: [2, 3, 4]
  }
]

export default navConfig
