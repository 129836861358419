import jwtDecode from 'jwt-decode'
import axios from './axios'
import { postOauthRefreshToken } from 'src/services/hooks/oauth/useOauth'
import { getCustumersById } from 'src/services/hooks/customers/useCustumers'

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode(accessToken)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const handleTokenExpired = async (exp) => {
  let expiredTimer

  const currentTime = Date.now()

  const timeLeft = exp * 1000 - currentTime

  clearTimeout(expiredTimer)

  expiredTimer = setTimeout(async () => {
    // alert('Token expired')

    try {
      if (localStorage.getItem('accessToken')) {
        const userId = localStorage.getItem('userId')

        const { customerId } = JSON.parse(localStorage.getItem('customer'))

        const refreshRes = {
          token: localStorage.getItem('accessToken'),
          refresh: localStorage.getItem('refreshToken'),
          email: localStorage.getItem('email')
        }
        const userRes = await postOauthRefreshToken(refreshRes)

        const customerRes = await getCustumersById({
          customerId,
          token: userRes.token
        })

        const customer = {
          customerId,
          logo: customerRes.logo,
          favicon: customerRes.favicon,
          primaryColor: customerRes.primaryColor
        }

        setSession(
          userRes.token,
          userRes.refresh,
          userRes.email,
          userId,
          JSON.stringify(customer)
        )
      } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('email')
        localStorage.removeItem('userId')
        localStorage.removeItem('customer')
      }
    } catch (error) {
      console.error(error?.response)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('email')
      localStorage.removeItem('userId')
      localStorage.removeItem('customer')
      window.location.href = '/login'
    }
  }, timeLeft)
}

const setSession = (accessToken, refreshToken, email, userId, customer) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('email', email)
    localStorage.setItem('userId', userId)
    localStorage.setItem('customer', customer)

    axios.defaults.headers.Authorization = `Bearer ${accessToken}`

    const { exp } = jwtDecode(accessToken)
    handleTokenExpired(exp)
  } else {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('email')
    localStorage.removeItem('userId')
    localStorage.removeItem('customer')
    handleTokenExpired(0)
    delete axios.defaults.headers.Authorization
  }
}

export { isValidToken, setSession }
