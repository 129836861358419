import React, { useEffect, useState, useCallback } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useAuth from 'src/hooks/useAuth'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Typography, Box, Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Form } from 'react-final-form'
import { Autocomplete, Date, Input, Switch } from 'src/components/Fields'
import { newUserValidation } from 'src/utils/form/validations'
import { maskCPF, maskName } from 'src/utils/regex/masks'
import { enumOcupation, enumAssignment } from 'src/utils/constants'
import { getOccupationArea } from 'src/services/hooks/occupationArea/useOccupationArea'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Button, NewBoxWidget, HeaderBreadcrumbs, Helmet } from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { postUser } from 'src/services/hooks/users/useUsers'
import { PermContactCalendarOutlined as PermContactCalendarOutlinedIcon } from '@material-ui/icons'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'

function NewUser() {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  const navigate = useNavigate()

  const [role] = useState([1, 2])
  const { user } = useAuth()
  const currentCustomerId = user?.customerId

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [populateSelectOptions, setPopulateSelectOptions] = useState({
    occupationArea: []
  })

  const getDataSelectOptions = useCallback(async () => {
    try {
      const resultOccupations = await getOccupationArea()
      const occupations = resultOccupations.map((key) => ({
        value: key.id,
        title: key.name
      }))

      setPopulateSelectOptions({
        occupationArea: occupations
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    getDataSelectOptions()
  }, [getDataSelectOptions])

  const onSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        customerId: currentCustomerId
      }
      onOpenLoading()
      await postUser(payload)
      onCallAlert({
        type: 'success',
        message: notifications.users.success.addNewUser
      })
      navigate('/users')
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  function alertDocument(value, error) {
    if(value.length === 14 && error) {
      onCallAlert({
        type: 'error',
        message: error
      })
    }
  }

  function alertBirthData(value, error) {
    if(moment(value).isValid() && error) {
      onCallAlert({
        type: 'error',
        message: error
      })
    }
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Novo usuário" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<PermContactCalendarOutlinedIcon />}
          links={[
            { name: 'Usuários', href: '/users' },
            { name: 'Novo usuário' }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <Form
          onSubmit={(values) => onSubmit(values)}
          validate={newUserValidation}
          initialValues={{
            isActive: false
          }}
          render={({ handleSubmit, valid, values, errors }) => (
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <NewBoxWidget
                title="Novo usuário"
                subtitle="Adicione os dados do novo contato:"
                options={
                  <>
                    <Typography
                      variant="inherit"
                      className={classes.textStatusUser}
                    >
                      Status do usuário:
                    </Typography>
                    <Switch name="isActive" />
                  </>
                }
                actions={
                  <div className={classes.actions}>
                    <Typography variant="inherit">
                      *Campos obrigatórios
                    </Typography>
                    <Button
                      label="Adicionar"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!valid}
                    />
                  </div>
                }
                fullBody
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    item
                    style={{
                      alignItems: 'center',
                      direction: 'column'
                    }}
                    spacing={3}
                  >
                    <Grid item md={6} xs={12}>
                      <Input label="Nome*" name="name" parse={maskName} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Input label="E-mail*" name="email" />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Autocomplete
                        options={enumOcupation}
                        label="Vinculo de papel*"
                        name="occupation"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Input
                        label="CPF*"
                        name="document"
                        parse={maskCPF}
                        onBlur={() => alertDocument(values?.document ?? "", errors?.document)}
                      />
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Date
                        label="Data de nascimento*"
                        name="birthData"
                        disableFuture
                        onBlur={() => alertBirthData(values?.birthData ?? "", errors?.birthData)}
                      />
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Date
                        label="Data de admissão*"
                        name="admissionDate"
                        disableFuture
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={populateSelectOptions.occupationArea}
                        label="Área de atuação*"
                        name="occupationAreaId"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={enumAssignment}
                        label="Experiência*"
                        name="assignment"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                  </Grid>
                </form>
              </NewBoxWidget>
            </MuiPickersUtilsProvider>
          )}
        />
      </Box>
    </RoleBasedGuard>
  )
}

export default NewUser
